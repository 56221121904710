import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { MegaphoneIcon } from '@heroicons/react/24/solid'
import { useAuth } from '@clerk/clerk-react'
import { handleThumbsFeedbackServerSide } from '../../ServerActions'
import { useHistory } from '../../providers/HistoryProvider'
import { set } from 'lodash'

export default function FeedbackModal() {
    const [input, setInput] = useState<string>()
    const { getToken} = useAuth();
    const {feedbackModal, feedbackModalSource, activeSession, setNotification, setNotificationText, setNotificationTitle, setFeedbackModal} = useHistory()
    const [submitting, setSubmitting] = useState(false)

    function handleInputChange(value:string){
        setInput(value)
    }

    async function handleSubmitFeedback(){
        try{
            setSubmitting(true)
            await handleThumbsFeedbackServerSide(activeSession?.id ?? "", false, input ?? "N/A", feedbackModalSource,  await getToken({template:"supabase"}) ?? "")
            setSubmitting(false)
            setNotification(true)
            setNotificationTitle("Thank you for the feedback")
            setNotificationText("Feedback was shared with team!")
            setFeedbackModal(false)
        }
        catch(error){
            setSubmitting(false)
        }
    }

    return (
        <Transition.Root show={feedbackModal} as={Fragment}>
        <Dialog as="div" className="relative z-[999999]" onClose={setFeedbackModal}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                        <MegaphoneIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Submit feedback
                        </Dialog.Title>
                        <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Feedback is crucial for VetRec to improve. Thank you for sharing your feedback!
                        </p>
                        </div>
                    </div>
                    </div>
                    <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 mt-4"
                        defaultValue={''}
                        onChange={(event) => {handleInputChange(event.target.value)}}
                    />
                    <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2 mt-4"
                        onClick={() => handleSubmitFeedback()}
                        disabled={submitting}
                    >
                        Submit
                    </button>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    )
}
