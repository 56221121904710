import UserPicker from "./components/UserPicker";
import { FileUploader } from "./components/recordsrecap/FileUploader";
import {
  RecordsRecapStatus,
  useRecordsRecap,
} from "./providers/RecordsRecapProvider";
import Error from "./components/Error";
import { Loading } from "./utils/Loading";
import { BetaBanner } from "./components/common/BetaBanner";

export default function RecordsRecapPage() {
  const {
    sessionId,
    recordsRecapState,
    name,
    updateName,
    updateRecordAs,
    membersList,
    recordAs,
    isActAsAvailable,
    error,
    setError,
    startNewRecordsRecap,
  } = useRecordsRecap();

  return (
    <>
      <div className="border-b border-gray-400 pb-5 mb-5 py-4 sm:pt-10 flex flex-row justify-between gap-x-10 items-center">
        <div>
          <h2 className="text-2xl font-bold leading-7 text-main-text-darker sm:truncate sm:text-3xl sm:tracking-tight">
            Records Recap
          </h2>
          <p className="mt-2 max-w-4xl text-sm text-gray-500 hidden sm:block">
            Upload a patient's prior records or referral records, and VetRec
            Records Recap will <u>quickly</u> and <u>accurately</u> summarize
            them for your review prior to an appointment.
          </p>
        </div>
      </div>
      {recordsRecapState !== RecordsRecapStatus.UPLOADING && (
        <div className="flex flex-col gap-y-4">
          <BetaBanner><span className="font-semibold">Records Recap</span> now supports uploading and summarizing more than 150 pages!</BetaBanner>
          <div className="flex sm:flex-row flex-col w-full gap-x-4 gap-y-4 pt-2">
            <div className="relative grow">
              <label
                htmlFor="name"
                className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker"
              >
                Patient's Name
              </label>
              <input
                type="text"
                className={`block w-full h-10 sm:h-14 rounded-md border-0 py-1.5 text-main-text-darker shadow-sm ring-2 ring-inset ring-blue-400 placeholder:text-gray-400 ${
                  recordsRecapState === RecordsRecapStatus.FAILED_NAME
                    ? "ring-red-600 ring-4"
                    : " ring-blue-400 ring-2 "
                } focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6`}
                placeholder="Mr.Wiggles"
                value={name}
                onChange={(event) => updateName(event.target.value)}
              />
            </div>
            {membersList && membersList.length > 1 && isActAsAvailable && (
              <div className="relative">
                <UserPicker
                  userList={membersList}
                  userSearch={membersList.find((u) => u.identifier == recordAs)}
                  setUserSearch={(value) =>
                    updateRecordAs(value?.identifier ?? "")
                  }
                  className="h-10 sm:h-14"
                  text="Summarizing as"
                  disabled={recordsRecapState !== RecordsRecapStatus.NOTSTARTED}
                />
              </div>
            )}
          </div>
          <div className="">
            <FileUploader
              sessionId={sessionId}
              onSubmitButtonClick={startNewRecordsRecap}
              submitButtonText="Summarize and Start Visit"
            />
          </div>
          <div className="flex flex-col-reverse sm:flex-row justify-between items-center w-full pt-4 sm:pr-20 gap-y-8 gap-x-10">
            <div className="text-xs text-center sm:text-start w-full truncate shrink overflow-hidden whitespace-nowrap min-w-20">
              <span className="font-semibold">Visit ID:</span> {sessionId}
            </div>
          </div>
        </div>
      )}
      {recordsRecapState === RecordsRecapStatus.UPLOADING && (
        <Loading text="Processing prior records" />
      )}
      <Error error={error} setError={setError} />
    </>
  );
}
