import { Disclosure } from '@headlessui/react';
import React, { useState } from 'react';
import { EntryBox } from './EntryBox';
import { ItemTypes } from './ItemTypes';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { SectionBox } from './SectionBox';
import { TemplateType } from './TemplateUtils';
import { useTemplate } from './TemplateProvider';

const DraggableSections: React.FC = () => {
    const {templateType} = useTemplate()

    return (
        <div className="col-span-1 -mx-4 sm:pl-4 py-4 border-l-2 border-gray-300/30 sm:mx-0 col-span-1 xl:pt-2">
        <div className="flex flex-col gap-y-4 h-full"> 
            <div className={`${'h-[50vh] sm:h-[60vh] lg:h-[70vh]'} overflow-y-auto thin-scrollbar`}>
              <div style={{clear: 'both' }} className='flex flex-col gap-y-4 h-full'>
                <div>Drag and Drop</div>
                <SectionBox name="Section" description="Top level sections for notes" type={ItemTypes.SECTION}/>
                <EntryBox name="Entry" description='Data entry inside of a section' type={ItemTypes.ENTRY}/>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                        <span>Pre-built sections</span>
                        <ChevronUpIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-5 w-5 text-blue-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="pb-2 text-sm text-gray-500 flex flex-col gap-y-4">
                        <SectionBox name="Subjective" description='Patient history' type={ItemTypes.SUBJECTIVE} />
                        <SectionBox name="Vitals" description='Temperature, weight, BP, etc.' type={ItemTypes.VITALS} />
                        <SectionBox name="Physical Exam" description='Body system assessments.' type={ItemTypes.PHYSICAL_EXAM} />
                        <SectionBox name="Assessment" description='Problems and DDX.' type={ItemTypes.ASSESSMENT} />
                        <SectionBox name="Plan" description='Tests, treatments, medication, etc.' type={ItemTypes.PLAN} />
                        <SectionBox name="Fear Free" description='Entries for Fear Free related information.' type={ItemTypes.FEAR_FREE} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                        <span>Pre-built entries</span>
                        <ChevronUpIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-5 w-5 text-blue-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="pb-2 text-sm text-gray-500 flex flex-col gap-y-4">
                        <EntryBox name="Owner Discussion" description='' type={ItemTypes.OWNER_DISCUSSION} />
                        <EntryBox name="Current Medication" description='' type={ItemTypes.CURRENT_MEDICATION} />
                        <EntryBox name="Current Diet" description='' type={ItemTypes.CURRENT_DIET} />
                        <EntryBox name="Current Preventatives" description='' type={ItemTypes.CURRENT_PREVENTATIVES} />
                        <EntryBox name="Chronic Issues" description='' type={ItemTypes.CHRONIC_ISSUES} />
                        <EntryBox name="Diagnostic Tests" description='' type={ItemTypes.DIAGNOSTIC_TESTS} />
                        <EntryBox name="Treatments" description='' type={ItemTypes.TREATMENTs} />
                        <EntryBox name="Vaccines" description='' type={ItemTypes.VACCINES} />
                        <EntryBox name="Medication" description='' type={ItemTypes.MEDICATION} />
                        <EntryBox name="Diet" description='' type={ItemTypes.DIET} />
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
        </div>
      </div>
    );
};

export default DraggableSections;