import { PlusIcon } from '@heroicons/react/20/solid'
import { MicrophoneIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom';

export default function EmptyState() {
    const  navigate = useNavigate()
    return (
        <div className="text-center flex flex-col jutsify-center items-center mt-12">
            <MicrophoneIcon className='h-7 w-7' />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">No Active Visits</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by creating a new visit.</p>
            <div className="mt-6">
                <button
                type="button"
                className="inline-flex items-center rounded-md bg-main px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-main-lighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main"
                onClick={() => navigate('/scribe')}
                >
                    <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                    New Visit
                </button>
            </div>
        </div>
    )
}
