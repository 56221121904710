export function convertTimestampToDate(created_timestamp:number) {
    // Convert timestamp from seconds to milliseconds
    const date = new Date(created_timestamp * 1000);

    // Format the date to a human-readable format
    const humanReadableDate = date.toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    return humanReadableDate;
}

export function convertTimestampToDateNoTime(created_timestamp: number) {
    // Convert timestamp from seconds to milliseconds
    const date = new Date(created_timestamp * 1000).toISOString();

    // Extract the year, month, and day
    const [year, month, day] = date.split('T')[0].split('-');

    // Format the date as MM/DD/YYYY
    const humanReadableDate = `${Number(month)}/${Number(day)}/${year}`;

    return humanReadableDate;
}

export function getDatesBetween(startDate: number, endDate: number, timeframe:string): string[] {
    const result: string[] = [];
    let current: Date = new Date(startDate * 1000);
    const end: Date = new Date(endDate * 1000);

    if (timeframe === 'daily') {
        while (current <= end) {
            result.push(current.toLocaleDateString('en-US'));
            current.setDate(current.getDate() + 1);
        }
    } else if (timeframe === 'weekly') {
        // Set to the Monday of the week
        const dayOfWeek = current.getDay();
        const diff = current.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Sunday
        current = new Date(current.setDate(diff));

        while (current <= end) {
            result.push(current.toLocaleDateString('en-US'));
            current.setDate(current.getDate() + 7);
        }
    } else if (timeframe === 'monthly') {
        // Set to the 1st day of the month
        current.setDate(1);

        while (current <= end) {
            result.push(current.toLocaleDateString('en-US'));
            current.setMonth(current.getMonth() + 1);
        }
    }

    return result;
}
