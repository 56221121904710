import { SparklesIcon } from "@heroicons/react/24/solid"
import { ReactNode } from "react";

interface BetaBannerProps {
    children: ReactNode;
}

export const BetaBanner: React.FC<BetaBannerProps> = ({children}) => {
    return <div className="rounded-lg bg-blue-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
    <div className="flex grow items-center justify-between">
      <div className="flex items-center gap-x-4">
        <SparklesIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        <div className="text-sm text-blue-900">
          {children}
        </div>
      </div>
      <span className="inline-flex items-center px-2 py-1 text-xs font-bold text-white bg-blue-800 rounded-md">
        Beta
      </span>
    </div>
  </div>
}