import ReactQuill from "react-quill";
import { SpacingText } from "../../utils/TextUtils";
import { SessionObject } from "../../utils/SessionUtils";
import { CheckIcon, ChevronUpIcon, InformationCircleIcon, MicrophoneIcon } from "@heroicons/react/24/solid";
import { Loading } from "../../utils/Loading";
import { Disclosure } from "@headlessui/react";
import { useHistory } from "../../providers/HistoryProvider";
import { Spinner } from "../../utils/Spinner";

interface TranscriptProps {

}

export default function Transcript({}: TranscriptProps) {
    const {activeTranscript, transcriptLoading, activeRecording, activeRecordingBackUp, recordingLoading, activeSession, handleMarkComplete, handleAddToSession, loadingSessionInfo, transcriptProcessing} = useHistory()
    return(<div>
        {(transcriptLoading || loadingSessionInfo) && !activeTranscript && !transcriptProcessing && <Loading text={transcriptLoading ?? loadingSessionInfo ?? ""}/>}
        {transcriptProcessing && !transcriptLoading && !activeTranscript && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-6 mb-8 font-semibold'>
            <div>Transcribing recording 🤖...</div>
            <Spinner size="w-12 h-12" timer={true}/>
            <div className="text-sm text-center text-gray-700 font-normal">Transcript will be ready within 60s.<br/>Feel free to navigate away if you need to.</div>
        </div>}
        {activeTranscript && <div className="flex xl:flex-row flex-col gap-y-4 justify-between gap-x-4 mb-4 xl:items-center items-end">
            <div className="rounded-lg bg-blue-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
                <div className="flex grow items-center justify-center">
                    <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                        <div className="flex flex-row gap-x-4 items-center">
                            <div>
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </div>
                            <div className="text-sm text-blue-900"><span className="font-semibold">Speaker recognition</span> is in beta. Speaker identification doesn't affect notes.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-x-2 w-full sm:w-auto">
                <button
                    type="button"
                    title={activeSession && activeSession.status.general && activeSession.status.general == "Completed" ? "This session is completed" : "Mark this session as completed"}
                    className={`whitespace-nowrap inline-flex items-center gap-x-3 rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 border border-gray-300 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52 ${activeSession && activeSession.status.general && activeSession.status.general == "Completed" ? 'bg-green-600 hover:bg-green-500 font-semibold text-white' : 'bg-white'}`}
                    onClick={() => handleMarkComplete()}
                >
                    {activeSession && activeSession.status.general && activeSession.status.general == "Completed" && <CheckIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />}
                    {activeSession && activeSession.status.general && activeSession.status.general == "Completed" ?  "Completed" : "Mark as completed"}
                </button>
                <button
                    type="button"
                    title="Record additional information for this session which will be added to the notes."
                    className="whitespace-nowrap inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52"
                    onClick={() => handleAddToSession()}
                >
                    <MicrophoneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                    Add to visit
                </button>
            </div>
        </div>}
        {(activeRecording || activeRecordingBackUp || recordingLoading) && <div className="w-full mb-4">
            <div className="w-full rounded-lg bg-white">
                <Disclosure>
                {({ open }) => (
                    <>
                    <Disclosure.Button className={`flex w-full justify-between items-center ${open ? "rounded-t-lg" : "rounded-lg"} bg-blue-100 px-4 py-2 text-left text-sm font-semibold text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75 h-12`}>
                        <span>Recordings</span>
                        <ChevronUpIcon
                        className={`${
                            open ? 'rotate-180 transform' : ''
                        } h-5 w-5 text-blue-500`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-sm text-gray-500">
                    <div className="px-6 pb-4 pt-4 text-sm text-gray-500 border border-t-0 border-gray-300 rounded-b-lg shadow">
                        {recordingLoading && <Loading text={recordingLoading}/>}
                        {activeRecording && activeRecording.map((r) => (
                            <audio key={r} controls src={r} className="w-full my-4 fill-green-600">
                                Your browser does not support the audio element.
                            </audio>
                        ))}
                        {activeRecording && activeRecording.length == 0 && !activeRecordingBackUp && <div className="w-full text-center">
                            Looks like there are no recordings for this session.
                            Create a recording by going to the <span className="font-semibold">Active Sessions</span> tab.
                        </div>}
                        {!recordingLoading && activeRecordingBackUp && <div className="w-full text-center">
                            Some recordings might not be displayed, but they are stored. For access to the raw recordings contact support@vetrec.io
                        </div>}
                    </div>
                    </Disclosure.Panel>
                    </>
                )}
                </Disclosure>
            </div>
        </div>}
        {activeTranscript && <ReactQuill
            theme='snow'
            placeholder='Mr.Wiggles is looking mighty well today...'
            className='block w-full h-[68vh] rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
            value={SpacingText(activeTranscript)}
            readOnly
            modules={{toolbar: false}}
        />}
    </div>)
}