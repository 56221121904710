import { Transition } from "@headlessui/react"
import { ExclamationCircleIcon, ExclamationTriangleIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid"
import { Fragment, useEffect, useState } from "react"

interface WarningProps{
    warning:string | undefined
    setWarning: (arg0: string|undefined) => void
}


export default function WarningAlert(props:WarningProps) {
    const [warningTimer, setWarningTimer] = useState<number>(0);

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (props.warning) {
            setWarningTimer(0);
            timer = setTimeout(() => {
                props.setWarning(undefined);
            }, 5000); 

            interval = setInterval(() => {
                setWarningTimer((oldProgress) => {
                    if (oldProgress < 100) {
                        return oldProgress + 1;
                    } else {
                        clearInterval(interval);
                        return 100;
                    }
                });
            }, 50);
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [props.warning]);
    
    return(
        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-12 lg:mt-0 z-[999]"
            >
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
                show={props.warning !== undefined}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="p-4">
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                            <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
                            </div>
                            <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-main-text-darker">{props.warning}</p>
                            </div>
                            <div className="ml-4 flex flex-shrink-0">
                            <button
                                type="button"
                                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => {
                                props.setWarning(undefined)
                                }}
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full bg-gray-500'>
                        <div className={`bg-yellow-500 h-[5px]`} style={{width:`${warningTimer}%`}}/>
                    </div>
                </div>
            </Transition>
            </div>
        </div>
    )
}