import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { LanguageIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import LanguagePicker from '../LanguagePicker'
import { useHistory } from '../../providers/HistoryProvider'
import { isMobileDevice } from '../../utils/deviceUtils'
import UserPicker from '../UserPicker'
import { MemberObject } from '../../utils/MemberUtils'
import { useUser } from '@clerk/clerk-react'
import Datepicker, { DateValueType } from 'react-tailwindcss-datepicker'
import { Spinner } from '../../utils/Spinner'
import { time } from 'console'

export default function CreateVisitModal() {

    const {user} = useUser()
    const {createVisitModal, setCreateVisitModal, handleCreatePatient, membersList, searchDate} = useHistory()
    const [inputValue, setInputValue] = useState<string>('')
    const [staff, setStaff] = useState<MemberObject | undefined>({
        name: (user?.firstName && user?.lastName) ? user.firstName + " " + user.lastName : undefined,
        identifier: user?.primaryEmailAddress?.emailAddress ?? ""
    })
    const [createDate, setCreateDate] = useState<DateValueType>({
        startDate: new Date(),
        endDate: new Date()
    }); 
    const [creating, setCreating] = useState<boolean>(false)
    const [time, setTime] = useState<string>(new Date().toTimeString().slice(0, 5))
    const [missingFields, setMissingFields] = useState<boolean>(false)

    const createVisit = async () => {
        if(!inputValue || !staff || !createDate || !time){
            setMissingFields(true)
            return
        }
        setMissingFields(false)
        let date = new Date()
        if(createDate?.startDate){
            if(createDate.startDate instanceof Date){
                date = createDate.startDate
            }
            else{
                const [year, month, day] = createDate?.startDate?.toString().split('-').map(Number);
                date = new Date(year, month - 1, day); 
            }
        }
        date.setHours(parseInt(time.split(':')[0]), parseInt(time.split(':')[1]), 0, 0)
        let date_in_float = Math.floor(date.getTime() / 1000)
        setCreating(true)
        await handleCreatePatient(inputValue, staff?.identifier, date_in_float)
        setCreating(false)
        setInputValue('')
        setCreateVisitModal(false)
    }

    useEffect(() => {
        if(createVisitModal){
            setInputValue('')
            setStaff({
                name: (user?.firstName && user?.lastName) ? user.firstName + " " + user.lastName : undefined,
                identifier: user?.primaryEmailAddress?.emailAddress ?? ""
            })
            setCreateDate({
                startDate: new Date(),
                endDate: new Date()
            })
            setTime(new Date().toTimeString().slice(0, 5))
            setMissingFields(false)
        }
    }, [createVisitModal])

    return (
        <Transition.Root show={createVisitModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={setCreateVisitModal}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div className='px-8'>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                <UserCircleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                Create a visit
                                </Dialog.Title>
                                <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Add a visit to VetRec. Assign to a staff member and a date.
                                </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 flex flex-col gap-y-4 justify-center items-center">
                            <div className='flex flex-row gap-x-2 items-center'>
                                <span className="w-16 text-right">Patient:</span>
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    className="border border-gray-300 rounded-lg text-black w-60 placeholder:text-gray-400 sm:text-sm sm:leading-6 py-1.5"
                                    placeholder="Name"
                                />
                            </div>
                            <div className='flex flex-row gap-x-2 items-center'>
                                <span className="w-16 text-right">Date:</span>
                                <div className='w-60 z-[9999]'>
                                    <Datepicker 
                                        inputClassName={`h-10 rounded-lg w-60 text-sm border-gray-300 border-1 placeholder:text-gray-400 text-left`}
                                        toggleClassName={"hidden"}
                                        asSingle={true} 
                                        useRange={false} 
                                        value={createDate} 
                                        onChange={async (value) => {
                                            setCreateDate(value)
                                        }} 
                                        popoverDirection="down"
                                        displayFormat={"MM/DD/YYYY"}  
                                        placeholder="All Dates"
                                        disabled={creating}
                                    /> 
                                </div>
                            </div>
                            <div className='flex flex-row gap-x-2 items-center'>
                                <span className="w-16 text-right">Time:</span>
                                <div className='w-60'>
                                    <input type="time" id="time" className="border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" min="09:00" max="18:00" value={time} onChange={(event) => {setTime(event.target.value)}} disabled={creating}/>
                                </div>
                            </div>
                            <div className='flex flex-row gap-x-2 items-center'>
                                <span className="w-16 text-right">Staff:</span>
                                <div className='w-60'>
                                    <UserPicker 
                                        userList={membersList} 
                                        userSearch={staff} 
                                        setUserSearch={(value) => setStaff(value)} 
                                        className="pr-6 h-10 w-60" // Ensure the width is the same as the input fields
                                        disabled={creating}
                                    />
                                </div>
                            </div>
                            <div className='mt-4 w-full text-center flex flex-col gap-y-2'>
                                {missingFields && <div className='text-red-500 text-sm'>Please fill out all fields</div>}
                                <button
                                    type="button"
                                    className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 overflow-hidden w-full justify-center h-10 sm:h-12"
                                    onClick={async () => createVisit()}
                                    disabled={creating}
                                >
                                    {creating ? <Spinner size='h-5 w-5' timer={false}/>  : "Create visit"}
                                </button>
                            </div>
                        </div>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}