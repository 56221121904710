import { createContext, useContext, useState, ReactNode, useRef, useEffect } from 'react';
import { ContentType, EntryType, EzyVetCategories, EzyVetSection, SectionType, SectionTypes, TemplateStyling, ezyVetMappingToObject, saveEzyVetMapping, checkForDuplicateNames, TemplateType, EzyVetVitalsCategories, communicationTemplateTones, saveInstinctMapping, IntegrationType, instinctMappingToObject } from './TemplateUtils';
import { v4 as uuidv4 } from 'uuid';
import { useSupabase } from '../../supabase/SupabaseContext';
import { useUser } from '@clerk/clerk-react';
import { createTemplate, fetchTempalteById, updateTemplate } from '../../ServerActions';
import { useAuth } from '@clerk/clerk-react';
import { physical_exam, simpleAssessment, simplePlan, simpleSubjective } from './SectionDefinitions';
import { useLocation } from "react-router-dom"
import { UserAction } from '../../utils/vercelAnaytics';
import { GetTemplate, GetTemplateSignedURL } from '../../supabase/supabaseProxy';

const initTemplate = [ 
    simpleSubjective,
    physical_exam,
    simpleAssessment,
    simplePlan,
]

// Define the shape of your context state here
interface TemplateContextType {
  name: string|undefined;
  items: SectionType[];
  mapping: {[key:string]:EzyVetSection}
  styling: TemplateStyling
  integration: string | undefined
  entryHoverIndex: string | undefined
  sectionHoverIndex: string | undefined
  subSectionHover: boolean
  subEntryHoverIndex: string | undefined
  sectionDragged: boolean | undefined
  entryDragged: boolean | undefined
  templateType: TemplateType
  templateTone: string | undefined
  templateLoading: boolean
  addEntryToItem: (newEntry: EntryType) => void
  updateSectionName: (sectionKey: string, newName: string) => void
  updateSectionType: (sectionKey: string, type: SectionTypes) => void
  updateSectionDescription: (sectionKey: string, newDescription: string) => void
  updateSectionDefaultValue: (sectionKey: string, newDefaultValue: string) => void
  updateSectionHardcodedValue: (sectionKey: string, hardcodedValue: string) => void
  addSection: (newSection: SectionType) => void
  updateName: (name:string) => void
  deleteSection: (sectionKey:string) => void
  updateEntryName: (sectionKey: string, entryKey: string, newName: string, parentEntryKey?: string) => void
  updateEntryDescription: (sectionKey: string, entryKey: string, newDescription?: string, parentEntryKey?: string) => void
  updateEntryHardcodedValue: (sectionKey: string, entryKey: string, hardcodedValue: string | undefined, parentEntryKey?: string) => void
  updateEntryType: (sectionKey: string, entryKey: string, newType: ContentType, parentEntryKey?:string) => void
  updateEntryDefaultValue: (sectionKey: string, entryKey: string, newDefaultValue: string | undefined, parentEntryKey?: string) => void
  deleteEntry: (sectionKey: string, entryKey: string, parentEntryKey?: string) => void
  swapSectionsByKeys: (key1: string, key2: string) => void
  swapEntries:(sectionKey: string, entryKey1: string, entryKey2: string) => void
  swapSubEntries:(sectionKey: string, parentEntryKey: string, subEntryKey1: string, subEntryKey2: string) => void
  saveTemplate: () => Promise<void>
  validateTemplate: () => [string, boolean]
  overrideTemplate: (array_sections: { [key: string]: any }[]) => void
  updateEzyvetSectionName: (key: string, newName: string) => void
  updateEzyVetSectionCategory: (key: string, newCategory: EzyVetCategories) => void
  updateEzyVetVitalCategory: (sectionKey: string, entryKey:string, newCategory: EzyVetVitalsCategories)  => void
  saveMapping: () => Promise<void>
  updateIntegration: (integration:string) => void
  saveStyling: (styling:TemplateStyling) => Promise<void>
  updateStyling: (styling:TemplateStyling) => void
  handleSectionOver: (index:string|undefined) => void
  handleEntryOver: (index:string|undefined) => void
  handleSubSectionOver: (index:boolean) => void
  handleSubEntryOver: (index:string|undefined) => void
  handleSectionDragged: (index:boolean) => void
  handleEntryDragged: (index:boolean) => void
  updateTemplateType: (type:TemplateType) => void
  handleTemplateTone: (tone:string) => void
}

const TemplateContext = createContext<TemplateContextType>({
  name: undefined,
  items: initTemplate, 
  mapping: {},
  styling: {
    title_bold:true,
    abnormals_bolded: false,
    show_not_mentioned: true,
    bullet_points: true,
    list_bullet_points: true,
    abnormals_color: false,
    abnormals_uppercase: false
  },
  integration: undefined,
  entryHoverIndex: undefined,
  sectionHoverIndex: undefined,
  subSectionHover: false,
  subEntryHoverIndex: undefined,
  sectionDragged: undefined,
  entryDragged: undefined,
  templateType: TemplateType.MEDICAL,
  templateTone: communicationTemplateTones[0].value,
  templateLoading: true,
  addEntryToItem: () => { throw new Error('addEntryToItem not implemented'); },
  updateSectionName: () => { throw new Error('updateSectionName not implemented'); },
  updateSectionType: () => { throw new Error('updateSectionType not implemented'); },
  updateSectionDescription: () => { throw new Error('updateSectionDescription not implemented'); },
  updateSectionDefaultValue: () => { throw new Error('updateSectionDefaultValue not implemented'); },
  updateSectionHardcodedValue: () => { throw new Error('updateSectionHardcodedValue not implemented'); },
  addSection: () => {throw new Error('addSection not implemented');},
  updateName: () => {throw new Error('updateName not implemented')},
  deleteSection: () => {throw new Error('deleteSection not implemented')},
  updateEntryName: () => {throw new Error('updateEntryName not implemented')},
  updateEntryDescription: () => {throw new Error('updateEntryDescription not implemented')},
  updateEntryHardcodedValue: () => {throw new Error('updateEntryHardcodedValue not implemented')},
  updateEntryDefaultValue: () => {throw new Error('updateEntryDefaultValue not implemented')},
  updateEntryType: () => {throw new Error('updateEntryType not implemented')},
  deleteEntry: () => {throw new Error('deleteEntry not implemented')},
  swapSectionsByKeys: () => {throw new Error('swapSectionsByKeys not implemented')},
  swapEntries: () => {throw new Error('swapEntries not implemented')},
  swapSubEntries: () => {throw new Error('swapSubEntries not implemented')},
  saveTemplate: () => {throw new Error('saveTemplate not implemented')},
  validateTemplate: () => ["An error ocurred" , false],
  overrideTemplate: () => {throw new Error('saveTemplate not implemented')},
  updateEzyvetSectionName: () => {throw new Error('updateEzyvetSectionName not implemented')},
  updateEzyVetSectionCategory: () => {throw new Error('updateEzyVetSectionCategory not implemented')},
  updateEzyVetVitalCategory: () => {throw new Error('updateEzyVetVitalCategory not implemented')},
  saveMapping: () => {throw new Error('saveMapping not implemented')},
  updateIntegration: () => {throw new Error('updateIntegration not implemented')},
  saveStyling: () => {throw new Error('saveStyling not implemented')},
  updateStyling: () => {throw new Error('saveStyling not implemented')},
  handleSectionOver: () => {throw new Error('handleSectionOver not implemented')},
  handleEntryOver: () => {throw new Error('handleEntryOver not implemented')},
  handleSubSectionOver: () => {throw new Error('handleSubSectionOver not implemented')},
  handleSubEntryOver: () => {throw new Error('handleSubEntryOver not implemented')},
  handleSectionDragged: () => {throw new Error('handleSectionDragged not implemented')},
  handleEntryDragged: () => {throw new Error('handleEntryDragged not implemented')},
  updateTemplateType: () => {throw new Error('updateTemplateType not implemented')},
  handleTemplateTone: () => {throw new Error('handleTemplateTone not implemented')},
});

export const useTemplate = () => useContext(TemplateContext);

// Context provider component
export const TemplateProvider = ({ children }: { children: ReactNode }) => {
  const [saved, setSaved] = useState<boolean>(false)
  const [items, setItems] = useState<SectionType[]>(initTemplate)
  const [integration, setIntegration] = useState<string>()
  const [mapping, setMapping] = useState<{[key:string]:EzyVetSection}>({})
  const [styling, setStyling] = useState<TemplateStyling>({
    title_bold:true,
    abnormals_bolded: false,
    show_not_mentioned: true,
    bullet_points: true,
    list_bullet_points: true,
    abnormals_color: false,
    abnormals_uppercase: false
  })
  const [name, setName] = useState<string|undefined>(undefined)
  const { user } = useUser()
  const {getToken, orgId} = useAuth()
  const {uploadToSupabaseSignedURL} = useSupabase()
  const [existsingTemplate, setExistingTemplate] = useState<boolean>(false)
  const [templateId, setTemplateId] = useState<string>(uuidv4())
  const [templateType, setTemplateType] = useState<TemplateType>(TemplateType.MEDICAL)
  const updateName = (name:string) => {setName(name)}
  const [templateOrganization, setTemplateOrganization] = useState<string>()
  const [sectionHoverIndex, setSectionHoverIndex] = useState<string>()
  const [entryHoverIndex, setEntryHoverIndex] = useState<string>()
  const [subSectionHover, setSubSectionHover] = useState<boolean>(false)
  const [subEntryHoverIndex, setSubEntryHoverIndex] = useState<string>()
  const [sectionDragged, setSectionDragged] = useState<boolean>()
  const [entryDragged, setEntryDragged] = useState<boolean>()
  const [templateTone, setTemplateTone] = useState<string>(communicationTemplateTones[1].value)
  const [templateLoading, setTemplateLoading] = useState<boolean>(false)

  const sectionHoverIndexRef = useRef(sectionHoverIndex)
  const entryHoverIndexRef = useRef(entryHoverIndex)
  const subSectionHoverRef = useRef(subSectionHover)
  const subEntryHoverIndexRef = useRef(subEntryHoverIndex)

  useEffect(() => {
    sectionHoverIndexRef.current = sectionHoverIndex
  }, [sectionHoverIndex])

  useEffect(() => {
    entryHoverIndexRef.current = entryHoverIndex
  }, [entryHoverIndex])

  useEffect(() => {
    subSectionHoverRef.current = subSectionHover
  }, [subSectionHover])

  useEffect(() => {
    subEntryHoverIndexRef.current = subEntryHoverIndex
  }, [subEntryHoverIndex])

  const handleSectionOver = (index:string|undefined) => {
    setSectionHoverIndex(index)
  }

  const handleEntryOver = (index:string|undefined) => {
    setEntryHoverIndex(index)
  }

  const handleSubSectionOver = (index:boolean) => {
    setSubSectionHover(index)
  }

  const handleSubEntryOver = (index:string|undefined) => {
    setSubEntryHoverIndex(index)
  }

  const handleSectionDragged = (index:boolean) => {
    setSectionDragged(index)
  }

  const handleEntryDragged = (index:boolean) => {
    setEntryDragged(index)
  }

  const handleTemplateTone = (tone:string) => {
    setTemplateTone(tone)
  }

  const addSection = (newSection: SectionType) => {
    let sectionToAdd = { ...newSection, sectionKey: uuidv4() };
    setItems((currentItems) => [...currentItems, sectionToAdd]);
    
    if(sectionHoverIndexRef.current){
      swapSectionsByKeys(sectionHoverIndexRef.current, sectionToAdd.sectionKey)
    }
    handleEntryOver(undefined)
    handleSectionOver(undefined)
    handleSubEntryOver(undefined)
    handleSubSectionOver(false)
  };

  const deleteSection = (sectionKey:string) => {
    setItems((currentItems) => currentItems.filter(item => item.sectionKey !== sectionKey));
  };

  const addEntryToItem = (newEntry: EntryType) => {
    if(subSectionHoverRef.current){
      addEntryToSubentries(newEntry)
    }
    else{
      addEntryToSection(newEntry)
    }
  };

  const addEntryToSection = (newEntry: EntryType) => {
    if(sectionHoverIndexRef.current == undefined){
      return
    }

    let entryToAdd = { ...newEntry, entryKey: uuidv4() };
    setItems((currentItems) =>
      currentItems.map((item) =>
        item.sectionKey === sectionHoverIndexRef.current
          ? { ...item, entries: [...item.entries, entryToAdd] }
          : item
      )
    );
    if(entryHoverIndexRef.current){
      swapEntries(sectionHoverIndexRef.current, entryHoverIndexRef.current, entryToAdd.entryKey)
    }

    handleEntryOver(undefined)
    handleSectionOver(undefined)
    handleSubEntryOver(undefined)
    handleSubSectionOver(false)
  };

  const addEntryToSubentries = (newSubEntry: EntryType) => {
    if(sectionHoverIndexRef.current == undefined || entryHoverIndexRef.current == undefined){
      return
    }

    let subEntryToAdd = { ...newSubEntry, entryKey: uuidv4() };
    setItems((currentItems) =>
        currentItems.map((section) =>
            section.sectionKey === sectionHoverIndexRef.current
                ? {
                    ...section,
                    entries: section.entries.map((entry) =>
                        entry.entryKey === entryHoverIndexRef.current
                            ? { ...entry, subEntries: [...(entry.subEntries || []), subEntryToAdd] }
                            : entry
                    )
                }
                : section
        )
    );

    if(subEntryHoverIndexRef.current){
      swapSubEntries(sectionHoverIndexRef.current, entryHoverIndexRef.current, subEntryHoverIndexRef.current, subEntryToAdd.entryKey)
    }
    handleEntryOver(undefined)
    handleSectionOver(undefined)
    handleSubEntryOver(undefined)
    handleSubSectionOver(false)
  };

  const updateSectionName = (sectionKey: string, newName: string) => {
    setItems((currentItems) =>
      currentItems.map((item) =>
        item.sectionKey === sectionKey ? { ...item, name: newName } : item
      )
    );
  };

  const updateSectionDescription = (sectionKey: string, newDescription: string) => {
    setItems((currentItems) =>
      currentItems.map((item) =>
        item.sectionKey === sectionKey ? { ...item, description: newDescription } : item
      )
    );
  };

  const updateSectionDefaultValue = (sectionKey: string, newDefaultValue: string) => {
    setItems((currentItems) =>
      currentItems.map((item) =>
        item.sectionKey === sectionKey ? { ...item, defaultValue: newDefaultValue } : item
      )
    );
  };

  const updateSectionHardcodedValue = (sectionKey: string, hardcodedValue: string) => {
    setItems((currentItems) =>
      currentItems.map((item) =>
        item.sectionKey === sectionKey ? { ...item, hardcodedValue: hardcodedValue } : item
      )
    );
  };


  const updateSectionType = (sectionKey: string, type: SectionTypes) => {
    setItems((currentItems) =>
      currentItems.map((item) =>
        item.sectionKey === sectionKey ? { ...item, type: type } : item
      )
    );
  };

  const updateEntryName = (sectionKey: string, entryKey: string, newName: string, parentEntryKey?: string) => {
    if(!parentEntryKey){
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) =>
          section.sectionKey === sectionKey
            ? {
                ...section,
                entries: section.entries.map((entry) =>
                  entry.entryKey === entryKey ? { ...entry, name: newName } : entry
                ),
              }
            : section
        )
      );
    }
    else {
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) =>
          section.sectionKey === sectionKey
            ? {
                ...section,
                entries: section.entries.map((entry) =>
                  entry.entryKey === parentEntryKey ? { ...entry, subEntries: entry.subEntries?.map((subEntry) => subEntry.entryKey === entryKey ? { ...subEntry, name: newName } : subEntry) } : entry
                ),
              }
            : section
        )
      );
    }
  };

  const updateEntryDescription = (sectionKey: string, entryKey: string, newDescription: string | undefined, parentEntryKey?:string
  ) => {
    if(!parentEntryKey){
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) =>
          section.sectionKey === sectionKey
            ? {
                ...section,
                entries: section.entries.map((entry) =>
                  entry.entryKey === entryKey ? { ...entry, description: newDescription } : entry
                ),
              }
            : section
        )
      );
    }
    else{
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) =>
          section.sectionKey === sectionKey
            ? {
                ...section,
                entries: section.entries.map((entry) =>
                  entry.entryKey === parentEntryKey ? { ...entry, subEntries: entry.subEntries?.map((subEntry) => subEntry.entryKey === entryKey ? { ...subEntry, description: newDescription } : subEntry) } : entry
                ),
              }
            : section
        )
      );
    }
  };

  const updateEntryHardcodedValue = (sectionKey: string, entryKey: string, hardcodedValue: string | undefined, parentEntryKey?:string
  ) => {
    if(!parentEntryKey){
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) =>
          section.sectionKey === sectionKey
            ? {
                ...section,
                entries: section.entries.map((entry) =>
                  entry.entryKey === entryKey ? { ...entry, hardcodedValue: hardcodedValue } : entry
                ),
              }
            : section
        )
      );
    }
    else{
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) =>
          section.sectionKey === sectionKey
            ? {
                ...section,
                entries: section.entries.map((entry) =>
                  entry.entryKey === parentEntryKey ? { ...entry, subEntries: entry.subEntries?.map((subEntry) => subEntry.entryKey === entryKey ? { ...subEntry, hardcodedValue: hardcodedValue } : subEntry) } : entry
                ),
              }
            : section
        )
      );
    }
  };

  const updateEntryDefaultValue = (sectionKey: string, entryKey: string, newDefaultValue: string | undefined, parentEntryKey?: string) => {
    if(!parentEntryKey){
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) =>
          section.sectionKey === sectionKey
            ? {
                ...section,
                entries: section.entries.map((entry) =>
                  entry.entryKey === entryKey ? { ...entry, defaultValue: newDefaultValue } : entry
                ),
              }
            : section
        )
      );
    }
    else {
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) =>
          section.sectionKey === sectionKey
            ? {
                ...section,
                entries: section.entries.map((entry) =>
                  entry.entryKey === parentEntryKey ? { ...entry, subEntries: entry.subEntries?.map((subEntry) => subEntry.entryKey === entryKey ? { ...subEntry, defaultValue: newDefaultValue } : subEntry) } : entry
                ),
              }
            : section
        )
      );
    }
  };

  const updateEntryType = (sectionKey: string, entryKey: string, newType: ContentType, parentEntryKey?:string) => {
    if(!parentEntryKey){
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) =>
          section.sectionKey === sectionKey
            ? {
                ...section,
                entries: section.entries.map((entry) =>
                  entry.entryKey === entryKey ? { ...entry, type: newType } : entry
                ),
              }
            : section
        )
      );
    }
    else {
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) =>
          section.sectionKey === sectionKey
            ? {
                ...section,
                entries: section.entries.map((entry) =>
                  entry.entryKey === parentEntryKey ? { ...entry, subEntries: entry.subEntries?.map((subEntry) => subEntry.entryKey === entryKey ? { ...subEntry, type: newType } : subEntry) } : entry
                ),
              }
            : section
        )
      );
    }
  };

  const deleteEntry = (sectionKey: string, entryKey: string, parentEntryKey?:string) => {
    if(!parentEntryKey){
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) => {
          if (section.sectionKey === sectionKey) {
            // Filter out the entry with the given entryKey
            const updatedEntries = section.entries.filter((entry) => entry.entryKey !== entryKey);
            return { ...section, entries: updatedEntries };
          }
          return section;
        })
      );
    }
    else {
      setItems((currentItems: SectionType[]) =>
        currentItems.map((section) => {
          if (section.sectionKey === sectionKey) {
            // Filter out the subentry with the given entryKey
            const updatedEntries = section.entries.map((entry) => {
              if (entry.entryKey === parentEntryKey) {
                const updatedSubEntries = entry.subEntries?.filter((subEntry) => subEntry.entryKey !== entryKey);
                return { ...entry, subEntries: updatedSubEntries };
              }
              return entry;
            });
            return { ...section, entries: updatedEntries };
          }
          return section;
        })
      );
    }
  };

  const swapSectionsByKeys = (key1: string, key2: string) => {
    setItems((currentItems: SectionType[]) => {
      const index1 = currentItems.findIndex(section => section.sectionKey === key1);
      const index2 = currentItems.findIndex(section => section.sectionKey === key2);
  
      // Check if both sections are found, if not, return the current items without modification
      if (index1 === -1 || index2 === -1) return currentItems;
  
      // Initialize newItems at the beginning
      const newItems = [...currentItems];
  
      // Remove the item at index2 and insert it at index1
      const itemToMove = newItems.splice(index2, 1)[0];
      newItems.splice(index1, 0, itemToMove);
  
      // Correctly handle the shift-down operation
      if (index1 < index2) {
        // When moving from higher to lower, adjust the original item at index1 to move one position down
        const itemToShiftDown = newItems.splice(index1 + 1, 1)[0];
        newItems.splice(index1 + 1, 0, itemToShiftDown);
      } else {
        // When moving from lower to higher, no need to adjust as the other operations already manage the order
        // No additional code needed here
      }
  
      return newItems;
    });
  };

  const swapEntries = (sectionKey: string, entryKey1: string, entryKey2: string) => {
    setItems((currentItems: SectionType[]) =>
      currentItems.map((section) => {
        if (section.sectionKey === sectionKey) {
          const index1 = section.entries.findIndex(entry => entry.entryKey === entryKey1);
          const index2 = section.entries.findIndex(entry => entry.entryKey === entryKey2);
  
          // If either entry is not found, or they are the same, return the section unchanged
          if (index1 === -1 || index2 === -1 || index1 === index2) return section;
  
          const newEntries = [...section.entries];
  
          // Remove entry at index2 and insert it at index1
          const itemToMove = newEntries.splice(index2, 1)[0];
          newEntries.splice(index1, 0, itemToMove);
  
          if (index1 < index2) {
            // Adjust for when item is moved from a higher to a lower index
            const itemToShiftDown = newEntries.splice(index1 + 1, 1)[0];
            newEntries.splice(index1 + 1, 0, itemToShiftDown);
          } else {
            // Adjust for when item is moved from a lower to a higher index
            newEntries.splice(index1 + 1, 0, newEntries.splice(index1, 1)[0]);
          }
  
          // Return the section with updated entries
          return { ...section, entries: newEntries };
        }
        return section;
      })
    );
  };

  const swapSubEntries = (sectionKey: string, parentEntryKey: string, subEntryKey1: string, subEntryKey2: string) => {
      setItems((currentItems: SectionType[]) =>
          currentItems.map((section) => {
              if (section.sectionKey === sectionKey) {
                  // Find the index of the parent entry
                  const parentIndex = section.entries.findIndex(entry => entry.entryKey === parentEntryKey);

                  // If parent entry is not found, return the section unchanged
                  if (parentIndex === -1) return section;

                  const parentEntry = section.entries[parentIndex];
                  const subEntries = parentEntry.subEntries || [];

                  const subIndex1 = subEntries.findIndex(sub => sub.entryKey === subEntryKey1);
                  const subIndex2 = subEntries.findIndex(sub => sub.entryKey === subEntryKey2);

                  // If either subentry is not found, or they are the same, return the section unchanged
                  if (subIndex1 === -1 || subIndex2 === -1 || subIndex1 === subIndex2) return section;

                  // Swap the subentries
                  const newSubEntries = [...subEntries];
                  const temp = newSubEntries[subIndex1];
                  newSubEntries[subIndex1] = newSubEntries[subIndex2];
                  newSubEntries[subIndex2] = temp;

                  // Return the section with updated parent entry
                  return {
                      ...section,
                      entries: [
                          ...section.entries.slice(0, parentIndex),
                          { ...parentEntry, subEntries: newSubEntries },
                          ...section.entries.slice(parentIndex + 1)
                      ]
                  };
              }
              return section;
          })
      );
  };

  const updateTemplateType = (type:TemplateType) => {
    setTemplateType(type)
  }

  //Need to update save template to include mapping
  // Requires update to create template method and BE API
  const saveTemplate = async () => {
    UserAction(user?.emailAddresses[0].emailAddress ?? "", "TemplateSaved")
    const replacer = (key: any, value: any) => value === undefined ? null : value;
    let templateJSON = JSON.stringify(items, replacer)
    const blob = new Blob([templateJSON], {type: 'text/plain'})
    const file = new File([blob], `${templateId}.json`, {type: 'text/plain'})

    if(existsingTemplate){
      let folder = templateOrganization
      let signedURL = await GetTemplateSignedURL(`${templateId}`, await getToken({template:"supabase"}) ?? '')
      await uploadToSupabaseSignedURL(file, `${folder}/dynamic_${templateId}.json`, 'templates_v2', signedURL.signed_url.token)
      if(integration == IntegrationType.EZYVET){
        await updateTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveEzyVetMapping(mapping), styling, templateType, templateTone)
      }
      else if(integration == IntegrationType.INSTINCT){
        await updateTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveInstinctMapping(mapping), styling, templateType, templateTone)
      }
      else{
        await updateTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveEzyVetMapping(mapping), styling, templateType, templateTone)
      }
      setSaved(true)
      setExistingTemplate(true)
    }
    else{
      let folder = orgId ? orgId : user?.primaryEmailAddress?.emailAddress
      if(integration == IntegrationType.EZYVET){
        await createTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveEzyVetMapping(mapping), styling, templateType, templateTone)
      }
      else if(integration == IntegrationType.INSTINCT){
        await createTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveInstinctMapping(mapping), styling, templateType, templateTone)
      }
      else {
        await createTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveEzyVetMapping(mapping), styling, templateType, templateTone)
      }
      let signedURL = await GetTemplateSignedURL(`${templateId}`, await getToken({template:"supabase"}) ?? '')
      await uploadToSupabaseSignedURL(file, `${folder}/dynamic_${templateId}.json`, 'templates_v2', signedURL.signed_url.token)
      setTemplateOrganization(folder)
      setSaved(true)
      setExistingTemplate(true)
    }
  }

  const validateTemplate = (): [string, boolean] => {
    // check that there is a template name
    if (name == undefined) {
      return ["Please provide a template name", false];
    }
    // check that there are section names
    for (const item of items) {
      if (item.name == undefined) {
        return ["Please provide a name for all sections", false];
      } else {
        if(item.type == SectionTypes.NORMAL || item.type == SectionTypes.LIST || item.type == SectionTypes.COLLECTION) {
          if (item.entries.length === 0) {
            return ["Please add an entry to all sections", false];
          }
          for (const entry of item.entries) {
            if (entry.name == undefined) {
              return ["Please provide a name for all entries", false];
            }
            if(entry.type == ContentType.HARDCODED){
              if(entry.hardcodedValue == undefined){
                return ["Please ensure all fixed text entries have a value.", false];
              }
            }
            if(entry.type == ContentType.SUBSECTION){
              if(entry.subEntries && entry.subEntries.length > 0){
                for (const subEntry of entry.subEntries) {
                  if (subEntry.name == undefined) {
                    return ["Please provide a name for all sub-entries", false];
                  }
                }
              }
              else{
                return ["Please add an entry into all sub-sections", false];
              }
            }
          }
        }
        else if(item.type == SectionTypes.SIMPLE_LIST || item.type == SectionTypes.NUMBER || item.type == SectionTypes.PARAGRAPH){
          // Should we add validation for instructions?
        }
        else if (item.type == SectionTypes.HARDCODED){
          if(item.hardcodedValue == undefined){
            return ["Please ensure all fixed text sections have a value.", false];
          }
        }
      }
    }
    let duplicates = checkForDuplicateNames(items)
    if (!duplicates){
      return ["Please ensure all sections have unique names. Entries shouldn't have the same name as a section.", false];
    }
    // check that there are entry names
    return ["", true];
  };

  const overrideTemplate = (array_sections: { [key: string]: any }[]) : void => {
    let new_items:SectionType[] = []
    if(array_sections.length > 0){
      array_sections.forEach((section) => {
        let new_entries:EntryType[] = []
        if(section['entries'] && Array.isArray(section['entries']) && section['entries'].length > 0){
          section['entries'].forEach((entry) => {
            new_entries.push({
              entryKey: uuidv4(),
              name:entry['name'],
              description: entry['description'],
              defaultValue: entry['default_value'],
              type: ContentType[entry['type'] as keyof typeof ContentType]
            })
          })
        }
        new_items.push({
          sectionKey:uuidv4(),
          name: section['name'],
          type: SectionTypes[section['type'] as keyof typeof SectionTypes],
          entries: new_entries
        })
      })
    }
    setItems(new_items)
  }

  const updateEzyvetSectionName = (key: string, newName: string) => {
    const updatedMapping = { ...mapping };
    if (updatedMapping[key]) {
      updatedMapping[key].ezyvet_section_name = newName;
      setMapping(updatedMapping);
    }
  };

  const updateEzyVetSectionCategory = (key: string, newCategory: EzyVetCategories) => {
      const updatedMapping = { ...mapping };
      if (updatedMapping[key]) {
        updatedMapping[key].category = newCategory;
        setMapping(updatedMapping);
      }
  };

  const updateEzyVetVitalCategory = (sectionKey: string, entryKey:string, newCategory: EzyVetVitalsCategories) => {
    const updatedMapping = { ...mapping };
    if (updatedMapping[sectionKey]) {
      if(updatedMapping[sectionKey].inputs[entryKey]){
        updatedMapping[sectionKey].inputs[entryKey].inputName = newCategory;
      }
      else{
        updatedMapping[sectionKey].inputs[entryKey] = {
          name: entryKey,
          inputName: newCategory
        }
      }
      setMapping(updatedMapping);
    }
};

  const updateIntegration = (integration:string) => {
    UserAction(user?.emailAddresses[0].emailAddress ?? "", `TemplateIntegration_${integration}`)
    setIntegration(integration)
  }

  const saveMapping = async () => {
    UserAction(user?.emailAddresses[0].emailAddress ?? "", "TemplateMapping_Saved")
    if(saved){
      if(integration == IntegrationType.EZYVET){
        await updateTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveEzyVetMapping(mapping), styling, templateType, templateTone)
      }
      else if(integration == IntegrationType.INSTINCT){
        await updateTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveInstinctMapping(mapping), styling, templateType, templateTone)
      }
      else {
        await updateTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveEzyVetMapping(mapping), styling, templateType, templateTone)
      }
    }

  }

  const updateStyling = (styling:TemplateStyling) => {
    setStyling(styling)
  }

  const saveStyling =  async(styling:TemplateStyling) => {
    UserAction(user?.emailAddresses[0].emailAddress ?? "", "TemplateStyling_Saved")
    setStyling(styling)
    if(saved){
      if(integration == IntegrationType.EZYVET){
        await updateTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveEzyVetMapping(mapping), styling, templateType, templateTone)
      }
      else if(integration == IntegrationType.INSTINCT){
        await updateTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveInstinctMapping(mapping), styling, templateType, templateTone)
      }
      else{
        await updateTemplate(`dynamic_${templateId}`, name ?? "Template", await getToken({template:"supabase"}) ?? "", integration, saveEzyVetMapping(mapping), styling, templateType, templateTone)
      }
    }
  }

  const { search } = useLocation();

  useEffect(() => {
    const getTemplateFromParam = async () => {
      const queryParams = new URLSearchParams(search);
      const template_id_temp = queryParams.get('templateId');
      const duplicate =  queryParams.get('duplicate') == "true" ? true : false
      if(template_id_temp) {
        setTemplateLoading(true)
        let template_object = await fetchTempalteById(`dynamic_${template_id_temp}`, await getToken({template:"supabase"}) ?? "")
        let owner_VetRec = template_object['template']['owner'] == "VetRec" || template_object['template']['owner'] == "VetRec_Comms"
        setTemplateId(duplicate || owner_VetRec ? uuidv4() : template_id_temp)
        setExistingTemplate(duplicate || owner_VetRec ? false : true)
        let template = await GetTemplate(`dynamic_${template_id_temp}`, await getToken({template:"supabase"}) ?? '')
        setItems(JSON.parse(template ?? "") as SectionType[])
        setName(duplicate || owner_VetRec ? `${template_object['template']['name']} Copy` : template_object['template']['name'])
        setTemplateType(template_object['template']['type'] ?? TemplateType.MEDICAL)
        setTemplateLoading(false)
        setIntegration(template_object['template']['integration'])
        setTemplateTone(template_object['template']['tone'] ?? communicationTemplateTones[1].value)
        if(template_object['template']['mapping']) {
          let mapping = {}
          if(template_object['template']['integration'] == IntegrationType.EZYVET){
            mapping = ezyVetMappingToObject(template_object['template']['mapping'])
          }
          else if(template_object['template']['integration'] == IntegrationType.INSTINCT){
            mapping = instinctMappingToObject(template_object['template']['mapping'])
          }
          setMapping(mapping)
        }

        if(template_object['template']['styling']){
          let styling_temp: TemplateStyling = {
            title_bold: template_object.template.styling.title_bold === undefined ? true : template_object.template.styling.title_bold,
            abnormals_bolded: template_object.template.styling.abnormals_bolded === undefined ? false : template_object.template.styling.abnormals_bolded,
            abnormals_color: template_object.template.styling.abnormals_color === undefined ? false : template_object.template.styling.abnormals_color,
            abnormals_uppercase: template_object.template.styling.abnormals_uppercase === undefined ? false : template_object.template.styling.abnormals_uppercase,
            show_not_mentioned: template_object.template.styling.show_not_mentioned === undefined ? true : template_object.template.styling.show_not_mentioned,
            bullet_points: template_object.template.styling.bullet_points === undefined ? true : template_object.template.styling.bullet_points,
            list_bullet_points: template_object.template.styling.list_bullet_points === undefined ? true : template_object.template.styling.list_bullet_points
          }          
          setStyling(styling_temp)
          setTemplateOrganization(duplicate || owner_VetRec ?  (orgId ? orgId : user?.primaryEmailAddress?.emailAddress) : template_object['template']['organization'])
          setSaved(duplicate || owner_VetRec ? false : true)
        }
      }
    }
    getTemplateFromParam()
  }, []);

  useEffect(() => {
    let sections:{[key:string]:EzyVetSection}  = {}
    items.forEach((section) => {
        sections[section.name ?? ""] = {
          name: mapping && mapping[section.name ?? ""] ? mapping[section.name ?? ""].name : "",
          ezyvet_section_name: mapping && mapping[section.name ?? ""] ? mapping[section.name ?? ""].ezyvet_section_name : "",
          category: mapping && mapping[section.name ?? ""] ? mapping[section.name ?? ""].category : EzyVetCategories.HISTORY,
          inputs: mapping && mapping[section.name ?? ""] ? mapping[section.name ?? ""].inputs : {}
        }
    })
    setMapping(sections)
  }, [])

  useEffect(() => {
    let sections:{[key:string]:EzyVetSection}  = {}
    items.forEach((section) => {
        sections[section.name?.toLowerCase() ?? ""] = {
          name: mapping && mapping[section.name?.toLowerCase() ?? ""] ? mapping[section.name?.toLowerCase() ?? ""].name : "",
          ezyvet_section_name: mapping && mapping[section.name?.toLowerCase() ?? ""] ? mapping[section.name?.toLowerCase() ?? ""].ezyvet_section_name : "",
          category: mapping && mapping[section.name?.toLowerCase() ?? ""] ? mapping[section.name?.toLowerCase() ?? ""].category : EzyVetCategories.HISTORY,
          inputs: mapping && mapping[section.name?.toLowerCase() ?? ""] ? mapping[section.name?.toLowerCase() ?? ""].inputs : {}
        }
    })
    setMapping(sections)
  }, [items])

  const value = {
    name,
    items,
    mapping,
    integration,
    styling,
    entryHoverIndex,
    sectionHoverIndex,
    subSectionHover,
    subEntryHoverIndex,
    sectionDragged,
    entryDragged,
    templateType,
    templateTone,
    templateLoading,
    updateIntegration,
    addEntryToItem,
    updateSectionName,
    updateSectionType,
    updateSectionDescription,
    updateSectionDefaultValue,
    updateSectionHardcodedValue,
    addSection,
    updateName,
    deleteSection,
    updateEntryName,
    updateEntryDescription,
    updateEntryHardcodedValue,
    updateEntryDefaultValue,
    updateEntryType,
    deleteEntry,
    swapSectionsByKeys,
    swapEntries,
    swapSubEntries,
    saveTemplate,
    validateTemplate,
    overrideTemplate,
    updateEzyvetSectionName,
    updateEzyVetSectionCategory,
    updateEzyVetVitalCategory,
    saveMapping,
    saveStyling,
    updateStyling,
    handleSectionOver,
    handleEntryOver,
    handleSubSectionOver,
    handleSubEntryOver,
    handleSectionDragged,
    handleEntryDragged,
    updateTemplateType,
    handleTemplateTone
  }

  return <TemplateContext.Provider value={value}>{children}</TemplateContext.Provider>;
};

