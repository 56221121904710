import { TemplateType } from './components/templates/TemplateUtils';
import config from './config.json'

const url = process.env.REACT_APP_ENDPOINT ?? config.ENDPOINT;

interface SessionStatus {
    recording:string,
    transcript:string,
    notes:string,
    comms:string,
    manual_notes:string
}

interface SessionObject {
    id:string,
    created_timestamp:number,
    modified_timestamp:number,
    name:string,
    organization:string|undefined,
    owner:string,
    status:SessionStatus
}

export async function handleThumbsFeedbackServerSide(session:string, feedback:boolean, feedback_content:string, feedback_source: string, jwt:string){
    const endpoint = url + '/v2/thumbs_feedback';
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, feedback, feedback_content, feedback_source })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error handling thumbs feedback notification from the API.`);
    }
}

export async function integrationRequest(name:string, jwt:string){
    const endpoint = url + '/v2/integration_request';
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ name})
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error handling integration request from the API.`);
    }
}

export async function templateRequest(jwt:string){
    const endpoint = url + '/v2/tempalate_request';
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error handling template request from the API.`);
    }
}

export async function generateTemplate(sample_report:string, jwt:string){
    const endpoint = url + '/v1/generate_template';
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({sample_report})
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error handling generate template request from the API.`);
    }
}

export async function createSession(session:string, name:string, jwt:string, manual_notes:boolean, recording:boolean, consent:boolean, owner?:string, timestamp?:number) {
    const endpoint = url + '/v2/create_session';
    let source ="web"
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session, name, manual_notes, consent, recording, owner, source, timestamp })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function processRecording(session:string, name:string, jwt:string, manual_notes:boolean, template_id:string|undefined, consent:boolean) {
    const endpoint = url + '/v2/process_recording';
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, name, manual_notes, consent, template_id })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error fetching from the API. Error: ${error}`);
    }
}

export async function reprocessRecording(session:string, name:string, jwt:string, manual_notes:boolean, template_id:string|undefined, consent:boolean) {
    const endpoint = url + '/v2/reprocess_session';
    let response;
    try{
        response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, name, manual_notes, consent, template_id })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error fetching from the API. Error: ${error}`);
    }
}

export async function generateSoapNotes(session:string, name:string, template_id:string, transcript:boolean, manual_notes:boolean, jwt:string) {
    const endpoint = url + '/v2/soap_notes';
    try{
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, name, transcript, manual_notes, template_id })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error fetching from the API. Error: ${error}`);
    }

}

export async function generateDischargeNotes(session:string, name:string, template_id:string, jwt:string) {
    const endpoint = url + '/v2/discharge_notes';
    try{
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, name, template_id })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error fetching from the API. Error: ${error}`);
    }

}

export async function translateDischargeNotes(language:string, session:string, jwt:string){
    const endpoint = url + '/v1/discharge_translation';
    try{
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, language })
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }
    catch (error)
    {
        throw new Error(`There was an error fetching from the API. Error: ${error}`);
    }
}

export async function generateSOAPFollowup(session:string, msg:string, jwt:string) {
    const endpoint = url + '/v1/soap_notes_followup';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session, msg })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export interface message{
    role:string,
    message:string
}

export async function generateAssisstant(messages:Array<message>, jwt:string) {
    const endpoint = url + '/v1/assistant';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ messages })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

// Function to fetch all sessions
export async function fetchSessions(jwt:string) {
    return fetch(url + '/v1/all_sessions', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`There was an error fetching sessions. Error: ${error}`);
    });
}

export async function fetchSessionsDate(jwt:string, startDate:string, endDate:string) {
    return fetch(url + '/v1/sessions_by_date/' + startDate + '/' + endDate, {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`There was an error fetching sessions. Error: ${error}`);
    });
}

export async function fetchSessionsName(jwt:string, name:string) {
    return fetch(url + '/v1/sessions_by_name/' + name, {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`There was an error fetching sessions. Error: ${error}`);
    });
}

// Function to fetch all organization sessions
export async function fetchOrganizationSessions(jwt:string) {
    return fetch(url + '/v1/organization_sessions', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`Error fetching org sessions: ${error}`);
    });
}

// Function to fetch all active sessions
export async function fetchActiveSessions(jwt:string) {
    return fetch(url + '/v1/all_active_sessions', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`There was an error fetching active sessions. Error: ${error}`);
    });
}

// Function to fetch all active sessions for org
export async function fetchOrganizationActiveSessions(jwt:string) {
    return fetch(url + '/v1/organization_active_sessions', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['sessions'];
    })
    .catch(error => {
        throw new Error(`Error fetching org active sessions: ${error}`);
    });
}

// Function to fetch a specific session by ID
export async function fetchSessionById(sessionId:string, jwt:string) {
    // let sessions : SessionObject[] = await fetchSessions(jwt)
    // let theSession = sessions.find(session => session.id === sessionId) 
    // return theSession
    return fetch(url + `/v1/session/${sessionId}`, {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['session'];
    })
    .catch(error => {
        throw new Error(`Error fetching session: ${error}`);
    });
}

export async function saveName(session:string, name:string, jwt:string) {
    return fetch(url + `/v1/update_name`, {
        method: 'POST',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ session, name })
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        throw new Error(`Error updating name: ${error}`);
    });
}

export async function saveOwner(session:string, owner:string, jwt:string) {
    return fetch(url + `/v1/update_owner`, {
        method: 'POST',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ session, owner })
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        throw new Error(`Error updating name: ${error}`);
    });
}

// Function to fetch all templates
export async function fetchTemplates(jwt:string, type?:TemplateType) {
    return fetch(url + '/v1/templates' + (type ? `?type=${type}` : ""), {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['templates'];
    })
    .catch(error => {
        throw new Error(`Error fetching templates: ${error}`);
    });
}

// Function to fetch all templates for org
export async function fetchOrgTemplates(jwt:string, type?:TemplateType) {
    return fetch(url + '/v1/organization_templates' + (type ? `?type=${type}` : ""), {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['templates'];
    })
    .catch(error => {
        throw new Error(`Error fetching org templates: ${error}`);
    });
}

// Function to fetch all templates for org
export async function fetchTemplateLibrary(jwt:string) {
    return fetch(url + '/v1/template_library', {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data['templates'];
    })
    .catch(error => {
        throw new Error(`Error fetching library templates: ${error}`);
    });
}

// Function to fetch a specific session by ID
export async function fetchTempalteById(templateId:string, jwt:string) {
    return fetch(url + `/v1/template/${templateId}`, {
        method: 'GET',
        headers: {
            // Include headers for authentication as needed
            'Authorization':'Bearer '+ jwt,
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
        throw new Error(`Error fetching template: ${error}`);
    });
}

export async function createTemplate(template_id:string, name:string, jwt:string, integration: string | undefined, mapping: {} | undefined, styling: {} | undefined, templateType: TemplateType, tone:string) {
    const endpoint = url + '/v1/template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ name, template_id, integration: integration === undefined ? null : integration, mapping, styling, type: templateType, tone})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function updateTemplate(template_id:string, name:string, jwt:string, integration: string | undefined, mapping: {} | undefined, styling: {} | undefined, templateType: TemplateType, tone:string) {
    const endpoint = url + '/v1/update_template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ name, template_id, integration: integration === undefined ? null : integration, mapping, styling, type: templateType, tone})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function deleteTemplate(template_id:string, jwt:string) {
    const endpoint = url + '/v1/delete_template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ template_id })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function postDefaultTemplate(template_id:string, jwt:string) {
    const endpoint = url + '/v2/default_template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ template_id })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function postDefaultDischargeTemplate(template_id:string, jwt:string) {
    const endpoint = url + '/v2/default_discharge_template';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ template_id })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function cancelSession(session:string, jwt:string) {
    const endpoint = url + '/v1/cancel_session';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session })
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function recordSession(session:string, recording:boolean, manual_notes:boolean, microphone_name: string, jwt:string) {
    try{
        const endpoint = url + '/v1/recording_session';
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, recording, manual_notes, microphone_name })
        });
        if (!response.ok) {
            //throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }catch{
        
    }
}

export async function updatedSession(session:string, jwt:string) {
    try{
        const endpoint = url + '/v1/updated_session';
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session })
        });
        if (!response.ok) {
            //throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
    }catch{
        
    }
}

interface UsageResponse {
    num_sessions: number;
}

export async function fetchUsagePerUser(jsw: string): Promise<UsageResponse> {
    const endpoint = url + '/v1/usage';
  
    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jsw}`, // Assuming the token is used for user validation
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
      const data: UsageResponse = await response.json();
      return data;
    } catch (error) {
      console.error('Failed to fetch usage data', error);
      return { num_sessions: 0 }; // Return a default object in case of error
    }
}

export async function setNotifyMe(session:string, jwt:string) {
    const endpoint = url + '/v1/notifyme';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function intakeMessage(session:string, message:string, first:boolean) {
    let url_intake = "https://vetrec-intake.fly.dev"
    const endpoint = url_intake + '/v2/process_message';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ session,  message, first})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function markSessionCompleted(session:string, completed:boolean, jwt:string) {
    const endpoint = url + '/v1/session_completed/' + session;
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ status: completed})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return true
}

export async function processPDFSummary(session:string, jwt:string) {
    const endpoint = url + '/v1/processPDFSummary';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({session})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return true
}

export async function updateLanguage(language:string, jwt:string) {
    try {
        const response = await fetch(url + '/v1/update_language', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ language })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error updating language: ${errorDetail.detail}`);
        }

        return true;
    } catch (error) {
        console.error('Failed to update language:', error);
        return false;
    }
}

export async function downloadSoapPDF(session:string, jwt:string) {
    try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await fetch(url + '/v1/generatePDF', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, timezone })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error download soap PDF: ${errorDetail.detail}`);
        }

        return response.json();
    } catch (error) {
        console.error('Failed to download soap PDF:', error);
        return false;
    }
}

export async function downloadDischargePDF(session:string, jwt:string) {
    try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await fetch(url + '/v1/generateDischargePDF', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, timezone })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error download discharge PDF: ${errorDetail.detail}`);
        }

        return response.json();
    } catch (error) {
        console.error('Failed to download discharge PDF:', error);
        return false;
    }
}

export async function downloadPreHistoryPDF(session:string, jwt:string) {
    try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await fetch(url + '/v1/generatePreHistoryPDF', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, timezone })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`Error download soap PDF: ${errorDetail.detail}`);
        }

        return response.json();
    } catch (error) {
        console.error('Failed to download soap PDF:', error);
        return false;
    }
}

export async function transferToVetspire(session:string, patientId:string, jwt:string) {
    try {
        const response = await fetch(url + '/v1/transferToVetspire', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':'Bearer '+ jwt
            },
            body: JSON.stringify({ session, patientId })
        });

        if (!response.ok) {
            const errorDetail = await response.json();
            throw new Error(`${errorDetail.detail}`);
        }

        return response.json();
    } catch (error) {
        throw new Error(`Error transferring to Vetspire: ${error}`);
    }
}

export async function logClientAudit(action:string, item_type:string, item_id:string, metadata:{[key: string]: string}, jwt:string) {
    const endpoint = url + '/v1/client_audit_log';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ action, item_type, item_id, metadata})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}

export async function deleteSession(session_id:string, jwt:string) {
    const endpoint = url + '/v1/delete_session';
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization':'Bearer '+ jwt
        },
        body: JSON.stringify({ session_id})
    });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
}