import { XCircleIcon } from '@heroicons/react/24/solid';
import React, { ChangeEvent, DragEvent, FC } from 'react';

// Define the type for the props
interface FileDropZoneProps {
  files: File[]
  onFileUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  invalidFilesUploaded: boolean;
  subText?: string;
  accept?: string;
}

const FileDropZone: FC<FileDropZoneProps> = ({ files, invalidFilesUploaded, onFileUpload, subText = 'Supported file types: .pdf', accept = '.pdf' }) => {
  // Handler for drag over event
  const handleDragOver = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault(); // Prevent default behavior
  };

  // Handler for file drop event
  const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      onFileUpload({ target: { files } } as ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div className="flex-col items-center justify-center w-full mt-4">
      {files.length > 0 && <div className="flex flex-col items-start justify-start w-full rounded-lg mb-4">
        <div className="flex flex-col w-full">
          <span className="font-medium text-sm text-gray-700">Files Selected</span>
          <div className="text-sm text-gray-500 dark:text-gray-400 mt-4 flex flex-col gap-y-4 border rounded-md border-gray-300 p-2 py-3">
            {files.map((file, index) => (
              <div key={index} className='flex flex-row justify-between w-full'>
                <span key={index} className='text-sm'>{file.name}</span>
                <button
                  type="button"
                  className="ml-4"
                  onClick={() => {
                    files.splice(index, 1);
                    onFileUpload({ target: { files } } as unknown as ChangeEvent<HTMLInputElement>);
                  }}
                >
                  <XCircleIcon className='h-4 w-4 text-red-600' />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>}
      {<label
        htmlFor="dropzone-file"
        className={`flex flex-col items-center justify-center w-full h-40 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 ${invalidFilesUploaded ? 'border-red-500' : 'border-gray-300'}`}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div className="flex flex-col items-center justify-center pt-5 pb-6 px-6">
          <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5 a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
          </svg>
          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
            <span className="font-semibold">Click to upload</span> or drag and drop
          </p>
          <p className="text-xs text-gray-500 dark:text-gray-400 text-center">
            {subText}
          </p>
        </div>
        <input
          id="dropzone-file"
          type="file"
          className="hidden"
          accept={accept}
          onChange={onFileUpload}
          multiple
        />
      </label>}
    </div>
  );
};

export default FileDropZone;
