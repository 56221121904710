export interface IMimeTypeExtensionMap {
    [mimeType: string]: string;
}

export class TimeoutError extends Error {
    constructor(message: string = 'Timeout occurred') {
        super(message);
        this.name = 'TimeoutError';
    }
}

export interface IMicrophone {
    deviceId: string;
    label: string;
}

export const mimeTypeExtensionMap: IMimeTypeExtensionMap = {
    'audio/mpeg': '.mp3',
    'audio/mp4': '.mp4',  // or .m4a
    'audio/wav': '.wav',
    'audio/flac': '.flac',
    'audio/ogg': '.ogg',
    'audio/x-aiff': '.aif',  // or .aiff
    'audio/webm': '.webm',
    'audio/amr': '.amr',
    'audio/x-wav': '.wav',
    'audio/3gpp': '.3gp',
    'audio/3gpp2': '.3g2',
    'audio/aac': '.aac',
    'audio/vnd.wav': '.wav',
    'audio/webm;codecs=opus': '.webm', // Opus codec
    'audio/mp4;codecs=mp4a.40.2': '.m4a', // AAC codec in M4A container
    'audio/ogg;codecs=vorbis': '.ogg', // Vorbis codec in OGG container
    'audio/ogg;codecs=flac': '.ogg', // FLAC codec in OGG container
    'audio/mp4;codecs=mp3': '.mp4', // MP3 codec in MP4 container
    'audio/mp4;codecs=ac3': '.mp4', // AC-3 codec in MP4 container
    'audio/mp4;codecs=ec-3': '.mp4', // E-AC-3 codec in MP4 container
    'audio/webm; codecs=opus': '.webm', // Opus codec
    'audio/mp4; codecs=mp4a.40.2': '.m4a', // AAC codec in M4A container
    'audio/ogg; codecs=vorbis': '.ogg', // Vorbis codec in OGG container
    'audio/ogg; codecs=flac': '.ogg', // FLAC codec in OGG container
    'audio/mp4; codecs=mp3': '.mp4', // MP3 codec in MP4 container
    'audio/mp4; codecs=ac3': '.mp4', // AC-3 codec in MP4 container
    'audio/mp4; codecs=ec-3': '.mp4', // E-AC-3 codec in MP4 container
    'audio/x-m4a': '.m4a',
    'video/webm': '.webm',
    
    // Add more mappings as needed
};

export function isWakeLockSupported() {
    return "wakeLock" in navigator;
}

export async function checkMicrophonePermissions(timeout: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
        
        const timeoutId = setTimeout(() => {
            reject(new TimeoutError("Timeout occured")); // Reject if timeout occurs
        }, timeout);

        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream: MediaStream) => {
                clearTimeout(timeoutId)
                // Microphone access was granted
                stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
                resolve(true);
            })
            .catch((err: any) => {
                // Microphone access was denied or an error occurred
                clearTimeout(timeoutId)
                reject(err);
            });
    });
}

export async function getMicrophonesAvailable(): Promise<IMicrophone[]> {
    let devices = await navigator.mediaDevices.enumerateDevices()
    const mics: IMicrophone[] = devices
        .filter(device => device.kind === 'audioinput')
        .map(device => ({
            deviceId: device.deviceId,
            label: device.label || `Microphone ${device.deviceId}`
        }));
    return mics
}

export interface IMicrophone {
    deviceId: string;
    label: string;
}

export const formatTime = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;

    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = secs.toString().padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};