import { Fragment, useEffect, useMemo, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, CloudArrowUpIcon, LanguageIcon } from '@heroicons/react/24/solid'
import { useHistory } from '../../providers/HistoryProvider'
import { FileUploader } from '../uploadComponents/FileUploader'
import { useAuth, useUser } from '@clerk/clerk-react'
import { GetRecordingsSignedURL } from '../../supabase/supabaseProxy'
import { useSupabase } from '../../supabase/SupabaseContext'
import { forEach, set } from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import { mimeTypeExtensionMap } from '../../utils/RecordingUtils'
import va from "@vercel/analytics";
import { upload } from '@testing-library/user-event/dist/upload'
import { Spinner } from '../../utils/Spinner'
import { updatedSession } from '../../ServerActions'

export default function UploadRecordingModal() {

    const {activeSession, uploadRecordingModal, setUploadRecordingModal, refreshActiveSession} = useHistory()
    const { getToken } = useAuth()
    const { user } = useUser()
    const { uploadRecordingToSupabase } = useSupabase()
    const [filesToUpload, setFilesToUpload] = useState<File[]>([])
    const [uploadedFiles, setUploadedFiles] = useState<string[]>([])
    const [uploading, setUploading] = useState<boolean>()
    const [uploadingFile, setUploadingFile] = useState<string>()
    const [uploadPercentage, setUploadPercentage] = useState<number>(0) 
    const [uploadFailed, setUploadFailed] = useState<boolean>(false)
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false)

    useEffect(() => {
        setUploadFailed(false);
        setUploadedFiles([]);
        setFilesToUpload([]);
        setUploading(false);
        setUploadingFile(undefined);
        setUploadPercentage(0);
        setUploadSuccess(false);
    }, [uploadRecordingModal])

    const uploadFile = async (file: File) => {
        //Retry getting token
        let token = await getToken({ template: "supabase" });
        if(!token){
            token = await getToken({ template: "supabase" }) ?? "";
        }

        const maxRetries = 3; // Max retry attempts
        let attempt = 0;
        let success = false;

        const mimeType = file.type;
        const fileExtension = mimeTypeExtensionMap[mimeType];
        const sessionId = activeSession?.id ?? "";
        const owner = activeSession?.owner
        const recordingId = uuidv4();
        const fileName = `${sessionId}_${recordingId}${fileExtension}`;

        // Upload using signed URL
        while (attempt < maxRetries && !success) {
            try {
                const signed_url = await GetRecordingsSignedURL(sessionId ?? "", recordingId, fileExtension.split(".")[1], token);
                await uploadRecordingToSupabase(file, `${owner ?? user?.primaryEmailAddress?.emailAddress}/${sessionId}/${fileName}`, "recordings", mimeType, signed_url.signed_url.token, sessionId, setUploadPercentage);
                success = true; // Break loop on success
            } catch (error) {
                attempt++;
                let errorMessage = "An unknown error occurred";
                if (error instanceof Error) {
                    errorMessage = error.message;
                }
                if (attempt >= maxRetries) {
                    setUploadFailed(true);
                    va.track("Recording_Manual_Upload_Failed", {
                        date: new Date().toUTCString(),
                        sessionId: sessionId,
                        error: errorMessage
                    });
                } else {
                    const backoffTime = 500 * Math.pow(2, attempt); // Exponential backoff
                    await new Promise(resolve => setTimeout(resolve, backoffTime));
                }
            }
        }
    }

    const onSubmitButtonClick = async (files: File[]) => {
        if(!activeSession){
            return;
        }
        let token = await getToken({ template: "supabase" });
        setFilesToUpload(files);
        setUploadFailed(false);
        setUploading(true);
        for (let i = 0; i < files.length; i++) {
            setUploadingFile(files[i].name);
            await uploadFile(files[i]);
            setUploadedFiles((previous) => [...previous, files[i].name]);
            setUploadingFile(undefined);
            setUploadPercentage(0);
        }
        await updatedSession(activeSession.id, token ?? "")
        await refreshActiveSession()
        setUploadSuccess(true);
        setUploading(false);
    }

    return (
        <Transition.Root show={uploadRecordingModal} as={Fragment}>
            <Dialog as="div" className="relative z-[999999]" onClose={() => console.log()}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                    <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                                <CloudArrowUpIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                Upload a recording
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Upload an existing audio recording to generate notes.
                                    </p>
                                </div>
                            </div>
                        </div>
                        {uploading && <div className="mt-4 flex flex-col gap-y-2">
                            {filesToUpload.length > 0 && filesToUpload.map((file) => (
                                <div className='flex justify-between'>
                                    <span className='truncate w-60'>{file.name}</span>
                                    {uploadingFile === file.name && <span>{uploadPercentage}%</span>}
                                    {uploadingFile != file.name && !uploadedFiles.includes(file.name) && <Spinner size='h-4 w-4' timer={false}/>}
                                    {uploadedFiles.includes(file.name) && <CheckCircleIcon className="h-4 w-4 text-green-600" aria-hidden="true" />}
                                </div>
                            ))}
                        </div>}
                        {!uploading && !uploadSuccess && <FileUploader sessionId={activeSession?.id} onSubmitButtonClick={onSubmitButtonClick} submitButtonText='Upload'/>}
                        {uploadSuccess && <div className='w-full flex justify-center mt-4'>
                            <span className="text-lg text-green-600 w-full flex flex-row items-center gap-x-2 justify-center">
                                Upload successful. <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                            </span>
                        </div>}
                        {uploadFailed && <div>
                            <span className="text-xs text-red-600">
                                Upload failed. Please try again.
                            </span>
                        </div>}
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2 mt-4"
                            onClick={() => setUploadRecordingModal(false)}
                        >
                            Close
                        </button>
                    </Dialog.Panel>
                    </Transition.Child>
                </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}