import React, { createContext, useContext, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSupabase } from '../supabase/SupabaseContext';
import { CheckRecordings, GetAuditLogs, GetDischarge, GetFeed, GetNotes, GetPreHistory, GetRecordings, GetTemplate, GetTranscript } from '../supabase/supabaseProxy';
import { MemberObject } from '../utils/MemberUtils';
import { AuditLogs, filterCancelledSessions, filterSessions, SessionObject } from '../utils/SessionUtils';
import { TemplateObject } from '../utils/TemplateObject';
import { useEffect,useRef, useState } from "react"
import { fetchSessionById, generateSoapNotes, processRecording, translateDischargeNotes, handleThumbsFeedbackServerSide, markSessionCompleted, saveName, createSession, saveOwner, fetchSessionsDate, fetchSessionsName, generateDischargeNotes, reprocessRecording, deleteSession} from "../ServerActions"
import { DateValueType } from 'react-tailwindcss-datepicker';
import { PreHistoryDefinition, PreHistoryV2Definition } from '../utils/PreHistoryDefinition';
import { Confirmation } from '../utils/ConsentsUtils';
import { v4 as uuidv4 } from 'uuid';
import va from '@vercel/analytics';
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react"
import { preprocessDischargeText, preprocessText, revertPreprocessedText, splitNoteIntoSectionWithDefinition } from '../utils/TextUtils';
import { getTemplateName } from '../utils/TemplateUtils';
import { isMobileDevice } from "../utils/deviceUtils"
import useDebounce from '../utils/useDebounce';
import { SectionType } from '../components/templates/TemplateUtils';
import { forEach } from 'lodash';
import { start } from 'repl';

interface HistoryStateContextType {
    activeSession: SessionObject | undefined;
    setActiveSession: (value: SessionObject | undefined) => void;
    filteredSessions: SessionObject[] | undefined;
    availableSession: boolean;
    sessions: SessionObject[] | undefined;
    sessionTemplateName: string | undefined;
    tab: string;
    notesProcessing: boolean;
    transcriptProcessing: boolean;
    preHistoryProcessingMessage: string | undefined;
    preHistoryFailureMessage: string | undefined;
    recordingProcessing: boolean;
    feedProcessing: boolean;
    dischargeProcessing: boolean
    preHistoryStreaming: boolean;
    notesStreaming: boolean;
    dischargeStreaming: boolean;
    notesStreamCompleted: boolean;
    notesLoading: string | undefined;
    dischargeLoading: string | undefined;
    preHistoryLoading: string | undefined;
    recordingLoading: string | undefined;
    feedLoading: string | undefined;
    transcriptLoading: string | undefined;
    loadingSessionInfo: string | undefined;
    loadingSessions: boolean
    activeRecordingBackUp: boolean | undefined;
    activeRecordingExist: boolean | undefined;
    recordingCheckLoading: string | undefined;
    listContainerRef: any;
    scrollToItemRef: any;
    sessionFromParam: string | undefined;
    activeSessionUpdated: boolean;
    handleTabChange: (value: string) => void
}   

interface HistoryContentContextType{
    language: string;
    activeNotes: string | undefined;
    activeAuditLogs: AuditLogs[] | undefined;
    notesSections: Record<string, string> | undefined;
    displayActiveNotes: string
    activeDischarge: string | undefined
    displayActiveDischarge: string
    activeTranscript: string | undefined;
    activeConsents: Confirmation[] | undefined;
    activePreHistory: PreHistoryV2Definition | undefined;
    activeRecording: string[] | undefined;
    template: TemplateObject | undefined;
    templateDischarge: TemplateObject | undefined;
    setDisplayActiveNotes: (value: string) => void
    setDisplayActiveDischarge: (value: string) => void
    setTemplate: (value: TemplateObject) => void
    setTemplateDischarge: (value: TemplateObject) => void
    setLanguage: (value: string) => void
}

interface HistoryActionsContextType {
    feedbackNotes: boolean;
    feedbackDischarge: boolean
    toggleSections: boolean
    handleMarkComplete: () => void
    handleAddToSession: () => void
    handleThumbsInput: (value: boolean, type: string) => void
    handleLanguageChange: (change:boolean) => void
    handleCreatePatient: (value: string, owner?:string, timestamp?:number) => Promise<void>
    processTranscript: (value: boolean) => void
    processRecordings: (reprocess:boolean) => void
    handleSaveOwner: () => void
    translateDischarge: () => void
    handleToggleSections: () => void
    handleNotesTemplateChange: (change:boolean) => void
    handleDischargeTemplateChange: (change:boolean) => void
    handleSaveName: () => void
    refreshActiveSession: () => Promise<void>
    refreshAuditLogs: (sessionObject:SessionObject) => void
    setUserSearch: (value: MemberObject | undefined) => void
    selectSession: (value: SessionObject) => void
    generateDischarge: (regenerate:boolean) => void
    handleDeleteVisit: () => void
    refreshSessionObject: (sessionObject:SessionObject) => void
}

interface HistoryFiltersContextType {
    searchDate: DateValueType
    membersList: MemberObject[]
    userSearch: MemberObject | undefined;
    debouncedQuery: string | undefined;
    setSearchDate: (value: DateValueType) => void
    handleHistorySearch: (value: string) => void
}

interface HistoryAlertsContextType {
    deleteVisitModal: boolean;
    feedbackModal: boolean;
    createVisitModal: boolean;
    feedbackModalSource: string;
    changeTemplate: boolean;
    changeDischargeTemplate: boolean;
    changeLanguage: boolean;
    notification: boolean;
    notificationTitle: string | undefined;
    notificationText: string | undefined;
    error: string | undefined;
    warning: string | undefined;
    uploadRecordingModal: boolean;
    setNotification: (value: boolean) => void
    setNotificationTitle: (value: string | undefined) => void
    setNotificationText: (valuve:string | undefined) => void
    setError: (value: string | undefined) => void
    setWarning: (value: string | undefined) => void
    setFeedbackModal: (value: boolean) => void
    getLoggingProperties: () => Record<string, string>
    setCreateVisitModal: (value: boolean) => void
    setDeleteVisitModal: (value: boolean) => void
    setUploadRecordingModal: (value: boolean) => void
}

type CombinedHistoryContextType = 
    HistoryStateContextType & 
    HistoryContentContextType & 
    HistoryActionsContextType & 
    HistoryFiltersContextType & 
    HistoryAlertsContextType;

const HistoryContext = createContext<CombinedHistoryContextType | undefined>(undefined);

export const useHistory = () => {
  const context = useContext(HistoryContext);
  if (!context) {
    throw new Error('useHistsory must be used within a HistoryProvider');
  }
  return context;
};

interface HistoryProviderProps {
  children: ReactNode;
}

export const HistoryProvider: React.FC<HistoryProviderProps> = ({ children }) => {

        // General controls
        const [sessions, setSessions] = useState<SessionObject[]>([])
        const [filteredSessions, setFilteredSessions] = useState<SessionObject[]>([])
        const [availableSession, setAvailableSessions] = useState<boolean>(false)
        const [searchDate, setSearchDate] = useState<DateValueType>({
            startDate: new Date(),
            endDate: new Date()
        });        
        const [searchValue, setSearchValue] =  useState<string|undefined>(undefined)
        const [notesLoading, setNotesLoading] = useState<string|undefined>(undefined)
        const [feedLoading, setFeedLoading] = useState<string|undefined>(undefined)
        const [recordingLoading, setRecordingLoading] = useState<string|undefined>(undefined)
        const [transcriptLoading, setTranscriptLoading] = useState<string|undefined>(undefined)
        const [dischargeLoading, setDischargeLoading] = useState<string|undefined>(undefined)
        const [preHistoryLoading, setPreHistoryLoading] = useState<string|undefined>(undefined)
        const [loadingSessions , setLoadingSessions] = useState<boolean>(true)
        const [loadingSessionInfo, setLoadingSessionInfo] = useState<string|undefined>(undefined)
        const { getToken, orgId} = useAuth();
        const { user } = useUser()
        const { organization } = useOrganization()
        const  navigate = useNavigate()
        const [error, setError] = useState<string|undefined>(undefined)
        const [warning, setWarning] = useState<string|undefined>(undefined)
        const [tab, setTab] = useState<string>("overview")
        const [userSearch, setUserSearch] = useState<MemberObject | undefined>({
            name: (user?.firstName && user?.lastName) ? user.firstName + " " + user.lastName : undefined,
            identifier: user?.primaryEmailAddress?.emailAddress ?? ""
        })
        const [feedbackModal, setFeedbackModal] = useState<boolean>(false)
        const [feedbackModalSource, setFeedbackModalSource] = useState<string>("")
    
        // Selected session
        const [activeSession, setActiveSession] = useState<SessionObject|undefined>(undefined)
        const activeSessionRef = useRef(activeSession);
        const [activeTranscript, setActiveTranscript] = useState<string|undefined>(undefined)
        const [activeNotes, setActiveNotes] = useState<string|undefined>(undefined)
        const [activeAuditLogs, setActiveAuditLogs] = useState<AuditLogs[]>()
        const [activePreHistory, setActivePreHistory] = useState<PreHistoryV2Definition | undefined>(undefined)
        const [activeRecording, setActiveRecording] = useState<string[]|undefined>(undefined);
        const [activeDischarge, setActiveDischarge] = useState<string|undefined>(undefined)
        const [activeConsents, setActiveConsents] = useState<Confirmation[]|undefined>(undefined)
        const [activeRecordingBackUp, setActiveRecordingBackUp] = useState<boolean|undefined>(false);
        const [activeRecordingExist, setActiveRecordingExist] = useState<boolean|undefined>(false);
        const [activeSessionUpdated, setActiveSessionUpdated] = useState<boolean>(false)
        const [recordingCheckLoading, setRecordingCheckLoading] = useState<string|undefined>(undefined)
        const [recordingProcessing, setRecordingProcessing] = useState<boolean>(false)
        const [transcriptProcessing, setTranscriptProcessing] = useState<boolean>(false)
        const [notesProcessing, setNotesProcessing] = useState<boolean>(false)
        const [dischargeProcessing, setDischargeProcessing] = useState<boolean>(false)
        const [feedProcessing, setFeedProcessing] = useState<boolean>(false)
        const [preHistoryProcessingMessage, setPreHistoryProcessingMessage] = useState<string | undefined>(undefined)
        const [preHistoryFailureMessage, setPreHistoryFailureMessage] = useState<string | undefined>(undefined)
        const [preHistoryStreaming, setPreHistoryStreaming] = useState<boolean>(false)
        const [displayActiveNotes, setDisplayActiveNotes] = useState('');
        const [displayActiveDischarge, setDisplayActiveDischarge] = useState('');
        const [feedbackNotes, setFeedbackNotes] = useState<boolean>(false)
        const [feedbackDischarge, setFeedbackDischarge] = useState<boolean>(false)
        const [toggleSections, setToggleSections] = useState<boolean>(false)
        const [notesSections, setNotesSections] = useState<Record<string, string>>()
        const [notification, setNotification] = useState<boolean>(false)
        const [notificationTitle, setNotificationTitle] = useState<string>()
        const [notificationText, setNotificationText] = useState<string>()
        const [notesStreaming, setNotesStreaming] = useState<boolean>(false)
        const [dischargeStreaming, setDischargeStreaming] = useState<boolean>(false)
        const [notesStreamCompleted, setNotesStreamCompleted] = useState<boolean>(false)
        const [sessionTemplateName, setSessionTemplateName] = useState<string>()
        const [membersList, setMembersList] = useState<MemberObject[]>([])
    
        //Scroll regs
        const { search } = useLocation();
        const listContainerRef = useRef<any>(null);
        const scrollToItemRef = useRef<any>(null);
        const [sessionFromParam, setSessionFromParam] = useState<string|undefined>(undefined)
    
        // Template Variables
        const [template, setTemplate] = useState<TemplateObject>()
        const [templateDischarge, setTemplateDischarge] = useState<TemplateObject>()
        const [language, setLanguage] = useState<string>('English')
        const [templateDefinition, setTemplateDefinition] = useState<SectionType[]>()
    
        // Modals
        const [deleteVisitModal, setDeleteVisitModal] = useState<boolean>(false)
        const [createVisitModal, setCreateVisitModal] = useState<boolean>(false)
        const [changeTemplate, setChangeTemplate] = useState<boolean>(false)
        const [changeDischargeTemplate, setChangeDischargeTemplate] = useState<boolean>(false)
        const [changeLanguage, setChangeLanguage] = useState<boolean>(false)
        const [uploadRecordingModal, setUploadRecordingModal] = useState<boolean>(false)
    
        const { removeNotesSubscriptions, removePreHistorySubscriptions, getCurrentStateOfNotes, getCurrentStateOfPreHistory, subscribeToNotesStream, subscribeToPreHistoryStream, getCurrentStateOfDischarge, subscribeToDischargeStream, removeDischargeSubscriptions} = useSupabase()
    
        function getLoggingProperties() {
            return {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined",
            }
        }

        async function getOrganizationMember(){
            if(orgId){
                let members = await organization?.getMemberships({limit:500})
                let memberList: MemberObject[] | undefined = members?.map((member) => { 
                    let name 
                    if(member.publicUserData.firstName && member.publicUserData.lastName){
                        name = member.publicUserData.firstName + " " + member.publicUserData.lastName
                    }
                    else{
                        name = undefined
                    }
                    return {
                        name: name,
                        identifier: member.publicUserData.identifier
                    }
                })
                if(memberList) setMembersList(memberList)
            }
            else{
                let name
                if(user?.firstName && user?.lastName){
                    name = user?.firstName + " " + user?.lastName
                } else {
                    name = undefined
                }
                setMembersList([{
                    name: name,
                    identifier: user?.primaryEmailAddress?.emailAddress ?? ""
                }])
            }
        }
    
        useEffect(() => {
            activeSessionRef.current = activeSession;
        }, [activeSession]);
    
        useEffect(() => {
            getSessions().then(async (sessions_raw) => {
                if(sessions_raw){
                    const queryParams = new URLSearchParams(search);
                    const sessionId = queryParams.get('session_id');
                    if(sessionId){
                        setSessionFromParam(sessionId ?? undefined)
                        let session = sessions_raw.find(x => x.id === sessionId)
                        if(session)
                        {
                            selectSession(session)
                        } 
                        else{
                            // If no session is found, get it and add it to the list
                            await fetchSessionById(sessionId ?? "", await getToken({template:"supabase"}) ?? "").then((session) => {
                                if(session){
                                    setSessions([session , ...sessions_raw])
                                    setFilteredSessions(filterSessions([session , ...sessions_raw], userSearch))
                                    selectSession(session)
                                }
                            })
                        }
                    }
                }     
            })
            const queryParams = new URLSearchParams(search);
            const tab = queryParams.get('tab');
            setTab(tab ?? "overview") 
        }, [orgId])

        useEffect(() => {
            getOrganizationMember()
        }, [orgId])
    
        useEffect(() => {
            setTimeout(()=>{
                if (sessions && scrollToItemRef.current && listContainerRef.current && !isMobileDevice()) {
                    scrollToItemRef.current.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    });
                }
            }, 500)
        }, [sessionFromParam]);
    
        useEffect(() => {
            let interval: string | number | NodeJS.Timeout | undefined;
        
            if (activeSession && (transcriptProcessing || notesProcessing || dischargeProcessing || feedProcessing || preHistoryProcessingMessage)) {
              // Start the interval if any of the conditions is true
              interval = setInterval(() => {
                // Refresh your data here... Warning: this is an async call we do not await 
                refreshActiveSession()
              }, 3000); // Set the time as needed
            } else {
              // If neither condition is true, clear the interval
              if (interval) {
                clearInterval(interval);
              }
            }
        
            return () => {
              // Clear the interval when the component is unmounted or conditions change
              if (interval) {
                clearInterval(interval);
              }
            };
        }, [transcriptProcessing, notesProcessing, dischargeProcessing, feedProcessing, preHistoryProcessingMessage]);
    
        useEffect(() => {
            if(sessions){
                setFilteredSessions(filterSessions(sessions, userSearch))
            }
        }, [userSearch])
    
        const debouncedQuery = useDebounce(searchValue, 500); // 500ms delay
    
        useEffect(() => {
          const fetchResults = async () => {
            getSessions()
          };
      
          fetchResults();
        }, [debouncedQuery]);
    
        useEffect(() => {
            if(searchDate?.startDate && searchDate?.endDate){
                getSessions()
            }
        },[searchDate])
    
        const getSessions = async (loading:boolean = true) => {
            try{
                if(loading) setLoadingSessions(true)
                if(debouncedQuery && debouncedQuery.length > 0){
                    let temp_sessions : SessionObject[] = await fetchSessionsName(await getToken({template:"supabase"}) ?? '', debouncedQuery)
                    let nonCancelledSessions : SessionObject[] = filterCancelledSessions(temp_sessions)
                    setAvailableSessions(nonCancelledSessions.length !== 0)
                    setSessions(nonCancelledSessions)
                    setFilteredSessions(filterSessions(nonCancelledSessions, userSearch))
                    if(loading) setLoadingSessions(false)
                    return nonCancelledSessions
                }
                else {
                    let start_date = new Date()
                    let end_date = new Date()
                    if(searchDate?.startDate){
                        if(searchDate.startDate instanceof Date){
                            start_date = searchDate.startDate
                        }
                        else{
                            const [year, month, day] = searchDate?.startDate?.toString().split('-').map(Number);
                            start_date = new Date(year, month - 1, day); 
                        }
                    }
                    if(searchDate?.endDate){
                        if(searchDate.endDate instanceof Date){
                            end_date = searchDate.endDate
                        }
                        else{
                            const [year, month, day] = searchDate?.endDate?.toString().split('-').map(Number);
                            end_date = new Date(year, month - 1, day); 
                        }
                    }
                    start_date.setHours(0,0,0,0);
                    end_date.setHours(23,59,59,999); 
                    let start_date_in_float = Math.floor(start_date.getTime() / 1000)
                    let end_date_in_float = Math.floor(end_date.getTime() / 1000)
                    let temp_sessions : SessionObject[] = await fetchSessionsDate(await getToken({template:"supabase"}) ?? '', start_date_in_float.toString(), end_date_in_float.toString())
                    let nonCancelledSessions : SessionObject[] = filterCancelledSessions(temp_sessions)
                    setAvailableSessions(nonCancelledSessions.length !== 0)
                    setSessions(nonCancelledSessions)
                    setFilteredSessions(filterSessions(nonCancelledSessions, userSearch))
                    if(loading) setLoadingSessions(false)
                    return nonCancelledSessions
                }
            }
            catch{
                if(loading) setLoadingSessions(false)
                setError("Had an issue loading visits. Refresh the page.")
            }
        }

        async function refreshAuditLogs(sessionObject:SessionObject){
            let token = await getToken({template:"supabase"}) ?? ""
            let auditLogs:AuditLogs[] = (await GetAuditLogs(sessionObject.id, token))['logs']
            forEach(auditLogs, (log) => {
                log.actor = membersList.find(x => x.identifier === log.actor)?.name ?? log.actor
            })
            // Sort the logs by timestamp
            auditLogs.sort((a, b) => b.timestamp - a.timestamp)
            setActiveAuditLogs(auditLogs)
        }
    
        async function refreshNotes(sessionObject:SessionObject, refresh:boolean){
            if(sessionObject.status.notes === "Completed"){
                await removeNotesSubscriptions()
                let temp_notes = await GetNotes(sessionObject.id,await getToken({template:"supabase"}) ?? "")
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveNotes(temp_notes ?? "")
                setDisplayActiveNotes(preprocessText(temp_notes ?? ""))
                setNotesStreaming(false)
                setNotesProcessing(false)
            }
            else if(sessionObject.status.notes === "Streaming"){
                await removeNotesSubscriptions()
                let temp_notes = await getCurrentStateOfNotes(sessionObject.id)
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveNotes(temp_notes ?? "")
                setDisplayActiveNotes(preprocessText(temp_notes ?? ""))
                setNotesStreaming(true)
                setNotesProcessing(true)
                await subscribeToNotesStream(sessionObject.id, (value:string) => {
                    if (sessionObject.id !== activeSessionRef.current?.id) return;
                    setActiveNotes(value ?? "")
                    setDisplayActiveNotes(preprocessText(value ?? ""))
                }, () => {
                    if (sessionObject.id !== activeSessionRef.current?.id) return;
                    setNotesStreaming(false)
                })
            }
            else if(sessionObject.status.notes === "Processing"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveNotes(undefined)
                setNotesProcessing(true)
            }
            else if(sessionObject.status.notes == "Failed"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveNotes(undefined)
                setNotesProcessing(false)
                setNotesStreaming(false)
                if(refresh){
                    setError("We encountered an issue processing the notes. If error persists contact us at support@vetrec.io.")
                }
            }
            else{
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveNotes(undefined)
            }
            if(!refresh) setNotesLoading(undefined);
        }
    
        async function refreshTranscript(sessionObject:SessionObject, refresh:boolean){
            if(sessionObject.status.transcript === "Completed"){
                let transcript = await GetTranscript(sessionObject.id,await getToken({template:"supabase"}) ?? "")
                if (transcript.length === 0) transcript = "No sound detected."
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setTranscriptProcessing(false)
                setActiveTranscript(transcript ?? "")
                setActiveSessionUpdated(false)
            }
            else if(sessionObject.status.transcript === "Processing"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveTranscript(undefined)
                setTranscriptProcessing(true)
                setActiveSessionUpdated(false)
            }
            else if(sessionObject.status.transcript == "Failed"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveTranscript(undefined)
                setTranscriptProcessing(false)
                if(refresh){
                    setError("We encountered an issue processing the transcript. If error persists contact us at support@vetrec.io.")
                }
            }
            else if (sessionObject.status.transcript === "Updated"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveTranscript(undefined)
                setActiveSessionUpdated(true)
            }
            else{
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveTranscript(undefined)
            }
            if(!refresh) setTranscriptLoading(undefined);
        }
    
        async function refreshDischarge(sessionObject:SessionObject, refresh:boolean){
            if(sessionObject.status.discharge === "Completed"){
                await removeDischargeSubscriptions()
                let discharge_notes = await GetDischarge(sessionObject.id,await getToken({template:"supabase"}) ?? "")
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setDischargeProcessing(false)
                setDischargeStreaming(false)
                setActiveDischarge(discharge_notes ?? "")
                setDisplayActiveDischarge(preprocessDischargeText(discharge_notes ?? ""))
            }
            else if(sessionObject.status.discharge === "Processing"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setDischargeProcessing(true)
            }
            else if(sessionObject.status.discharge === "Streaming"){
                await removeDischargeSubscriptions()
                let temp_notes = await getCurrentStateOfDischarge(sessionObject.id)
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveDischarge(temp_notes ?? "Writing document...")
                setDisplayActiveDischarge(preprocessDischargeText(temp_notes ?? "Writing document..."))
                setDischargeStreaming(true)
                setDischargeProcessing(true)
                await subscribeToDischargeStream(sessionObject.id, (value:string) => {
                    if (sessionObject.id !== activeSessionRef.current?.id) return;
                    setActiveDischarge(value ?? "")
                    setDisplayActiveDischarge(preprocessDischargeText(value ?? ""))
                }, () => {
                    if (sessionObject.id !== activeSessionRef.current?.id) return;
                    setDischargeStreaming(false)
                })
            }
            else if(sessionObject.status.discharge == "Failed"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveDischarge(undefined)
                setDischargeProcessing(false)
                setDischargeStreaming(false)
                if(refresh){
                    setError("We encountered an issue generating discharge notes. Try again.")
                }
            }
            else{
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveDischarge(undefined)
            }
            if(!refresh) setDischargeLoading(undefined);
        }
    
        async function refreshFeed(sessionObject:SessionObject, refresh:boolean){
            if(sessionObject.status.consents == "Completed"){
                let temp_consents: Confirmation[]
                if(sessionObject.consents){
                    temp_consents = sessionObject.consents
                }
                else{
                    let json_consents = await GetFeed(sessionObject.id,await getToken({template:"supabase"}) ?? "")
                    const confirmations = JSON.parse(json_consents ?? "{}");
                    temp_consents = confirmations["confirmations"]
                }
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setFeedProcessing(false)
                setActiveConsents(temp_consents)
            }
            else if(sessionObject.status.consents === "Processing"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setFeedProcessing(true)
            }
            else if(sessionObject.status.consents == "Failed"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setFeedProcessing(false)
                if(refresh){
                    setError("We encoutered an issue generating the session feed. Try again.")
                }
            }
            else{
                setFeedProcessing(false)
            }
            if(!refresh) setFeedLoading(undefined);
        }
    
        async function refreshRecordings(sessionObject:SessionObject){
            if(sessionObject.status.recording === "Completed" || sessionObject.status.transcript === "Completed" || sessionObject.status.notes === "Completed"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveRecordingBackUp(true)
                setActiveRecordingExist(true)
                setRecordingCheckLoading(undefined)
            }
            else if(sessionObject.status.recording === "Processing" || sessionObject.status.recording == "Recording" || sessionObject.status.recording == "Updated"){
                setRecordingProcessing(true)
                if(sessionObject.status.recording == "Updated"){
                    setActiveSessionUpdated(true)
                }
                let token = await getToken({template:"supabase"}) ?? ""
    
                // Check recordings
                // If we already have a transcript or notes, means we have recordings, so don't check.
                let check_recordings
                if(sessionObject.status.transcript === "Completed" || sessionObject.status.notes === "Completed"){
                    check_recordings = {
                        recordings: true,
                        backup: true
                    }
                }
                else{
                    check_recordings = await CheckRecordings(sessionObject.id, token)
                }
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActiveRecordingBackUp(check_recordings.backup)
                setActiveRecordingExist(check_recordings.recordings)
                setRecordingCheckLoading(undefined)
            } else {
                setActiveRecordingBackUp(false)
                setActiveRecordingExist(false)
                setRecordingCheckLoading(undefined)
            }
        }

        useEffect(() => {
            if(activeRecordingExist && tab === "transcript" && !activeRecording){
                if(activeSessionRef.current){
                    loadRecordings(activeSessionRef.current)
                }
            }
        }, [activeRecordingExist, tab, activeRecording])
    
        async function loadRecordings(sessionObject:SessionObject){
            setRecordingLoading("Fetching Recordings 🎙️...")
            let token = await getToken({template:"supabase"}) ?? ""
            let recordingData = await GetRecordings(sessionObject.id, token)
            if (sessionObject.id === activeSessionRef.current?.id) {
                setActiveRecording(recordingData.urls)
            };
            setRecordingLoading(undefined)
        }

        function setPreHistoryFailure(showPopUpFailureMessage:boolean, popUpFailureMessage: string, staticFailureMessage: string) {
            setPreHistoryProcessingMessage(undefined)
            setPreHistoryFailureMessage(staticFailureMessage)
            if(showPopUpFailureMessage){
                setError(popUpFailureMessage)
            }
        }

        function parsePreHistoryJson(sessionObject:SessionObject, preHistory: string): PreHistoryV2Definition {
            if (sessionObject.prehistory_metadata?.version === "v2") {
                return JSON.parse(preHistory ?? "{}") as PreHistoryV2Definition;
            } else {
                const summaryV1: PreHistoryDefinition = JSON.parse(preHistory ?? "{}");
                return {
                    summary: summaryV1.summary,
                    referrers: summaryV1.referrer ? [summaryV1.referrer] : [],
                    physical_exams: Array.isArray(summaryV1.last_physical_exam) 
                                    ? summaryV1.last_physical_exam 
                                    : summaryV1.last_physical_exam ? [summaryV1.last_physical_exam] : [],
                    prior_issues: summaryV1.existing_issues || [],
                    prior_vaccines: summaryV1.existing_vaccines || [],
                    prior_medications: summaryV1.existing_medications || [],
                    prior_test_reports: summaryV1.previous_reports || [],
                    prior_treatments: summaryV1.previous_treatments || [],
                    additional_information: summaryV1.additional_information ? [summaryV1.additional_information] : []
                };
            }
        }
    
        async function refreshPreHistory(sessionObject:SessionObject, refresh:boolean){
            if(!refresh) setPreHistoryLoading("Fetching Records Recap 📄...")
            if(sessionObject.status.prehistory === "Completed"){
                let preHistory = await GetPreHistory(sessionObject.id, await getToken({template:"supabase"}) ?? "")
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                const summary: PreHistoryV2Definition = parsePreHistoryJson(sessionObject, preHistory)
                setPreHistoryProcessingMessage(undefined)
                setPreHistoryFailureMessage(undefined)
                setActivePreHistory(summary)
            }
            else if(sessionObject.status.prehistory === "Processing"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setPreHistoryProcessingMessage("Processing prior records")
                setPreHistoryFailureMessage(undefined)
            }
            else if(sessionObject.status.prehistory === "Streaming"){
                await removePreHistorySubscriptions()
                let value = await getCurrentStateOfPreHistory(sessionObject.id)
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                const summary: PreHistoryV2Definition = parsePreHistoryJson(sessionObject, value)
                setActivePreHistory(summary)
                setPreHistoryStreaming(true)
                setPreHistoryProcessingMessage("Writing summary. Feel free to navigate away if you need to.")
                setPreHistoryFailureMessage(undefined)
                await subscribeToPreHistoryStream(sessionObject.id, (value:string) => {
                    if (sessionObject.id !== activeSessionRef.current?.id) return;
                    const summary: PreHistoryV2Definition = parsePreHistoryJson(sessionObject, value)
                    setActivePreHistory(summary)
                }, () => {
                    if (sessionObject.id !== activeSessionRef.current?.id) return;
                    setPreHistoryProcessingMessage(undefined)
                    setPreHistoryStreaming(false)
                })
            }
            else if (sessionObject.status.prehistory === "Chunking" || sessionObject.status.prehistory === "Merging") {
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActivePreHistory(undefined)

                if (sessionObject.status.prehistory === "Chunking") {
                    setPreHistoryProcessingMessage("📖 Reading through all your records.")
                } else {
                    const num_merges_required = sessionObject.prehistory_metadata?.num_merges_required
                    const time_estimate = num_merges_required ? num_merges_required * 2 : undefined
                    setPreHistoryProcessingMessage(`🕐 Looks like you uploaded a lot of records!${time_estimate ? ` We should have your summary within ${time_estimate} minute${time_estimate > 1 ? "s" : ""}.` : " This may take a few minutes."}`)
                }
            }
            else if(sessionObject.status.prehistory === "Failed"){
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setPreHistoryFailure(refresh, "We encoutered an issue fetching your Records Recap. Please try again.", "There was an issue summarizing your records. Please try again.");
            }
            else if (sessionObject.status.prehistory === "FailedNoData") {
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setPreHistoryFailure(refresh, "We encoutered an issue fetching your Records Recap.", "Looks like there was no records to summarize! You may have provided scanned pages and images, which are not supported yet.");
            }
            else if (sessionObject.status.prehistory === "FailedTooMuchData") {
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setPreHistoryFailure(refresh, "We encoutered an issue fetching your Records Recap.", "Looks like you gave us too many records to summarize! Note: Support for PDFs more than 150 pages is still in beta.");
            }
            else{
                if (sessionObject.id !== activeSessionRef.current?.id) return;
                setActivePreHistory(undefined)
                setPreHistoryFailureMessage(undefined)
            }
            if(!refresh) setPreHistoryLoading(undefined);
        }

        const refreshSessionObject = async (session: SessionObject) => {
            const token = await getToken({ template: "supabase" }) ?? "";
            const temp_session: SessionObject | undefined = await fetchSessionById(session.id, token);
            
            if (temp_session) {
                setSessions(prevSessions => {
                    const updatedSessions = prevSessions.map(session => 
                        session.id === temp_session.id ? temp_session : session
                    );
                    return updatedSessions;
                });
                
                setFilteredSessions(prevSessions => {
                    const updatedSessions = prevSessions.map(session => 
                        session.id === temp_session.id ? temp_session : session
                    );
                    return filterSessions(updatedSessions, userSearch);
                });
            }
            
            return temp_session;
        };
    
        const refreshActiveSession = async () => {
            if (activeSessionRef.current) {
                const prevSession = activeSessionRef.current;
                let temp_session : SessionObject | undefined = await refreshSessionObject(prevSession)
                if (temp_session && temp_session.id === activeSessionRef.current?.id) {
                    const refreshTasks = [];
                    
                    refreshTasks.push(refreshAuditLogs(temp_session));
                    if(!sessionTemplateName){
                        refreshTasks.push(fetchTemplateForSession(temp_session))
                    }
                    if (temp_session.status.notes !== prevSession.status.notes) {
                        refreshTasks.push(refreshNotes(temp_session, true));
                    }
                    if (temp_session.status.prehistory !== prevSession.status.prehistory) {
                        refreshTasks.push(refreshPreHistory(temp_session, true));
                    }
                    if (temp_session.status.recording !== prevSession.status.recording) {
                        refreshTasks.push(refreshRecordings(temp_session));
                    }
                    if (temp_session.status.transcript !== prevSession.status.transcript) {
                        refreshTasks.push(refreshTranscript(temp_session, true));
                    }
                    if (temp_session.status.discharge !== prevSession.status.discharge) {
                        refreshTasks.push(refreshDischarge(temp_session, true));
                    }
                    if (temp_session.status.consents !== prevSession.status.consents) {
                        refreshTasks.push(refreshFeed(temp_session, true));
                    }
        
                    if (refreshTasks.length > 0) {
                        await Promise.all(refreshTasks);
                    }
        
                    // Update the active session only if it remains the same
                    if (temp_session.id === activeSessionRef.current?.id) {
                        activeSessionRef.current = temp_session;
                        setActiveSession(temp_session);
                    }
                }
            }
        };
    
        async function fetchTemplateForSession(sessionObject:SessionObject){
            // Template name fetching
            if (sessionObject.template_id && sessionObject.id === activeSessionRef.current?.id) {
                try {
                    const templateName = await getTemplateName(sessionObject.template_id.split("/")[1], await getToken({template: "supabase"}) ?? "");
                    setSessionTemplateName(templateName);
                } catch (error) {
                    setSessionTemplateName(undefined);
                }
            }
        }
    
        async function selectSession(sessionObject: SessionObject): Promise<void> {
            setSessionFromParam(sessionObject.id ?? undefined);
            setActiveSession(sessionObject);
            setActiveNotes(undefined);
            setActiveTranscript(undefined);
            setActiveRecording(undefined);
            setActiveDischarge(undefined);
            setActiveConsents(undefined);
            setActivePreHistory(undefined);
            setActiveRecordingBackUp(false);
            setActiveRecordingExist(false)
            setDisplayActiveDischarge('')
            setDisplayActiveNotes('')
        
            setRecordingProcessing(false);
            setNotesProcessing(false);
            setTranscriptProcessing(false);
            setPreHistoryProcessingMessage(undefined)
            setPreHistoryStreaming(false);
            setFeedProcessing(false);
            setFeedbackNotes(false);
            setFeedbackDischarge(false);
            setNotesStreaming(false);
            setSessionTemplateName(undefined);
            setTemplateDefinition(undefined);
            setPreHistoryFailureMessage(undefined);
            setNotesSections(undefined);
            
            setLoadingSessionInfo("Fetching Session 📅...");
            setNotesLoading("Fetching Notes 🤖...");
            setTranscriptLoading("Fetching Transcript 📄...");
            setDischargeLoading("Fetching Communications 📄...")
            setFeedLoading("Fetching Feed 📄...")
            setRecordingCheckLoading("Checking Recording 💿...");
            navigate(`/history?session_id=${sessionObject.id}`);
    
            let temp_session : SessionObject | undefined = await refreshSessionObject(sessionObject)
            activeSessionRef.current = temp_session;
        
            if (sessionObject.id !== activeSessionRef.current?.id) return;
        
            // Prepare all refresh promises
            const refreshTasks = [
                fetchTemplateForSession(temp_session ?? sessionObject),
                refreshAuditLogs(temp_session ?? sessionObject),
                refreshNotes(temp_session ?? sessionObject, false),
                refreshPreHistory(temp_session ?? sessionObject, false),
                refreshTranscript(temp_session ?? sessionObject, false),
                refreshDischarge(temp_session ?? sessionObject, false),
                refreshFeed(temp_session ?? sessionObject, false),
                refreshRecordings(temp_session ?? sessionObject)
            ];
        
            // Execute all refresh operations in parallel
             await Promise.all(refreshTasks.map(task => task.catch(e => { console.error('Refresh task failed:', e); })));
        
            // Log the view event
            let properties = {
                date: (new Date()).toUTCString(),
                sessionId: sessionObject.id
            };
            va.track("HistoryPage_ViewSession_Click", properties);
        
            // Clear loading states
            setLoadingSessionInfo(undefined);
        }

        async function generateDischarge(regenerate:boolean = false): Promise<void> {
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined",
                regenerate:regenerate
            }
            va.track("HistoryPage_GenerateDischarge_Click", properties)
    
            if (activeSession)
            {
                if (activeDischarge){
                    try{
                        setDischargeProcessing(true)
                        setActiveDischarge(undefined)
                        setActiveSession(currentSession => {
                            // Check if currentSession is not undefined before updating
                            if (currentSession) {
                                return {
                                ...currentSession,
                                status: {
                                    ...currentSession.status,
                                    discharge: "Processing",
                                },
                                };
                            }
                            return currentSession; // or return a new SessionObject if needed
                        });
                    }
                    catch(error){
                        setDischargeProcessing(false)
                        setActiveDischarge(undefined)
                        setError("There was a problem re-generating your communication notes. If error persists contact us at support@vetrec.io.")
                        return
                    }
                }
    
                setDischargeProcessing(true)
                let templateId = `${templateDischarge?.organization}/${templateDischarge?.id}`
                try{
                    setTab("discharge")
                    await generateDischargeNotes(activeSession?.id, activeSession?.name, templateId,  await getToken({template:"supabase"}) ?? "")
                    await getSessions(false)
                    // TODO show template name under discharge
                }
                catch(error) {
                    setDischargeProcessing(false)
                    setError("There was a problem generating communication notes. If error persists contact us at support@vetrec.io.")
                    return
                }
            }
            else{
                setError("Small hiccup, please try again later.")
            }
        }
    
        async function processTranscript(regenerate:boolean = false): Promise<void> {
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined",
                regenerate:regenerate
            }
            va.track("HistoryPage_ProcessTranscript_Click", properties)
    
            if (activeSession)
            {
                if (activeNotes){
                    setActiveNotes(undefined)
                }
                
                setActiveSession(currentSession => {
                    // Check if currentSession is not undefined before updating
                    if (currentSession) {
                        return {
                        ...currentSession,
                        status: {
                            ...currentSession.status,
                            notes: "Processing",
                            summary: "Processing",
                        },
                        };
                    }
                    return currentSession; // or return a new SessionObject if needed
                });
                setNotesProcessing(true)
                let templateId = `${template?.organization}/${template?.id}`
                try{
                    setTab("notes")
                    await generateSoapNotes(activeSession?.id, activeSession?.name, templateId, activeTranscript !== undefined , false, await getToken({template:"supabase"}) ?? "")
                    await getSessions(false)
                    if(templateId){
                        setSessionTemplateName(await getTemplateName(templateId.split("/")[1],  await getToken({template:"supabase"}) ?? ""))
                    }
                }
                catch(error) {
                    setNotesProcessing(false)
                    setError("There was a problem generating the notes. If error persists contact us at support@vetrec.io.")
                    return
                }
            }
            else{
                setError("Small hiccup, please try again later.")
            }
        }
        
        async function processRecordings(reprocess:boolean = false): Promise<void> {
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined"
            }
            va.track("HistoryPage_ProcessRecording_Click", properties)
    
            if(activeSession){
                setTranscriptProcessing(true)
                setActiveNotes(undefined)
                setActiveTranscript(undefined)
                try{
                    let templateId = `${template?.organization}/${template?.id}`
                    setTab("notes")
                    if(reprocess){
                        await reprocessRecording(activeSession?.id, activeSession?.name, await getToken({template:"supabase"}) ?? "",  activeNotes !== undefined, templateId, true)
                    }
                    else{
                        await processRecording(activeSession?.id, activeSession?.name, await getToken({template:"supabase"}) ?? "",  activeNotes !== undefined, templateId, true)
                    }
                    await getSessions(false)
                }
                catch(error) {
                    setTranscriptProcessing(false)
                    setError("There was a problem generating the transcript. If error persists contact us at support@vetrec.io.")
                    return
                }
            }
            else{
                setError("Small hiccup, please try again later.")
            }
        }
    
        async function handleSaveName(): Promise<void> {
            if(activeSession)
            {
                await saveName(activeSession?.id, activeSession?.name, await getToken({template:"supabase"}) ?? "")
                setNotification(true)
                setNotificationTitle("Visit name has been updated")
                await getSessions(false)
            }
        }
    
        async function handleThumbsInput(input: boolean, location: string): Promise<void> {
            // if(location == "HistoryDischarge") setFeedbackDischarge(true)
            // else setFeedbackNotes(true)
            let properties =  {
                date:(new Date()).toUTCString(),
                session:activeSession?.id ?? "No Session",
                feedback: input
            }
            va.track(`${location}_ThumbsInput`, properties)
    
            if(!input){
                setFeedbackModal(true)
                setFeedbackModalSource(location)
            }
            else{
                setNotification(true)
                setNotificationTitle("Thank you for the feedback")
                setNotificationText("Feedback was shared with team!")
                let session = activeSession?.id ?? "No Session"
                try{
                    await handleThumbsFeedbackServerSide(session, input, "", location, await getToken({template:"supabase"}) ?? "")
                }
                catch(error){
                }
            }
        }
    
        function handleTabChange(value: string): void {
            setTab(value)
            let properties =  {
                date:(new Date()).toUTCString(),
                session:activeSession?.id ?? "No Session",
                tabClicked:value
            }
            va.track(`History_TabChange`, properties)
        }
    
        function handleHistorySearch(value:string): void {
            setSearchValue(value)
        }
    
        async function translateDischarge(): Promise<void> {
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined",
            }
            va.track("HistoryPage_TranslateDischarge", properties)
    
            if (activeSession)
            {
                if (activeDischarge){
                    try{
                        setActiveSession(currentSession => {
                            // Check if currentSession is not undefined before updating
                            if (currentSession) {
                                return {
                                ...currentSession,
                                status: {
                                    ...currentSession.status,
                                    discharge: "Processing",
                                },
                                };
                            }
                            return currentSession; // or return a new SessionObject if needed
                        });
                        setDischargeProcessing(true)
                        setActiveDischarge(undefined)
                    }
                    catch(error){
                        setDischargeProcessing(false)
                        setError("There was a problem translating your discharge notes. If error persists contact us at support@vetrec.io.")
                        return
                    }
                }
    
                setDischargeProcessing(true)
                try{
                    setTab("discharge")
                    await translateDischargeNotes(language, activeSession.id, await getToken({template:"supabase"}) ?? "")
                    await getSessions(false)
                }
                catch(error) {
                    setDischargeProcessing(false)
                    setError("There was a problem translating the discharge notes. If error persists contact us at support@vetrec.io.")
                    return
                }
            }
            else{
                setError("Small hiccup, please try again later.")
            }
        }
    
        function handleAddToSession(): void {
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: activeSession?.id ?? "undefined",
            }
            va.track("HistoryPage_AddToSession", properties)
            navigate("/scribe?session=" + activeSession?.id)
        }
    
        function handleNotesTemplateChange(change:boolean): void{
            setChangeTemplate(change)
        }

        function handleDischargeTemplateChange(change:boolean): void{
            setChangeDischargeTemplate(change)
        }
    
        function handleLanguageChange(change:boolean): void{
            setChangeLanguage(change)
        }
    
        async function handleMarkComplete(): Promise<void> {
            let completed = activeSession?.status.general == "Completed" ? false : true
            await markSessionCompleted(activeSession?.id ?? "", completed, await getToken({template:"supabase"}) ?? '')
            setActiveSession(currentSession => {
                return currentSession ? {
                  ...currentSession,
                  status: {
                    ...currentSession.status,
                    general: activeSession?.status.general == "Completed" ? "Not Started" : "Completed"
                  }
                } : undefined;
            });
            setNotification(true)
            setNotificationText("")
            setNotificationTitle(activeSession?.status.general == "Completed" ? "Session has been marked as incomplete" : "Session has been marked as completed")
            await getSessions(false)
        }
    
        async function handleCreatePatient(value: string, owner?: string, timestamp?:number): Promise<void> {
            let properties =  {
                date:(new Date()).toUTCString(),
                session:activeSession?.id ?? "No Session",
            }
            va.track("HistoryPage_CreatePatient", properties)
            await createSession(uuidv4(), value, await getToken({template:"supabase"}) ?? "", false, true, true, owner, timestamp)
            await getSessions(true)
        }
    
        async function handleSaveOwner(): Promise<void> {
            if(activeSession){
                await saveOwner(activeSession?.id, activeSession?.display_owner ?? activeSession.owner, await getToken({template:"supabase"}) ?? "")
                setNotification(true)
                setNotificationTitle("Visit owner has been updated")
                await getSessions(false)
            }
        }

        function handleToggleSections(){
            setToggleSections(!toggleSections)
        }

        async function GetTemplateDefinition(templateId:string){
            let temp_definition = templateDefinition
            if(!temp_definition){
                let template = await GetTemplate(`${templateId}`, await getToken({template:"supabase"}) ?? '')
                temp_definition = JSON.parse(template ?? "") as SectionType[]
                setTemplateDefinition(temp_definition)
            }
            return temp_definition
        }

        async function handleDeleteVisit(){
            await deleteSession(activeSession?.id ?? "", await getToken({template:"supabase"}) ?? "")
            setActiveSession(undefined)
            await getSessions(true)
            setDeleteVisitModal(false)
        }

        useEffect(() => {
            const fetchData = async () => {
                if(toggleSections && activeSession){
                    let template_id = activeSession.template_id?.split("/")[1]
                    if(template_id){
                        let split_notes = splitNoteIntoSectionWithDefinition(revertPreprocessedText(displayActiveNotes) ?? "", await GetTemplateDefinition(template_id ?? ""));
                        setNotesSections(split_notes)
                    }
                }
            };
    
            fetchData();
        },[toggleSections, activeSession, displayActiveNotes])

    return (
        <HistoryContext.Provider value={{ activeSession, activeNotes, activeDischarge, activeConsents, activeTranscript, notesProcessing, dischargeProcessing, transcriptProcessing, feedProcessing, notesStreaming, notesStreamCompleted, displayActiveDischarge, displayActiveNotes, membersList, userSearch, dischargeLoading, preHistoryLoading, loadingSessions, feedLoading,notesLoading, transcriptLoading, searchDate, tab, changeLanguage, changeTemplate, changeDischargeTemplate, filteredSessions, availableSession, sessions, sessionTemplateName, error, warning, notification, notificationTitle, notificationText, preHistoryProcessingMessage, preHistoryFailureMessage, preHistoryStreaming, activePreHistory, recordingLoading, recordingProcessing, activeRecording, activeRecordingBackUp, template, feedbackNotes, feedbackDischarge, feedbackModal, feedbackModalSource, listContainerRef, scrollToItemRef, language, sessionFromParam, activeRecordingExist, recordingCheckLoading, debouncedQuery, loadingSessionInfo, templateDischarge, dischargeStreaming, activeSessionUpdated, toggleSections, activeAuditLogs, notesSections, createVisitModal, deleteVisitModal, uploadRecordingModal, setTemplateDischarge,handleTabChange, handleAddToSession, handleCreatePatient, handleLanguageChange, handleMarkComplete, handleThumbsInput, handleSaveOwner, processTranscript, processRecordings, handleNotesTemplateChange, handleDischargeTemplateChange, handleHistorySearch, translateDischarge, handleSaveName, setSearchDate, setTemplate, setLanguage, setDisplayActiveDischarge, setDisplayActiveNotes, setError, setWarning, setNotification, setNotificationText, setNotificationTitle, refreshActiveSession, setUserSearch, setActiveSession, selectSession, generateDischarge, setFeedbackModal, getLoggingProperties, handleToggleSections, refreshAuditLogs, setCreateVisitModal, setDeleteVisitModal, handleDeleteVisit, setUploadRecordingModal, refreshSessionObject}}>
        {children}
        </HistoryContext.Provider>
    );
}