import { BoltIcon, BookOpenIcon, CalendarIcon, DocumentTextIcon, LanguageIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../utils/ClassUtils"
import { useAnalytics } from "../../providers/AnalyticsProvider";

interface ScopeTabsProps {

}

export const ScopeTabs: React.FC<ScopeTabsProps> = () => {
    const {scope, handleScope} = useAnalytics()
    
    return(<div className="sm:w-auto w-full">
        <div className="xl:hidden relative">
            <label
            htmlFor="tabs"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-main-text-darker"
            >
                Scope
            </label>
            <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-main-lighter focus:ring-main-lighter"
            value={scope}
            onChange={(value) => handleScope(value.target.value)}
            >
                <option key={"user"} value={"user"}>User</option>
                <option key={"team"} value={"team"}>Team</option>
            </select>
        </div>
        <div className="hidden xl:block">
            <div className="flex flex-row items-center gap-x-4">
                <span className="font-semibold">Scope:</span>
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex flex-row space-x-6 w-full" aria-label="Tabs">
                        <div
                            key={"user"}
                            onClick={() => handleScope("user")}
                            className={classNames(
                            scope == "user"
                                ? 'border-main-lighter text-main'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                            )}
                            aria-current={scope == "user" ? 'page' : undefined}
                        >
                            <span>{"User"}</span>
                        </div>
                        <div
                            key={"team"}
                            onClick={() => handleScope("team")}
                            className={classNames(
                            scope == "team"
                                ? 'border-main-lighter text-main'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                            )}
                            aria-current={scope == "team" ? 'page' : undefined}
                        >
                            <span>{"Team"}</span>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>)
}