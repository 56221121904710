import { DateType } from "react-tailwindcss-datepicker";

export const DateTypeToFloat = ( dateTime:DateType ) => {
    let date = new Date()
    if(dateTime){
        if(dateTime instanceof Date){
            date = dateTime
        }
        else{
            const [year, month, day] = dateTime.toString().split('-').map(Number);
            date = new Date(year, month - 1, day); 
        }
    }
    date.setHours(0, 0, 0, 0); 
    let date_in_float = Math.floor(date.getTime() / 1000)
    return date_in_float
}