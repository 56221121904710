import { Confirmation } from "./ConsentsUtils"
import { MemberObject } from "./MemberUtils"

export interface SessionStatus {
    recording:string,
    transcript:string,
    notes:string,
    comms:string,
    manual_notes:string
    discharge?:string
    consents?:string
    general?:string
    prehistory?: string
}

export interface PreHistoryMetadata {
    version?: string,
    num_merges_required?: number,
}

export interface SessionObject {
    id:string,
    summary?:string,
    consents?: Confirmation[],
    created_timestamp:number,
    modified_timestamp:number,
    name:string,
    organization?:string,
    owner:string,
    status:SessionStatus,
    prehistory_metadata?: PreHistoryMetadata,
    language?:string,
    template_id?:string,
    display_owner?:string
}

export interface AuditLogs {
    item_id:string,
    item_type:string,
    action: string,
    actor:string,
    timestamp:number,
    metadata: {}
}

export function displaySessionsInOrder(sessions: SessionObject[]) {
    const nonCancelledSession = sessions.filter((session) => session.status.recording != "Cancelled")
    const sortedSessions = nonCancelledSession.sort((a, b) => b.created_timestamp - a.created_timestamp);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today.getTime() - 86400000); // 24 hours in milliseconds
    
    // Calculate the start of this week (assuming the week starts on Sunday)
    const thisWeekStart = new Date(today);
    thisWeekStart.setDate(today.getDate() - today.getDay());
    
    // Calculate the start of last week
    const lastWeekStart = new Date(thisWeekStart);
    lastWeekStart.setDate(thisWeekStart.getDate() - 7);

    // Calculate the start of this month
    const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);

    let buckets: SessionObject[]  = []

    sortedSessions.forEach(session => {
        const sessionDate = new Date(session.created_timestamp * 1000); // Convert Unix timestamp to JavaScript Date object
        if (sessionDate >= today) {
            buckets.push(session);
        } else if (sessionDate >= yesterday && sessionDate < today) {
            buckets.push(session);
        } else if (sessionDate >= thisWeekStart && sessionDate < today) {
            buckets.push(session);
        } else if (sessionDate >= lastWeekStart && sessionDate < thisWeekStart) {
            buckets.push(session);
        } else if (sessionDate >= thisMonthStart) {
            buckets.push(session);
        } else {
            buckets.push(session);
        }
    });

    return buckets;
}

export function filterCancelledSessions(sessions:SessionObject[]){
    const nonCancelledSession = sessions.filter((session) => session.status.general != "Cancelled")
    return nonCancelledSession
}

export function filterSessions(sessions: SessionObject[], userSearch: MemberObject | undefined) {
    return sessions.filter(session => {
        // Check if userSearch is provided and not equal to "All doctors"
        const isUserSearchValid = userSearch && userSearch.identifier.toLowerCase() !== "all doctors";
        const displayOwnerMatch = isUserSearchValid && session.display_owner ? (session.display_owner.toLowerCase().includes(userSearch.identifier.toLowerCase())) : false;
        const ownerMatch = isUserSearchValid && !session.display_owner ? session.owner.toLowerCase().includes(userSearch.identifier.toLowerCase()) : false;
        const matchesUserSearch = isUserSearchValid ? (displayOwnerMatch || ownerMatch): true;
        return matchesUserSearch;
    });
}

export function displaySessionsInOrderInBuckets(sessions: SessionObject[]) {
    const sortedSessions = sessions.sort((a, b) => b.created_timestamp - a.created_timestamp);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today.getTime() - 86400000); // 24 hours in milliseconds
    
    // Calculate the start of this week (assuming the week starts on Sunday)
    const thisWeekStart = new Date(today);
    thisWeekStart.setDate(today.getDate() - today.getDay());
    
    // Calculate the start of last week
    const lastWeekStart = new Date(thisWeekStart);
    lastWeekStart.setDate(thisWeekStart.getDate() - 7);

    // Calculate the start of this month
    const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);

    let buckets: { [key: string]: SessionObject[] } = {
        today: [],
        yesterday: [],
        thisWeek: [],
        lastWeek: [],
        thisMonth: [],
        catchAll: []
    };

    sortedSessions.forEach(session => {
        const sessionDate = new Date(session.created_timestamp * 1000); // Convert Unix timestamp to JavaScript Date object
        if (sessionDate >= today) {
            buckets.today.push(session);
        } else if (sessionDate >= yesterday && sessionDate < today) {
            buckets.yesterday.push(session);
        } else if (sessionDate >= thisWeekStart && sessionDate < today) {
            buckets.thisWeek.push(session);
        } else if (sessionDate >= lastWeekStart && sessionDate < thisWeekStart) {
            buckets.lastWeek.push(session);
        } else if (sessionDate >= thisMonthStart) {
            buckets.thisMonth.push(session);
        } else {
            buckets.catchAll.push(session);
        }
    });

    return buckets;
}
