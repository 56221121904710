import Datepicker from "react-tailwindcss-datepicker";
import { TimeFrameTabs } from "./components/analytics/timeframeTabs";
import { useAnalytics } from "./providers/AnalyticsProvider";
import { ScopeTabs } from "./components/analytics/scopeTabs";
import { Chart } from 'react-chartjs-2';
import UsersTable from "./components/analytics/usersTable";
import { Spinner } from "./utils/Spinner";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

export default function Analytics(){
    const {searchDate, setSearchDate, organizationAdmin, graphData, loadingAnalytics, totalUsers, totalVisits, scope, totalMembers} = useAnalytics()

    return (
    <>
        <div className="flex flex-col h-screen w-full">
            <div className="flex flex-col sm:flex-row w-full items-center gap-x-6 gap-y-4 justify-between pb-4 border-b border-gray-300 mt-4">
                {organizationAdmin && <ScopeTabs/>}
                <div className="z-[99] gap-x-2 flex flex-row items-center">
                    <div className="whitespace-nowrap font-semibold">
                        Date Range:
                    </div>
                    <Datepicker 
                        inputClassName={`pl-2 h-10 rounded-lg text-sm border-gray-300 border-1 placeholder:text-gray-400 text-center w-72`}
                        toggleClassName={"hidden"}
                        useRange={true} 
                        value={searchDate} 
                        separator=" - "
                        onChange={async (value) => {
                            setSearchDate(value)
                        }} 
                        popoverDirection="down"
                        maxDate={new Date()}
                        displayFormat={"MM/DD/YYYY"}  
                        placeholder="All Dates"
                    /> 
                </div>
                <TimeFrameTabs/>
            </div>
            <div className="py-4 px-4 bg-blue-200 rounded-md mt-4">
                Analytics are in <span className="font-semibold">Beta</span>. The data is refreshed every 24 hours. For any questions or feedback, please reach out to <a href="mailto:support@vetrec.io" className="underline">support@vetrec.io</a>.
            </div>
            <div className="max-h-[65vh] sm:max-h-[80vh] overflow-y-auto thin-scrollbar w-full">
                <div className="text-lg font-semibold pt-4 w-full text-start pb-4">Usage Overview</div>
                    <div className="flex flex-row gap-x-2">
                        <div className="flex flex-col gap-y-2 bg-gray-50 border-blue-300 border rounded-md p-4 justify-center items-center w-48 shadow" title="Total visits from users in the time period.">
                            <div className="text-sm font-semibold">Total Visits</div>
                            <div className="text-2xl font-semibold">{totalVisits}</div>
                        </div>
                        {scope == "team" && <div className="flex flex-col gap-y-2 bg-gray-50 border-blue-300 border rounded-md p-4 justify-center items-center w-48 shadow" title="Total unique users in the time period out of the total membership.">
                            <div className="text-sm font-semibold">Users</div>
                            <div className="text-2xl font-semibold">{totalUsers} <span className="text-sm">/ {totalMembers}</span></div>
                        </div>}
                        <div className="flex flex-col gap-y-2 bg-gray-50 border-blue-300 border rounded-md p-4 justify-center items-center w-48 shadow" title="Estimated time saved assuming 10 mins saved / visit.">
                            <div className="text-sm font-semibold flex flex-row gap-x-2 items-center">
                                <span>Time Saved</span>
                                <QuestionMarkCircleIcon className="h-4 w-4 text-gray-500"/>
                            </div>
                            <div className="text-2xl font-semibold">{Math.round((totalVisits * 10 / 60) * 10) / 10} hours</div>
                    </div>
                </div>
                <div className="mt-6 flex flex-col items-center justify-center gap-y-4">
                    <div className="text-lg font-semibold pt-4 w-full text-start pb-4">Usage Timeline</div>
                    {loadingAnalytics && <div className="flex flex-col gap-y-8 w-full h-full justify-center items-center">
                        <Spinner size="h-36" timer={false}/>
                        <div>Loading Analytics...</div>
                    </div>}
                    <div className="h-[48vh] w-full">
                        {!loadingAnalytics && graphData && <Chart type="bar" data={graphData} options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales:{
                                x: {
                                    stacked: true,
                                    display: true,
                                    title:{
                                        display: true,
                                        text: 'Date'
                                    }
                                },
                                y: {
                                    stacked: true,
                                    title:{
                                        display: true,
                                        text: 'Visits'
                                    }
                                }
                            }
                        }}/>}
                    </div>
                </div>
                <UsersTable />
            </div>
        </div>
    </>
    )
}