import { createRef, Fragment, useEffect, useMemo, useRef, useState } from "react"
import { TemplateObject } from "../../utils/TemplateObject"
import { fetchOrgTemplates, fetchTemplates } from "../../ServerActions"
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { Combobox, Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { preBuiltDischargeTemplate, preBuiltTemplate, preBuiltTemplateSpanish } from "../../utils/prebuiltTemplates";
import va from '@vercel/analytics';
import { TemplateType } from "./TemplateUtils";
import { executeWithRetries } from "../../utils/RetryUtils";
import { getTemplateFromListById } from "../../utils/TemplateUtils";
import { Spinner } from "../../utils/Spinner";
import { StarIcon } from "@heroicons/react/24/solid";

interface TemplatePickerProps{
    template:TemplateObject | undefined
    templateId_force?: string | undefined
    setTemplate: ((value: TemplateObject) => void) | undefined
    className:string
    templateBackground:string
    pickerWidth?:string
    type?:TemplateType
    pickerDirection?: string
}

export default function TemplatePicker(props: TemplatePickerProps) {
    const { template, templateId_force, setTemplate, className, templateBackground, pickerWidth, type, pickerDirection } = props
    const { getToken} = useAuth();
    const { organization } = useOrganization()
    const {orgId} = useAuth()
    
    // Template Variables
    const [loadingTemplates , setLoadingTemplates] = useState<boolean>(false)
    const [templates, setTemplates] = useState<TemplateObject[]>()
    const [query, setQuery] = useState('');
    const { user } = useUser()
    const defaultTemplate = useMemo<string>(() => {
        const userTemplate = user?.publicMetadata[type === TemplateType.COMMUNICATIONS ? 'default_discharge_template_id' : 'default_template_id'] as string;
        const orgTemplate = organization?.publicMetadata[type === TemplateType.COMMUNICATIONS ? 'default_discharge_template_id' : 'default_template_id'] as string;
    
        return userTemplate ?? orgTemplate ?? (type === TemplateType.COMMUNICATIONS ? 'dynamic_simple_discharge' : 'dynamic_soap_advanced');
    }, [organization]);
    

    const defaultTemplateRaw = useMemo<string>(() => {
        const userTemplate = user?.publicMetadata[type === TemplateType.COMMUNICATIONS ? 'default_discharge_template_id' : 'default_template_id'] as string;
        const orgTemplate = organization?.publicMetadata[type === TemplateType.COMMUNICATIONS ? 'default_discharge_template_id' : 'default_template_id'] as string;
    
        return userTemplate ?? orgTemplate;
    }, [organization]);
    
    
    const language = useMemo<string>(() => (user?.publicMetadata['language'] as string) ?? 'English', []);
    const defaultTemplates = type == TemplateType.COMMUNICATIONS ? preBuiltDischargeTemplate : language === 'Spanish' ? preBuiltTemplateSpanish : preBuiltTemplate;
    
    let width = pickerWidth ? pickerWidth : "xl:w-60 lg:w-40 md:w-40 sm:w-40 w-full"
    const direction = pickerDirection ? pickerDirection : "below"
    
    const getTemplates = async () => {
        try{
            setLoadingTemplates(true)
           
            await executeWithRetries(async () => {
                let temp_templates : TemplateObject[] = await fetchTemplates(await getToken({template:"supabase"}) ?? '', type)
                let org_templates : TemplateObject[] = orgId ? await fetchOrgTemplates(await getToken({template:"supabase"}) ?? '', type) : []
                if (org_templates.length > 0){
                    temp_templates = temp_templates.filter(template => !org_templates.some(template2 => template2.id === template.id))
                }
    
                let list_templates = [...temp_templates, ...org_templates, ...defaultTemplates]

                //sort the templates by name
                list_templates.sort((a, b) => a.name.localeCompare(b.name))
    
                const templateToSet = getTemplateFromListById(list_templates, templateId_force) ?? list_templates.find(t => t.id == defaultTemplate) ?? list_templates[0]
                if (setTemplate) setTemplate(templateToSet)
                
                //move the selected template to the top of the list
                const index = list_templates.findIndex(t => t.id === templateToSet.id)
                list_templates.splice(index, 1)
                list_templates = [templateToSet, ...list_templates]
                
                setTemplates(list_templates)
            })
            
            setLoadingTemplates(false)
        }catch{
            console.error("Failed to fetch templates.")
        }
    }

    useEffect(() => {
        getTemplates()
    }, [templateId_force, orgId])

    useEffect(() => {
        if (template && templates) {
            const filteredTemplates = templates.filter(t => t !== template);
            filteredTemplates.sort((a, b) => a.name.localeCompare(b.name));
            setTemplates([template, ...filteredTemplates])            
        }
    }, [template])

    const handleTemplateChange =  (template: TemplateObject) => {
        if (setTemplate) setTemplate(template)
        let properties =  {
            date:(new Date()).toUTCString(),
            template:template.id
        }
        setQuery("")
        va.track("Template Changed", properties)
    }

    const filteredTemplates = query === ''
    ? templates
    : templates?.filter((temp) =>
        temp.name?.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')) ?? temp.id.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
      );
    
    const buttonRef = useRef<HTMLButtonElement>(null);

    return(<>
        <div className={`shrink-0 ${width} relative`}>
            <label
                htmlFor="name"
                className={`absolute -top-2 left-2 inline-block px-1 text-xs font-medium text-main-text-darker z-[2] ${templateBackground}`}
            >
                Template
            </label>
            <Combobox value={template} onChange={(template) => handleTemplateChange(template)}>
                {({open}) => (
                    <div className="relative z-[1]">
                        <div className={`relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm border border-gray-300 ${className} flex flex-row items-center`}>
                            <Combobox.Input
                                className={`w-full border-none py-2 pl-3 mr-10 text-sm leading-5 text-gray-900 focus:outline-none focus:ring-0 h-full rounded-md border-blue-600`}
                                displayValue={(template: TemplateObject | undefined) => template ? template.name : ""}
                                onChange={(event) => setQuery(event.target.value)}
                                onClick={() => {
                                    if(!open){
                                        buttonRef.current?.click()
                                    }
                                }}
                            />
                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2" ref={buttonRef}>
                                {loadingTemplates && <Spinner size='h-5 w-5' timer={false}/>}
                                {!loadingTemplates && <div>
                                    <ChevronUpDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>}
                            </Combobox.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Combobox.Options className={`absolute ${direction == "below" ? "mt-1" : "-mt-52"} max-h-40 thin-scrollbar w-full min-w-60 right-0 overflow-auto rounded-md bg-white py-1 text-base shadow-sm ring-1 ring-gray-300 focus:outline-none sm:text-sm cursor-pointer`}>
                            {filteredTemplates?.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                    </div>
                            ) : (
                                filteredTemplates && filteredTemplates.map((template, templateIdx) => (
                                    <Combobox.Option
                                    key={templateIdx}
                                    className={({ active }) =>
                                        `relative select-none py-2 pl-4 flex flex-row gap-x-2 pr-4 cursor-pointer ${
                                        active ? 'bg-accent text-main-text-darker' : 'text-main-text-darker'
                                        }`
                                    }
                                    value={template}
                                    >
                                    {({ selected }) => (
                                        <>
                                        <span
                                            className={`block truncate ${
                                            selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                            {template.name}
                                        </span>
                                        {selected && <span className="flex items-center pl-3 text-gray-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>}
                                        {!selected && defaultTemplateRaw && template.id === defaultTemplateRaw && <span className="flex items-center pl-3 text-gray-600">
                                            <StarIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>}
                                        </>
                                    )}
                                </Combobox.Option>
                                ))
                            )}
                            </Combobox.Options>
                        </Transition>
                    </div>
                )}
            </Combobox>
        </div>
    </>)
}
