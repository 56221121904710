import { useAnalytics } from "../../providers/AnalyticsProvider"
import { getDatesBetween } from "../../utils/time"
import { DateTypeToFloat } from "../../utils/TimeUtils"



export default function UsersTable(){
    const { userData, timeframe, searchDate} = useAnalytics()
    let start = DateTypeToFloat(searchDate?.startDate ?? new Date())
    let end = DateTypeToFloat(searchDate?.endDate ?? new Date())
    let labels = getDatesBetween(start, end, timeframe)
    
    return(<>
        <div className="text-lg font-semibold mt-12 border-t border-gray-300 pt-4 pb-4">Usage Breakdown</div>
        {userData && <div className="overflow-x-auto">
            <table className="min-w-full border-collapse table-auto">
                <thead>
                    <tr>
                        <th className="border px-4 py-2 text-sm">User</th>
                        {labels.slice(-4).map((label, index) => (
                            <th className="border px-4 py-2 text-sm">{label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="text-center">
                    {Object.keys(userData).length > 0 && Object.keys(userData).map((user, index) => (
                        <tr key={index}>
                            <td className="border px-4 py-2 font-semibold">{user}</td>
                            {Object.keys(userData[user]).map((date, index) => (
                                <td className="border px-4 py-2">{userData[user][date]}</td>
                            ))}
                        </tr>
                    ))}
                    {Object.keys(userData).length == 0 && <tr>
                        <td colSpan={labels.length + 1} className="border px-4 py-8">No Data</td>
                    </tr>}
                </tbody>
            </table>
        </div>}
    </>)
}