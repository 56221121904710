import { useState, type FC, useRef, useEffect } from 'react'
import { useDrop } from 'react-dnd'
import { ItemTypes } from './ItemTypes'
import { SectionType, TemplateType, communicationTemplateTones, jsonToText } from './TemplateUtils'
import { CloudIcon, Cog6ToothIcon, DocumentPlusIcon, EyeIcon, LinkIcon, PaintBrushIcon, PencilIcon } from '@heroicons/react/24/solid'
import { Section } from './Section'
import { useTemplate } from './TemplateProvider'
import { MainButton } from '../MainButton'
import Remainder from '../remainder'
import { useNavigate } from 'react-router-dom'
import { Spinner } from '../../utils/Spinner'
import Error from '../Error'
import { physical_exam, simpleSection, vitals, simpleSubjective, simpleAssessment, simplePlan, fearfree } from './SectionDefinitions'
import { chronicIssuesEntry, currentDietEntry, currentMedicationEntry, currentPreventatitvesEntry, diagnosticTestsEntry, dietEntry, medicationEntry, owner_discussion, simpleEntry, treatmentsEntry, vaccinesEntry } from './EntryDefinitions'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { preprocessText } from '../../utils/TextUtils'
import MoreMenu from '../moreMenu'
import TemplateGenerateModal from './TemplateGenerateModal'
import TemplateConfigModal from './TemplateConfigModal'
import TemplateStylingModal from './TemplateStylingModal'
import { UserAction } from '../../utils/vercelAnaytics'
import { useUser } from '@clerk/clerk-react'
import EnumPicker from '../EnumPicker'
import { Loading } from '../../utils/Loading'
import { RadioGroup } from '@headlessui/react'
import DraggableSections from './DraggableSections'
import { set } from 'lodash'

export const MainTemplate: FC = () => {
  const {items, addSection, name, updateName, saveTemplate, validateTemplate, overrideTemplate, styling, mapping, handleEntryOver, handleSectionOver, sectionHoverIndex, addEntryToItem, handleSectionDragged, handleEntryDragged, templateType, updateTemplateType, templateLoading, templateTone, handleTemplateTone} = useTemplate()
  const itemsRef = useRef<SectionType[]>(items)
  const [remainder, setRemainder] = useState<boolean>(false)
  const [remainderText, setRemainderText] = useState<string>()
  const [remainderLineColor, setRemainderLineColor] = useState<string>()
  const [remainderIconColor, setRemainderIconColor] = useState<string>()
  const [saving, setSaving] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const  navigate = useNavigate()
  const [preview, setPreview] = useState<boolean>(false)
  const [previewNotes, setPreviewNotes] = useState<string>()
  const [templateGenerate, setTemplateGenerate] = useState<boolean>(false)
  const [templateConfig, setTemplateConfig] = useState<boolean>(false)
  const [templateStyling, setTemplateStyling] = useState<boolean>(false)
  const { user } = useUser()

  useEffect(() => {
    itemsRef.current = items
  }, [items])

  function handleSectionDrop(item: { name: string }){
    let item_name = item.name.toLowerCase().replace(" ", "_")
    if(item_name == "section"){
      addSection(simpleSection)
    }
    else if (item_name == "vitals"){
      addSection(vitals)
    }
    else if (item_name == "physical_exam"){
      addSection(physical_exam)
    }
    else if (item_name == "subjective"){
      addSection(simpleSubjective)
    }
    else if (item_name == "assessment"){
      addSection(simpleAssessment)
    }
    else if (item_name == "plan"){
      addSection(simplePlan)
    }
    else if (item_name == "fear_free"){
      addSection(fearfree)
    }
  }

  function handleEntryDrop(item: { name: string }){
    let item_name = item.name.toLowerCase().replace(" ", "_")

    if(item_name == "entry"){
      addEntryToItem(simpleEntry)
    }
    else if (item_name == "current_medication"){
      addEntryToItem(currentMedicationEntry)
    }
    else if (item_name == "current_diet"){
      addEntryToItem(currentDietEntry)
    }
    else if (item_name == "current_preventatives"){
      addEntryToItem(currentPreventatitvesEntry)
    }
    else if (item_name == "chronic_issues"){
      addEntryToItem(chronicIssuesEntry)
    }
    else if (item_name == "diagnostic_tests"){
      addEntryToItem(diagnosticTestsEntry)
    }
    else if (item_name == "treatments"){
      addEntryToItem(treatmentsEntry)
    }
    else if (item_name == "vaccines"){
      addEntryToItem(vaccinesEntry)
    }
    else if (item_name == "medication"){
      addEntryToItem(medicationEntry)
    }
    else if (item_name == "diet"){
      addEntryToItem(dietEntry)
    }
    else if (item_name == "owner_discussion"){
      addEntryToItem(owner_discussion)
    }
  }
  
  const [{isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.SECTION, ItemTypes.RENDERED_SECTION, ItemTypes.RENDERED_ENTRY, ItemTypes.ENTRY, ItemTypes.VITALS, ItemTypes.PHYSICAL_EXAM, ItemTypes.FEAR_FREE, ItemTypes.SUBJECTIVE, ItemTypes.ASSESSMENT, ItemTypes.PLAN, ItemTypes.OWNER_DISCUSSION, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.CHRONIC_ISSUES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.TREATMENTs, ItemTypes.VACCINES, ItemTypes.MEDICATION, ItemTypes.DIET],
    drop: (item:any, monitor) => {
      if([ItemTypes.SECTION, ItemTypes.VITALS, ItemTypes.PHYSICAL_EXAM, ItemTypes.FEAR_FREE, ItemTypes.SUBJECTIVE, ItemTypes.ASSESSMENT, ItemTypes.PLAN].includes(item['name'].toLowerCase().replace(" ", "_"))){
        handleSectionDrop(item)
      }
      else if (sectionHoverIndex !== "999999999" && [ItemTypes.ENTRY, ItemTypes.CHRONIC_ISSUES, ItemTypes.CURRENT_DIET, ItemTypes.CURRENT_MEDICATION, ItemTypes.CURRENT_PREVENTATIVES, ItemTypes.DIAGNOSTIC_TESTS, ItemTypes.DIET, ItemTypes.MEDICATION, ItemTypes.OWNER_DISCUSSION, ItemTypes.TREATMENTs, ItemTypes.VACCINES].includes(item['name'].toLowerCase().replace(" ", "_"))){
        handleEntryDrop(item)
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    hover(item, monitor) {
      if([ItemTypes.SECTION, ItemTypes.VITALS, ItemTypes.PHYSICAL_EXAM, ItemTypes.FEAR_FREE, ItemTypes.SUBJECTIVE, ItemTypes.ASSESSMENT, ItemTypes.PLAN].includes(item['name'].toLowerCase().replace(" ", "_"))){
        handleSectionDragged(true)
        handleSectionOver("999999999")
      }
      else{
        handleEntryDragged(true)
      }
    },
  }))

  const handleTemplateSave = async () => {
    try {
      let [validationMessage, validation] = validateTemplate()
      if(!validation){
        setErrorMessage(validationMessage)
      }
      else if (!saving){
        setSaving(true)
        setRemainder(true)
        setRemainderLineColor("bg-blue-500")
        setRemainderIconColor("text-blue-400")
        setRemainderText("Saving template...")
        await saveTemplate()
        setRemainderLineColor("bg-green-500")
        setRemainderIconColor("text-green-400")
        setRemainderText("Template saved successfully.")
        setSaving(false)
      }
    } catch (ex) {
      setErrorMessage("There was an issue saving the template. Please try again.")
    }
  }

  const handleTemplatePreview = () => {
    UserAction(user?.emailAddresses[0].emailAddress ?? "", "TemplatePreview")
    setPreview(!preview)
    setPreviewNotes(preview ? undefined : preprocessText(jsonToText(items, styling)))
  }

  const handleTemplateGenerate = () => {
    UserAction(user?.emailAddresses[0].emailAddress ?? "", "TemplateGenerate")
    setTemplateGenerate(true)
  }

  const handleTemplateIntegration = () => {
    UserAction(user?.emailAddresses[0].emailAddress ?? "", "TemplateIntegration")
    setTemplateConfig(true)
  }

  const handleTemplateStyling = () => {
    UserAction(user?.emailAddresses[0].emailAddress ?? "", "TemplateStyling")
    setTemplateStyling(true)
  }

  useEffect(() => {
    setPreviewNotes(preview ? preprocessText(jsonToText(items, styling)) : undefined)
  }, [items, styling])

  return (
  <>
    {templateLoading && <Loading text='Loading template...'/>}
    {!templateLoading && <div className="flex flex-col gap-y-4 h-full">
      <div className='flex flex-col sm:flex-row justify-between items-center gap-y-4 gap-x-4'>
          <div className='flex flex-col gap-y-2'>
            <input placeholder="Template Name" className={`focus:rounded-md border-gray-300 h-8 ${name == undefined || name == "" ? "ring-2 ring-red-600 rounded-md" : "border-t-0 border-r-0 border-l-0"} w-full sm:w-48 lg:w-60 mb-2`} value={name} onChange={(event) => updateName(event.target.value)}/>
            <div className='flex flex-row gap-x-2 items-center'>
              <span className='truncate'>Template Type: </span>
              <EnumPicker enum={TemplateType} selectedOption={templateType} setSelectedOption={(value) => updateTemplateType(TemplateType[value.toUpperCase().replace(" ", "_") as keyof typeof TemplateType])} width='w-full sm:w-auto xl:w-36'/>
            </div>
          </div>              
          <div className='flex flex-col gap-y-2'>
            <div className='flex flex-row gap-x-2 border-blue-600 border rounded-md items-center justify-center px-3.5 py-2.5 text-sm font-semibold h-8 cursor-pointer hover:bg-blue-100 shadow-sm mb-2' onClick={() => handleTemplateGenerate()} title='Import existing templates with VetRec AI'>
              <span className='truncate'>Import Template with AI</span>
              <DocumentPlusIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-300 sm:hidden xl:block"/>
            </div>
            <div className='flex flex-row gap-x-2 items-center w-full'>
              <MainButton title={preview ? "Edit notes in builder" : "Preview notes"} text={preview ? "Edit" : "Preview"} icon={preview ? <PencilIcon className='h-4 w-4 text-white' /> : <EyeIcon className='h-4 w-4 text-white' />} action={handleTemplatePreview} size='h-8'/>
              <MainButton title={"Save notes"} text="Save" icon={remainder ? <Spinner size='h-4 w-4' timer={false} /> : <CloudIcon className='h-4 w-4 text-white' />} action={handleTemplateSave} size='h-8' disabled={saving}/>
              <MoreMenu 
                moreText="Settings" 
                moreIcon={<Cog6ToothIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />} 
                options={[
                  {
                    name:"Integrations",
                    action: () => handleTemplateIntegration(),
                    icon: <LinkIcon className=" mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500"/>
                  },
                  {
                    name:"Styling",
                    action: () => handleTemplateStyling(),
                    icon: <PaintBrushIcon className=" mr-3 h-4 w-4 text-gray-400 group-hover:text-gray-500"/>
                  }
                ]} height='h-8'/>
            </div>
          </div>
      </div>
      <div className='grid grid-cols-4 mt-2 gap-x-4 h-full border-t-2 border-gray-300/30'>
        <div className='col-span-3'>
          {templateType === TemplateType.COMMUNICATIONS && <div className='w-full'>
            <RadioGroup value={templateTone} onChange={handleTemplateTone} className="mt-2 w-full">
              <RadioGroup.Label className="font-medium">What tone and style should the information be in?</RadioGroup.Label>
              <div className='flex flex-row gap-x-4 mt-4 w-full'>
              {communicationTemplateTones.map((tone) => (
                <RadioGroup.Option
                  key={tone.value}
                  value={tone.value}
                  className={`${templateTone === tone.value ? 'bg-blue-600 text-white' : 'bg-blue-100 text-gray-900 hover:bg-blue-300'} rounded-md px-3 py-2 text-sm font-medium cursor-pointer `}
                >
                  {tone.name}<br/>
                  <span className='font-light text-xs'>{tone.description}</span>
                </RadioGroup.Option>
              ))}
              </div>
            </RadioGroup>
          </div>}
          <div className={`${templateType === TemplateType.COMMUNICATIONS ? 'h-[40vh] sm:h-[50vh] lg:h-[60vh]' : 'h-[50vh] sm:h-[65vh] lg:h-[70vh]'} overflow-y-auto thin-scrollbar mt-4`}>
            <div className='h-full'>
              <div style={{ overflow: 'hidden', clear: 'both' }} className='h-full'>
                {!preview && <div ref={drop} className="w-full h-full flex flex-col gap-y-4 overflow-y-auto overflow-x-hidden thin-scrollbar pb-20" data-testid="main-template">
                  {/* {isActive ? 'Release to drop' : 'Drag a box here'} */}
                  {items.length > 0 && items.map((item) => (
                    <Section key={item.sectionKey} sectionKey={item.sectionKey} name={item.name} entries={item.entries} type={item.type} description={item.description} defaultValue={item.defaultValue} hardcodedValue={item.hardcodedValue}/>
                  ))}
                  {sectionHoverIndex === "999999999" && <div className='w-full min-h-1 bg-blue-600 rounded-md' />}
                  {items.length == 0 && <div className='flex flex-col items-center justify-center h-full'>
                    <div className='flex flex-col items-center gap-y-2'>
                      <div className='text-gray-500 text-lg'>Drag and drop a section</div>
                        <div className='text-gray-500 text-sm'>
                          Choose from empty sections or pre-built ones.
                        </div>
                      </div>
                    </div>
                  }
                </div>}
                {preview && <div>
                  <ReactQuill
                      theme='snow'
                      placeholder='Mr.Wiggles is looking mighty well today...'
                      className='block w-full h-[50vh]  sm:h-[60vh] lg:h-[65vh] rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
                      value={previewNotes}
                      readOnly
                      modules={{toolbar: false}}
                  />
                </div>}
              </div>
            </div>
          </div>
        </div>
        <div>
          <DraggableSections />
        </div>
      </div>
    </div>}
    <Remainder show={remainder} setShow={setRemainder} title={remainderText ?? ""} text='' lineColor={remainderLineColor} iconColor={remainderIconColor}/>
    <Error error={errorMessage} setError={setErrorMessage}/>
    <TemplateGenerateModal items={items} setItems={overrideTemplate} open={templateGenerate} setOpen={setTemplateGenerate} />
    <TemplateConfigModal open={templateConfig} setOpen={setTemplateConfig} items={items}/>
    <TemplateStylingModal open={templateStyling} setOpen={setTemplateStyling} />
  </>
  )
}
