import { GetTemplate } from "../../supabase/supabaseProxy"

export enum IntegrationType {
    EZYVET = "EzyVet",
    INSTINCT = "Instinct",
}

export enum ContentType {
    PARAGRAPH = "paragraph",
    LIST = "list",
    NUMBER = "number",
    SUBSECTION = "subsection",
    HARDCODED = "hardcoded"
}

export enum SectionTypes {
    NORMAL = "normal",
    LIST = "list",
    PARAGRAPH = "paragraph",
    NUMBER = "number",
    COLLECTION = "collection",
    SIMPLE_LIST = "simple_list",
    HARDCODED = "hardcoded"
}
  
export interface EntryType{
    entryKey:string
    name:string| undefined
    description?:string|undefined
    defaultValue?:string|undefined
    type:ContentType
    hardcodedValue?:string|undefined
    subEntries?:EntryType[]
}
  
export interface SectionType{
    sectionKey:string
    type:SectionTypes
    name:string|undefined
    entries:EntryType[]
    description?:string|undefined
    defaultValue?:string|undefined
    hardcodedValue?:string|undefined
}

export enum EzyVetCategories{
  HISTORY = "history",
  VITALS = "vitals",
  EXAM = "exam",
  ASSESSMENT = "assessment",
  PLAN = "plan",
  REVISIT = "revisit",
  //OWNER_COMMUNICATIONS = "owner communications",
  THERAPEUTICS = "therapeutics",
  NO_MAPPING = "No Mapping"
}

export enum EzyVetVitalsCategories{
  NO_MAPPING = "No Mapping",
  WEIGHT = "weight",
  HEARTRATE = "heart rate",
  TEMPERATURE = "temperature",
  RESPIRATORYRATE = "respiratory rate",
  BCS = "BCS",
  PAINSCORE = "pain score",
  DENTALSCORE = "dental score",
  ATTITUDE = "attitude",
  CRT = "CRT",
  MM = "MM",
  PULSEQUALITY = "pulse quality",
  RESPIRATORYEFFORT = "respiratory effort",
}

export interface EzyVetSubInput{
  name:string
  inputName:string
}

export interface EzyVetSection{
  name:string
  ezyvet_section_name:string
  category: EzyVetCategories | undefined
  inputs: {[key:string] : EzyVetSubInput}
}

export interface EzyVetMapping{
  section: EzyVetSection[]
}

export interface TemplateStyling{
  title_bold:boolean
  abnormals_bolded:boolean
  show_not_mentioned:boolean
  bullet_points: boolean
  list_bullet_points: boolean
  abnormals_color:boolean
  abnormals_uppercase:boolean
}

export enum TemplateType {
  MEDICAL = "medical",
  COMMUNICATIONS = "communications",
}

export function formatKey(key: string, bold:boolean, isEmpty:boolean = false): string {
  if(isEmpty){
    return "";
  }
    // Replace double underscores with a placeholder, split by underscore, then capitalize each word
    // Only if it's not an acronym (all uppercase), then join with a space or slash as appropriate
    const formattedKey = key.replace('__', '/').split('_')
      .map(word => word === word.toUpperCase() ? word : word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    
    if(bold){
      return `**${formattedKey}**: `;
    }
    else {
      return `${formattedKey}: `;
    }
}

function generateIndent(indent: number = 0, includeBullet:boolean = true){
  let bullet = indent === 0 ? "" : indent === 1 ? "- " : "* ";
  if(!includeBullet) bullet = ""
  const indentSpace = " ".repeat(indent) + bullet;
  return indentSpace
}

function printEntry(entry:EntryType, styling:TemplateStyling, indent:number = 1){
  let textVersion = ""
  if(entry.type == ContentType.PARAGRAPH || entry.type == ContentType.NUMBER){
    textVersion += `${generateIndent(indent, styling.bullet_points)}${formatKey(entry.name ?? "", styling.title_bold) !== '**Problem**: ' ? formatKey(entry.name ?? "", styling.title_bold) : ''}${entry.defaultValue && entry.defaultValue != "" ? entry.defaultValue : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}\n`
  } else if (entry.type == ContentType.LIST){
    textVersion += `${generateIndent(indent, styling.bullet_points)}${formatKey(entry.name ?? "", styling.title_bold)}\n`
    textVersion += `${" ".repeat(2)}${generateIndent(2, styling.list_bullet_points).replace('-', '*')} ${"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}\n`;
  } else if (entry.type == ContentType.HARDCODED){
    const entryNameIsEmpty = (entry.name ?? "").trim() === "";
    textVersion += `${generateIndent(indent, styling.bullet_points)}${formatKey(entry.name ?? "", styling.title_bold) !== '**Problem**: ' ? formatKey(entry.name ?? "", styling.title_bold, entryNameIsEmpty) : ''}${entry.hardcodedValue && entry.hardcodedValue != "" ? entry.hardcodedValue : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}\n`
  } else if (entry.type == ContentType.SUBSECTION){
    textVersion += `${generateIndent(indent, styling.bullet_points)}${formatKey(entry.name ?? "", styling.title_bold)}\n`
    if(entry.subEntries !== undefined){
      entry.subEntries.forEach(subEntry => {
        textVersion += printEntry(subEntry, styling, 2)
      })
    }
  }
  return textVersion
}
  
export function jsonToText(data: SectionType[], styling:TemplateStyling): string {
    let textVersion = "";
    let keysProcessed = 0;

    data.forEach(((section) => {
      const sectionNameIsEmpty = (section.name ?? "").trim() === "";
      const formattedKey = formatKey(section.name ?? "", styling.title_bold, sectionNameIsEmpty);
      if(section.type == SectionTypes.LIST){
        textVersion += `${formattedKey}\n`;
        section.entries.forEach(entry => {
          textVersion += printEntry(entry, styling)
        })
        textVersion += `\n`
        section.entries.forEach(entry => {
          textVersion += printEntry(entry, styling)
        })
      }
      else if(section.type == SectionTypes.SIMPLE_LIST){
        textVersion += `${formattedKey}\n`;
        textVersion += `- ${"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}\n`;
      }
      else if(section.type == SectionTypes.PARAGRAPH || section.type == SectionTypes.NUMBER){
        textVersion += `${formattedKey}`;
        textVersion += `${"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}\n`
      }
      else if (section.type == SectionTypes.HARDCODED){
        textVersion += `${formattedKey}`;
        textVersion += `${section.hardcodedValue && section.hardcodedValue != "" ? section.hardcodedValue : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}\n`
      }
      else{
        textVersion += `${formattedKey}\n`;
        section.entries.forEach(entry => {
          textVersion += printEntry(entry, styling)
        })
      }
      textVersion += `\n`
    }));
  
    return textVersion;
}

function categoryToInputName(category:EzyVetCategories){
  switch(category){
    case EzyVetCategories.HISTORY:
      return "visithistorydata_comments"
    case EzyVetCategories.ASSESSMENT:
      return "consultassessmentdata_notes"
    case EzyVetCategories.EXAM:
      return "visitexamdata_comments"
    case EzyVetCategories.PLAN:
      return "consultplandata_notes"
    case EzyVetCategories.THERAPEUTICS:
      return "plantherapeuticdata_specifics"
    //case EzyVetCategories.OWNER_COMMUNICATIONS:
      return ""
    case EzyVetCategories.REVISIT:
      return "revisitdata_notes"
  }
}

function inputNameToCategory(inputName: string): EzyVetCategories {
  switch(inputName) {
      case "visithistorydata_comments":
          return EzyVetCategories.HISTORY;
      case "consultassessmentdata_notes":
          return EzyVetCategories.ASSESSMENT;
      case "visitexamdata_comments":
          return EzyVetCategories.EXAM;
      case "consultplandata_notes":
          return EzyVetCategories.PLAN;
      //case "owner_communications":
          //return EzyVetCategories.OWNER_COMMUNICATIONS;
      case "plantherapeuticdata_specifics":
          return EzyVetCategories.THERAPEUTICS;
      case "revisitdata_notes":
          return EzyVetCategories.REVISIT;
      default:
          // Optionally handle unknown input names
          return EzyVetCategories.HISTORY;
  }
}

export function saveInstinctMapping(mapping:{[key:string]:EzyVetSection}) {
  let processed_mapping: {[key:string] : {[key:string] : string}} = {}
  Object.keys(mapping).forEach((key) => {
    processed_mapping[key.toLowerCase()] = {
      type:"one-to-one",
      button: "",
      addButton: "",
      inputType: "textarea",
      inputName: `${mapping[key].ezyvet_section_name}`,
      inputFormat: "string"
    }
  })
  return processed_mapping

}

export function saveEzyVetMapping(mapping:{[key:string]:EzyVetSection}){
  let processed_mapping: {[key:string] : {[key:string] : string | {[key:string] : {[key:string] : string }}}} = {}
  Object.keys(mapping).forEach((key) => {
    if(mapping[key].category != EzyVetCategories.VITALS){
      processed_mapping[key.toLowerCase()] = {
        type:"one-to-one",
        button: `Add${mapping[key].ezyvet_section_name.replace(" ", "").replace("/", "").replace("(","").replace(")","").replace(".", "")}`,
        addButton: "Add",
        inputType: "textarea",
        inputName: categoryToInputName(mapping[key].category ?? EzyVetCategories.HISTORY) ?? "",
        inputFormat: "string"
      }
    }
    else{
      processed_mapping[key.toLowerCase()] = {
        type:"one-to-many",
        button: `Add${mapping[key].ezyvet_section_name.replace(" ", "").replace("/", "").replace("(","").replace(")","")}`,
        addButton: "Add",
        inputs: saveEzyVetInputs(mapping[key].inputs)
      }
    }
  })
  return processed_mapping
}

export function saveEzyVetInputs(inputs:{[key:string]:EzyVetSubInput}){
  let processed_inputs: {[key:string]:{[key:string]:string}} = {}
  Object.keys(inputs).forEach((key) => {
    let inputFormat = "string"
    let inputType = "input"
    if([EzyVetVitalsCategories.WEIGHT, EzyVetVitalsCategories.TEMPERATURE, EzyVetVitalsCategories.HEARTRATE, EzyVetVitalsCategories.RESPIRATORYRATE, EzyVetVitalsCategories.BCS, EzyVetVitalsCategories.PAINSCORE, EzyVetVitalsCategories.DENTALSCORE].includes(inputs[key].inputName as EzyVetVitalsCategories)){
      inputFormat = "number"
    }

    if([EzyVetVitalsCategories.ATTITUDE, EzyVetVitalsCategories.CRT, EzyVetVitalsCategories.MM, EzyVetVitalsCategories.RESPIRATORYEFFORT, EzyVetVitalsCategories.PULSEQUALITY ].includes(inputs[key].inputName as EzyVetVitalsCategories)){
      inputType = "select"
    }

    processed_inputs[key.toLowerCase()] = {
      inputType: inputType,
      inputFormat: inputFormat,
      inputName: `healthstatusdata_${inputs[key].inputName.replace(" ", "").toLowerCase()}`
    }
  })
  return processed_inputs
}

export function instinctMappingToObject(mapping: { [key: string]: { [key: string]: string | { [key: string]: string } } }) {
  let processed_mapping: { [key: string]: EzyVetSection } = {};
  Object.keys(mapping).forEach((key) => {
      const sectionNameVlaue = mapping[key]['inputName']
      const sectionName = typeof sectionNameVlaue === 'string' ? sectionNameVlaue : ''

      processed_mapping[key.toLowerCase()] = {
          name: key,
          ezyvet_section_name: sectionName,
          category: undefined,
          inputs: {}
      };
  });
  return processed_mapping;
}

export function ezyVetMappingToObject(mapping: { [key: string]: { [key: string]: string | { [key: string]: string } } }) {
  let processed_mapping: { [key: string]: EzyVetSection } = {};
  Object.keys(mapping).forEach((key) => {
      // Check if inputName is a string, otherwise use an empty string or handle accordingly
      const inputNameValue = mapping[key]['inputName'];
      const inputName = typeof inputNameValue === 'string' ? inputNameValue : '';
      let category = inputNameToCategory(inputName);  // This will now only be called with a string
      if(mapping[key]['type'] == "one-to-many"){
        category = EzyVetCategories.VITALS
      }

      const sectionNameVlaue = mapping[key]['button']
      const sectionName = typeof sectionNameVlaue === 'string' ? sectionNameVlaue.replace("Add", "") : ''

      processed_mapping[key.toLowerCase()] = {
          name: key,
          ezyvet_section_name: sectionName,
          category: category,
          inputs: ezyVetInputsToObject(mapping[key]['inputs'])
      };
  });
  return processed_mapping;
}

export function ezyVetInputsToObject(inputs:any){
  let processed_inputs: {[key:string]:EzyVetSubInput} = {}
  if(inputs){
    Object.keys(inputs).forEach((key) => {
      processed_inputs[key.toLowerCase()] = {
        name: key,
        inputName: EzyVetVitalsCategories[(inputs[key]['inputName'].replace("healthstatusdata_", "").replace("data_", "").replace(" ", "_").toUpperCase()) as keyof typeof EzyVetVitalsCategories]
      }
    })
  }
  return processed_inputs
}

export function checkForDuplicateNames(sections: SectionType[]): boolean {
  const sectionSet = new Set<string>();

  // Function to process each entry and its subentries recursively
  // Checks to make sure entries don't share names with sections
  function processEntries(entries: EntryType[]): boolean {
    for (const entry of entries) {
      if (entry.name) {
        if (sectionSet.has(entry.name.toLowerCase())) {
          return false;
        }
      }
      if (entry.type == ContentType.SUBSECTION && entry.subEntries) {
        if (!processEntries(entry.subEntries)) {
          return false;
        }
      }
    }
    return true;
  }

  for (const section of sections) {
    if (section.name && sectionSet.has(section.name.toLowerCase())) {
      return false;
    }
    section.name && sectionSet.add(section.name.toLowerCase());
    if ([SectionTypes.NORMAL, SectionTypes.LIST, SectionTypes.COLLECTION].includes(section.type)) {
      if (!processEntries(section.entries)) {
        return false;
      }
    }
  }

  return true;  // Return true if no duplicates are found
}

export const communicationTemplateTones = [
  {
    name: "Casual",
    value: "casual",
    description:"Communications will be informal and friendly. Using layman terms."
  },
  {
    name: "Professional",
    value: "professional",
    description:"Communications will be formal and professional. Using layman terms."
  },
  {
    name: "Technical",
    value: "technical",
    description:"Communications will be formal and professional. Using medical terms and jargon."
  }
]
