import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { IMicrophone, TimeoutError, checkMicrophonePermissions, getMicrophonesAvailable, mimeTypeExtensionMap } from '../utils/RecordingUtils';
import va from '@vercel/analytics';
import { useAuth, useOrganization, useUser } from '@clerk/clerk-react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useRouteError } from 'react-router-dom';
import { cancelSession, createSession, fetchSessionById, recordSession, saveName, updatedSession } from '../ServerActions';
import { useSupabase } from '../supabase/SupabaseContext';
import { CheckRecordings, GetRecordingFullBackupSignedURL, GetRecordingsPartialBackupSignedURL, GetRecordingsSignedURL } from '../supabase/supabaseProxy';
import { MemberObject } from '../utils/MemberUtils';
import { set } from 'lodash';
import { SessionObject } from '../utils/SessionUtils';

export enum PermissionStatus {
    GRANTED = 'granted',
    DENIED = 'denied',
    CHECKING = 'checking',
    FETCHING = 'fetching',
    FAILED_TIMEOUT = 'failed_timeout',
    FAILED = 'failed'
}

export enum RecordingStatus {
    PREPARING = 'preparing',
    NOTSTARTED = 'not_started',
    RECORDING = 'recording',
    STOPPED = 'stopped',
    UPLOADING = 'uploading',
    REUPLOADING = 'reuploading',
    FAILED = 'failed',
    FAILED_NAME = 'failed_name',
    FAILED_UPLOAD = 'failed_upload',
    FAILED_NOT_FOUND = 'failed_not_found'
}

interface RecordingContextType {
    name: string;
    seconds: number;
    sessionId: string;
    recordingAllowed: PermissionStatus;
    recordingState: RecordingStatus;
    microphones: IMicrophone[];
    selectedMicrophone: IMicrophone;
    anyRecording: boolean;
    addToRecording: boolean;
    mediaStream?: MediaStream;
    consent: boolean;
    consentPending: boolean;
    membersList: MemberObject[];
    recordAs: string;
    existingTemplateId: string | undefined;
    isActAsAvailable: boolean;
    uploadPercentage: number;
    startRecording: (sessionId_force:SessionObject | undefined, owner_force: string| undefined, reset:boolean) => Promise<void>;
    stopRecording: () => Promise<void>;
    updateConsent: (consent:boolean) => Promise<void>;
    updateName: (name:string) => void;
    updateSelectedMicrophone: (microphone:IMicrophone) => void;
    updateRecordAs: (recordAs:string) => void;
    handleUpload: (reupload:boolean) => Promise<void>;
}

const RecordingContext = createContext<RecordingContextType | undefined>(undefined);

export const useRecording = () => {
  const context = useContext(RecordingContext);
  if (!context) {
    throw new Error('useRecording must be used within a RecordingProvider');
  }
  return context;
};

interface RecordingProviderProps {
  children: ReactNode;
}

export const RecordingProvider: React.FC<RecordingProviderProps> = ({ children }) => {
    // Providers
    const { user } = useUser()
    const { search } = useLocation();
    const { getToken, orgId } = useAuth();
    const { organization } = useOrganization()
    const {uploadRecordingToSupabase} = useSupabase()

    // Session Info
    const [sessionId, setSessionId] = useState<string>(uuidv4())
    const [name, setName] = useState<string>("")
    const [consent, setConsent] = useState<boolean>(false)
    const [consentPending, setConsentPending] = useState<boolean>(false)
    const [recordingId, setRecordingId] = useState<string>(uuidv4())
    const [addToRecording, setAddToRecording] = useState<boolean>(false)
    const [owner, setOwner] = useState<string | undefined>(user?.primaryEmailAddress?.emailAddress)
    const [anyRecording, setAnyRecording] = useState<boolean>(false)
    const [selectedMicrophone, setSelectedMicrophone] = useState<IMicrophone>({} as IMicrophone)
    const [activeRecording, setActiveRecording] = useState<boolean>(false)

    // Context State
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
    const [mediaStream, setMediaStream] = useState<MediaStream>();  
    const [audioBlobs, setAudioBlobs] = useState<Blob[]>([]);
    const [recordingState, setRecordingState] = useState<RecordingStatus>(RecordingStatus.NOTSTARTED);
    const [seconds, setSeconds] = useState<number>(0);
    const [timerActive, setTimeActive] = useState<boolean>(false);
    const [recordingAllowed, setRecordingAllowed] = useState<PermissionStatus>(PermissionStatus.CHECKING);
    const [microphones, setMicrophones] = useState<IMicrophone[]>([]);
    const [preferredMimeType, setPreferredMimeType] = useState<string>()
    const [membersList, setMembersList] = useState<MemberObject[]>([])
    const [recordAs, setRecordAs] = useState<string>(user?.primaryEmailAddress?.emailAddress ?? "")
    const [existingTemplateId, setExistingTemplateId] = useState<string | undefined>(undefined)
    const [isActAsAvailable, setIsActAsAvailable] = useState<boolean>(false)
    const [uploadPercentage, setUploadPercentage] = useState<number>(0)

    async function setupRecorder() {
        if(mediaRecorder && mediaRecorder.state === "recording"){
            //If for some reason it is already recording, don't set up another recorder
            return undefined;
        }

        try{
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: { deviceId: selectedMicrophone?.deviceId ?? microphones[0].deviceId }
            })
            setMediaStream(stream);
            let preferredTypes = ['audio/webm; codecs=opus', 'audio/webm', 'audio/mp4'];
            let mimeType = preferredTypes.find(type => MediaRecorder.isTypeSupported(type)) || undefined;
            setPreferredMimeType(mimeType)
            let options = mimeType ? { mimeType } : {}
            const recorder = new MediaRecorder(stream, options);
            setRecordingId(uuidv4())
            setUploadPercentage(0)
            setAudioBlobs([]);
            let properties =  {
                date:(new Date()).toUTCString(),
                mimeType:mimeType ?? "Not default: " + recorder.mimeType,
                sessionId: sessionId
            }
            va.track("MediaRecorder MimeType", properties)
            setMediaRecorder(recorder);

            recorder.onstart = () => {
                setRecordingState(RecordingStatus.RECORDING);
            }

            recorder.onerror = (event) => {
                console.error("MediaRecorder Error: ", event)
                let properties =  {
                    date:(new Date()).toUTCString(),
                    sessionId: sessionId,
                    error: "Something happened"
                }
                va.track("MediaRecorder_Error", properties)
            }

            // Add blobs to state
            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    setAudioBlobs((prevBlobs) => [...prevBlobs, event.data]);
                }
            };

            // Process recording stop
            recorder.onstop = () => {
                setRecordingState(RecordingStatus.UPLOADING);
                setTimeActive(false);
                if (stream) {
                    stream.getTracks().forEach(track => {
                        if (track.readyState === "live") { // Check if the track is still live
                            track.stop();
                        }
                    });
                    setMediaStream(undefined);
                }
            };

            return recorder;
        }catch(err){
            if (mediaStream) {
                mediaStream.getTracks().forEach(track => {
                    if (track.readyState === "live") { // Check if the track is still live
                        track.stop();
                    }
                });
                setMediaStream(undefined);
            }
            setMediaRecorder(undefined);
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: sessionId,
                error: err instanceof Error ? err.toString() : ""
            }
            va.track("MediaRecorder_Setup_failed", properties)
        }
    }

    function shouldOrganizationLimitActAsToOnlyAdmin() {
        const publicMetadata = organization?.publicMetadata
        if (publicMetadata) {
            return publicMetadata["limit_act_as_to_admin"] === true
        }

        return false
    }

    async function getOrganizationMember(){
        if(orgId){
            let members = await organization?.getMemberships({limit:500})
            if(members){
                if (shouldOrganizationLimitActAsToOnlyAdmin()) {
                    let admins = members.filter(x => x.role === "org:admin")
                    setIsActAsAvailable(admins.some(admin => admin.publicUserData.userId === user?.id))
                } else {
                    setIsActAsAvailable(true)
                }
            }
            
            let memberList: MemberObject[] | undefined = members?.map((member) => { 
                let name 
                if(member.publicUserData.firstName && member.publicUserData.lastName){
                    name = member.publicUserData.firstName + " " + member.publicUserData.lastName
                }
                else{
                    name = undefined
                }
                return {
                    name: name,
                    identifier: member.publicUserData.identifier,
                    default_template: member.publicMetadata['default_template_id'] as string,
                    default_template_discharge: member.publicMetadata['default_discharge_template_id'] as string
                }
            })
            if(memberList) setMembersList(memberList)
        }
    }

    // Handle recording timer
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
    
        // Start the interval only if timerActive is true
        if (timerActive) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds + 1);
            }, 1000);
        }
    
        // Cleanup function to clear the interval
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [timerActive]);  // Dependency on timerActive to restart the effect when it changes    

    // Handle session setup
    useEffect(() => {
        async function processQueryParams() {
            const queryParams = new URLSearchParams(search);
            const session_param = queryParams.get('session');
            if (session_param) {
                setAddToRecording(true);
                setSessionId(session_param); // Assuming this sets the state for sessionId used below.
    
                try {
                    setRecordingState(RecordingStatus.PREPARING);
                    const token = await getToken({ template: "supabase" });
                    const temp_session = await fetchSessionById(session_param, token || "");
                    const check_recordings = await CheckRecordings(session_param, token || "")
                    if (temp_session) {
                        setName(temp_session.name);
                        setAnyRecording(check_recordings.backup || check_recordings.recordings ? true : false);
                        setOwner(temp_session.owner);
                        setRecordAs(temp_session.owner)
                        setExistingTemplateId(temp_session.template_id);
                    }
                    setRecordingState(RecordingStatus.NOTSTARTED);
                } catch (err) {
                    setRecordingState(RecordingStatus.FAILED_NOT_FOUND);
                    const properties = {
                        date: (new Date()).toUTCString(),
                        sessionId: session_param
                    };
                    va.track("Failed_Get_AddRecording", properties);
                }
            }
        }
        processQueryParams();
    }, []);

    useEffect(() => {
        getOrganizationMember()
    }, [orgId])

    // Handle blobs added to array
    useEffect(() => {
        const uploadChunks = async (blobs: BlobPart[], isFull: boolean) => {
            const maxRetries = 3;
            let attempts = 0;
            let success = false;
    
            while (!success && attempts < maxRetries) {
                try {
                    const partial_id = uuidv4();
                    const mimeType = mediaRecorder?.mimeType ?? preferredMimeType ?? "audio/webm";
                    const recordingBlob = new Blob(blobs, { type: mimeType });
                    const fileExtension = mimeTypeExtensionMap[mimeType];
                    const fileName = isFull ? `${sessionId}_${recordingId}${fileExtension}` : `${sessionId}_${recordingId}_${partial_id}${fileExtension}`;
                    const recordingFile = new FormData();
                    recordingFile.append("file", recordingBlob, fileName);
                    const file = recordingFile.get("file");
                    const path = isFull ? `full/${recordingId}/` : `partial/${recordingId}/`;
    
                    // Ensure token retrieval is handled correctly
                    let token = await getToken({ template: "supabase" }) || await getToken({ template: "supabase" }) || "";
                    let signed_url = isFull 
                        ? await GetRecordingFullBackupSignedURL(sessionId, recordingId, fileExtension.split(".")[1], token)
                        : await GetRecordingsPartialBackupSignedURL(sessionId, recordingId, partial_id, fileExtension.split(".")[1], token);
                    
                    await uploadRecordingToSupabase(file, `${owner ?? user?.primaryEmailAddress?.emailAddress}/${sessionId}/${path}${fileName}`, "recordings_backup", mimeType, signed_url.signed_url.token, sessionId, (value) => {});
                    success = true;
                } catch (error) {
                    attempts++;
                    console.error(`Attempt ${attempts}: Silent failure in uploading ${isFull ? "full" : "partial"} backup.`);
                    if (attempts >= maxRetries) {
                        let properties = {
                            date: (new Date()).toUTCString(),
                            sessionId: sessionId,
                            error: error instanceof Error ? error.toString() : "Unknown Error"
                        };
                        va.track(isFull ? "Full_BackUp_Failed" : "Partial_BackUp_Failed", properties);
                    } else {
                        const backoffTime = 500 * Math.pow(2, attempts);
                        await new Promise(resolve => setTimeout(resolve, backoffTime));
                    }
                }
            }
        };

        const handleUploads = async () => {
            if (mediaRecorder && recordingState === RecordingStatus.RECORDING) {
                if (audioBlobs.length === 1) {
                    await uploadChunks(audioBlobs, false);
                }
                if (audioBlobs.length % 30 === 0 && audioBlobs.length > 0 && audioBlobs.length < 7200) {
                    const start = Math.max(1, audioBlobs.length - 31);
                    const finish = audioBlobs.length;
                    await uploadChunks([audioBlobs[0], ...audioBlobs.slice(start, finish)], false);
                }
                if (audioBlobs.length % 180 === 0 && audioBlobs.length > 0 && audioBlobs.length < 7200) {
                    await uploadChunks(audioBlobs, true);
                }
            }
        };
        
        handleUploads();

    }, [audioBlobs]);

    const handleUpload = async (reupload:boolean = false) => {

        if (reupload){
            setRecordingState(RecordingStatus.REUPLOADING);
        }

        if (!consent || audioBlobs.length === 0) {
            if (!consent) {
                setRecordingState(RecordingStatus.NOTSTARTED);
            } else if (audioBlobs.length === 0) {
                va.track("Recording_Upload_Empty", {
                    date: new Date().toUTCString(),
                    sessionId: sessionId
                });
            }
            return;
        }
        //Retry getting token
        let token = await getToken({ template: "supabase" });
        if(!token){
            token = await getToken({ template: "supabase" }) ?? "";
        }

        // Prep recording file
        const mimeType = mediaRecorder?.mimeType ?? preferredMimeType ?? 'audio/webm';
        const fileExtension = mimeTypeExtensionMap[mimeType];
        const fileName = `${sessionId}_${recordingId}${fileExtension}`;
        const recordingBlob = new Blob(audioBlobs, { type: mimeType });
        const recordingFile = new FormData();
        recordingFile.append("file", recordingBlob, fileName);
        const file = recordingFile.get("file");

        const maxRetries = 3; // Max retry attempts
        let attempt = 0;
        let success = false;

        // Upload using signed URL
        while (attempt < maxRetries && !success) {
            try {
                const signed_url = await GetRecordingsSignedURL(sessionId, recordingId, fileExtension.split(".")[1], token);
                await uploadRecordingToSupabase(file, `${owner ?? user?.primaryEmailAddress?.emailAddress}/${sessionId}/${fileName}`, "recordings", mimeType, signed_url.signed_url.token, sessionId, setUploadPercentage);
                setAnyRecording(true);
                setAudioBlobs([]);
                setRecordingState(RecordingStatus.STOPPED);
                success = true; // Break loop on success
            } catch (error) {
                attempt++;
                let errorMessage = "An unknown error occurred";
                if (error instanceof Error) {
                    errorMessage = error.message;
                }
                if (attempt >= maxRetries) {
                    setRecordingState(RecordingStatus.FAILED_UPLOAD);
                    va.track("Recording_Upload_Failed", {
                        date: new Date().toUTCString(),
                        sessionId: sessionId,
                        error: errorMessage
                    });
                } else {
                    const backoffTime = 500 * Math.pow(2, attempt); // Exponential backoff
                    await new Promise(resolve => setTimeout(resolve, backoffTime));
                }
            }
        }

        if (name && success) {
            await saveName(sessionId, name, token);
        }
    };
    
    // Handle upload
    useEffect(() => {
    
        if (recordingState === RecordingStatus.UPLOADING) {
            handleUpload(false);
        }
        else if(recordingState === RecordingStatus.FAILED){
            if(mediaRecorder && mediaRecorder.state === "recording"){
                setConsentPending(false)
                setConsent(false)
                mediaRecorder.onstop = () => {
                    setRecordingState(RecordingStatus.NOTSTARTED);
                }
                mediaRecorder.stop()
                setMediaRecorder(undefined)
            } 
            if (mediaStream) {
                mediaStream.getTracks().forEach(track => {
                    if (track.readyState === "live") { // Check if the track is still live
                        track.stop();
                    }
                });
                setMediaStream(undefined);
            }
        }
    }, [recordingState]);

    // Handle permissions and setup
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const permission = await checkMicrophonePermissions(5000);
                if (permission) {
                    setRecordingAllowed(PermissionStatus.FETCHING);
                    const microphones = await getMicrophonesAvailable();
                    setMicrophones(microphones);
                    setSelectedMicrophone(microphones[0]);
                    setRecordingAllowed(PermissionStatus.GRANTED);
                } else {
                    setRecordingAllowed(PermissionStatus.DENIED);
                }
            } catch (err) {
                if (err instanceof TimeoutError) {
                    setRecordingAllowed(PermissionStatus.FAILED_TIMEOUT);
                    let properties = {
                        date: (new Date()).toUTCString(),
                        user: user?.emailAddresses[0]?.emailAddress ?? "",
                        error: "Permissions Timeout",
                        sessionId: sessionId
                    };
                    va.track("MicrophonePermission_timeout", properties);
                } else {
                    setRecordingAllowed(PermissionStatus.FAILED);
                    let properties = {
                        date: (new Date()).toUTCString(),
                        user: user?.emailAddresses[0]?.emailAddress ?? "",
                        error: String(err),
                        sessionId: sessionId
                    };
                    va.track("MicrophonePermission_failed", properties);
                }
            }
        };
    
        fetchPermissions();
    }, []);

    // Methods

    const startRecording = async (sessionForced: SessionObject | undefined = undefined, owner_forcer:string | undefined = undefined, reset:boolean = false) => {
        try{
            if(sessionForced) 
            {
                setActiveRecording(true) // if recording is forced, then it is from active
                setSessionId(sessionForced.id)
            }
            if(owner_forcer) setOwner(owner_forcer)
            if(reset) setSeconds(0)
            if(name || sessionForced){
                let mr = await setupRecorder(); // Ensure setupRecorder is an async function
                let token = await getToken({template:"supabase"}) ?? ""
                if(mr){
                    mr.start(1000)
                    if(!addToRecording && !anyRecording && !sessionForced){
                        // New visit so create entry
                        await createSession(sessionId, name, token, false, true, true, recordAs === user?.emailAddresses[0]?.emailAddress ? undefined : recordAs)
                    }
                    else{
                        // Adding to existing visit, so lets update status
                        await updatedSession(sessionForced ? sessionForced.id: sessionId, token)
                    }
                    let existingSession = sessionForced?.status.recording == "Processing" || sessionForced?.status.transcript == "Updated"
                    setConsentPending(!anyRecording && !existingSession ? true : false)
                    setConsent(anyRecording || existingSession ? true : false)
                    setTimeActive(true)
                    await recordSession(sessionForced ? sessionForced.id : sessionId, true, false, selectedMicrophone?.label, token)
                }
                else{
                    setRecordingState(RecordingStatus.FAILED)
                    throw new Error("Recording failed to start.")
                }
            }
            else{
                setRecordingState(RecordingStatus.FAILED_NAME)
            }
        }catch(err){
            setRecordingState(RecordingStatus.FAILED)
            setTimeActive(false)
            throw new Error("Failed to create session for recording.")
        }
    };

    const stopRecording = async () => {
        mediaRecorder?.stop();
        setTimeActive(false)
        await recordSession(sessionId, false, false, selectedMicrophone?.label, await getToken({template:"supabase"}) ?? "")
    };

    const updateConsent = async (consent:boolean) => {
        if(consent){
            setConsent(true)
            setConsentPending(false)
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: sessionId
            }
            va.track("ConsentGrantedClick", properties)
        }
        else{
            mediaRecorder?.stop()
            setAudioBlobs([])
            setConsent(false)
            setConsentPending(false)
            if(!addToRecording && !activeRecording){ // if recordidng in active visit, can't cancel visit
                setSessionId(uuidv4())
                await cancelSession(sessionId, await getToken({template:"supabase"}) ?? "")
            }
            setSeconds(0)
            let properties =  {
                date:(new Date()).toUTCString(),
                sessionId: sessionId,
            }
            va.track("ConsentCanceled", properties)
        }
    }

    const updateName = async (name:string) => {
        if(recordingState === RecordingStatus.FAILED_NAME){
            setRecordingState(RecordingStatus.NOTSTARTED)
        }
        setName(name)
    }

    const updateSelectedMicrophone = (microphone:IMicrophone) => {
        setSelectedMicrophone(microphone)
    }

    const updateRecordAs = async (recordAs:string) => {
        setRecordAs(recordAs)
        if(!addToRecording){
            setOwner(recordAs)
        }
        
        let properties =  {
            date:(new Date()).toUTCString(),
            sessionId: sessionId ?? "undefined",
            recordAs: recordAs
        }
        va.track("RecordAs_Select", properties)
    }

    return (
        <RecordingContext.Provider value={{ anyRecording, seconds, sessionId, microphones, recordingState, startRecording, stopRecording, recordingAllowed, consent, updateConsent, name, updateName, addToRecording, updateSelectedMicrophone, selectedMicrophone, mediaStream, consentPending, membersList, recordAs, existingTemplateId, updateRecordAs, handleUpload, isActAsAvailable: isActAsAvailable, uploadPercentage }}>
            {children}
        </RecordingContext.Provider>
    );
};