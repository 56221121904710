import { useEffect, useState } from 'react';
import group from '../../assets/group.png'
import joeyMic from '../../assets/joey-mic.png'
import joeyNotes from '../../assets/joey-notes.png'
import joeySitting from '../../assets/joey-sitting.png'
import puppyJoey from '../../assets/puppy-joey.png'
import amira from '../../assets/Dr. Amira.png'
import sumo from '../../assets/Dr. Sumo.png'

const images = [group, joeyMic, joeyNotes, joeySitting, puppyJoey, amira, sumo];

const RandomPetGraphic = () => {
    const [randomImage, setRandomImage] = useState('');

    // Preload images when the component mounts
    useEffect(() => {
        try {
            // Select a random image
            const randomIndex = Math.floor(Math.random() * images.length);
            setRandomImage(images[randomIndex]);
        } catch (e) {
        }
    }, []);
    
    return (
        <div className="flex justify-center items-start pt-4 overflow-x-hidden">
        {randomImage && (
            <img 
            src={randomImage} // Use the preloaded random image
            alt="Randomly selected pet"
            className="rounded-lg shadow-md max-h-64 w-auto" // Tailwind CSS classes for styling
            />
        )}
        </div>
    );
  };
  
  export default RandomPetGraphic;