import { CheckCircleIcon, CheckIcon, CloudArrowUpIcon, MicrophoneIcon, PencilIcon, TrashIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { SessionObject, SessionStatus } from './utils/SessionUtils'
import { convertTimestampToDate } from './utils/time'
import { useUser } from "@clerk/clerk-react"
import { useNavigate } from 'react-router-dom'
import UserPicker from './components/UserPicker'
import { MemberObject } from './utils/MemberUtils'
import { useHistory } from './providers/HistoryProvider'
import { stringify } from 'querystring'
import Activity from './components/history/Activity'
import MoreMenu from './components/moreMenu'

export default function PetOverview() {
  const [petNameEditor, setPetNameEditor] = useState<boolean>(false)
  const [changeOwner, setChangeOwner] = useState<boolean>(false)
  const { user } = useUser()
  const  navigate = useNavigate()
  const {handleSaveName, activeSession, setActiveSession, handleMarkComplete, membersList, handleSaveOwner, activeAuditLogs, setDeleteVisitModal, setUploadRecordingModal} = useHistory()

  return (<>
    <div className="bg-white shadow rounded-lg border border-gray-300">
      <div className="px-4 py-6 sm:px-6 lg:items-center">
        <div className='flex md:flex-row flex-col justify-between gap-x-4 gap-y-4 w-full items-center'>
          <div className='flex flex-col gap-y-1'>
            <div className="mt-1 text-lg font-semibold leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-row md:justify-start justify-center gap-x-2">
                {!petNameEditor && (activeSession && activeSession.name != "" ? activeSession.name : "Not specified")}
                {!petNameEditor && <div className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                onClick={() => setPetNameEditor(true)}>
                    <PencilIcon  className="h-4 w-4"/>
                </div>}
                {petNameEditor && <div className="flex flex-row items-center justify-center gap-x-3">
                    <input
                        type="text"
                        name="name"
                        id="name"
                        className="block w-40 sm:w-full sm:h-12 h-10 rounded-md border-0 py-1.5 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                        placeholder="Mr.Wiggles"
                        value={activeSession?.name ?? "Not specified"} // Bind value to state
                        onChange={(event) => {
                            if(activeSession && activeSession.id){
                                setActiveSession({
                                    ...activeSession,
                                    name: event.target.value, // Update specific field
                                });
                            }
                        }}
                    />
                    <div className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                    onClick={() => {
                        setPetNameEditor(false)
                        handleSaveName()
                    }}>
                        <CheckCircleIcon  className="h-6 w-6"/>
                    </div>
                </div>}
            </div>
            <div className='text-sm text-gray-700'>
              {convertTimestampToDate(activeSession?.created_timestamp ?? 0)}
            </div>
          </div>
          <div className='flex flex-row gap-x-2 gap-y-4 sm:w-auto'>
            <button
                type="button"
                title={activeSession && activeSession.status.general && activeSession.status.general == "Completed" ? "This session is completed" : "Mark this session as completed"}
                className={`whitespace-nowrap inline-flex items-center gap-x-3 rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 border border-gray-300 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-48 ${activeSession && activeSession.status.general && activeSession.status.general == "Completed" ? 'bg-green-600 hover:bg-green-500 font-semibold text-white' : 'bg-white'} truncate`}
                onClick={() => handleMarkComplete()}
            >
                {activeSession && activeSession.status.general && activeSession.status.general == "Completed" && <CheckIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />}
                {activeSession && activeSession.status.general && activeSession.status.general == "Completed" ?  "Completed" : "Mark completed"}
            </button>
            <button
                type="button"
                title="Record additional information for this session which will be added to the notes."
                className="inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 overflow-hidden sm:max-w-48 w-full justify-center h-10 sm:h-12 truncate"
                onClick={() => navigate("/scribe?session=" + activeSession?.id)}
            >
              <MicrophoneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
              Add to visit
            </button>
            <button className='bg-red-500 px-3.5 py-2.5 rounded-md font-bold text-white text-xl h-10 sm:h-12 justify-center items-center flex' onClick={() => {setDeleteVisitModal(true)}}>
              <TrashIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            </button>
            <MoreMenu moreText="Actions" options={[{
              name: "Upload Recording",
              action: () => setUploadRecordingModal(true),
              icon: <CloudArrowUpIcon className="h-5 w-5" aria-hidden="true" />
            }]}
            height="h-10 sm:h-12 border-0"/>
          </div>
        </div>
      </div>
      {activeSession && <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
        <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Staff</dt>
            <dd className='mt-2 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex flex-row gap-x-2'>
              {!changeOwner && <div className='flex flex-row gap-x-2 items-center'>
                <div className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{membersList.find((u) => activeSession && u.identifier === activeSession.display_owner)?.name ?? activeSession.display_owner ?? membersList.find((u) => activeSession && u.identifier === activeSession.owner)?.name ?? activeSession.owner}</div>
                <div className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                  onClick={() => setChangeOwner(true)}>
                      <PencilIcon  className="h-4 w-4"/>
                </div>
              </div>}
              {changeOwner && <div className='flex flex-row gap-x-2 items-center w-'>
                <UserPicker userList={membersList} userSearch={membersList.find((u) => activeSession && u.identifier === activeSession.display_owner) ?? membersList.find((u) => activeSession && u.identifier === activeSession.owner)} setUserSearch={(value) => {
                  if(activeSession && activeSession.id){
                      setActiveSession({
                          ...activeSession,
                          display_owner: value?.identifier ?? "", // Update specific field
                      });
                  }
                }} className="h-10" text="Doctor / Staff"/>
                <div className="flex flex-row rounded-full hover:bg-selected h-6 w-6 items-center justify-center cursor-pointer"
                  onClick={() => {
                      setChangeOwner(false)
                      handleSaveOwner()
                  }}>
                      <CheckCircleIcon  className="h-6 w-6"/>
                  </div>
              </div>}
            </dd>
          </div>
          {activeSession.summary && <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-900">Summary</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{activeSession.summary}</dd>
          </div>}
        </dl>
      </div>}
      <div className='border-b border-gray-400 sm:mx-6 mx-4 py-4 text-md'>
        Activity logs
      </div>
      <Activity />
    </div>
    </>
  )
}
