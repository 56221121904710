import { useState } from "react";
import { PreHistoryV2Definition } from "../../utils/PreHistoryDefinition";
import { Spinner } from "../../utils/Spinner";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

interface EntryViewProps {
  title: string;
  value: string | string[] | undefined;
  forceExpand: boolean;
  truncateMax?: number | undefined;
}

const EntryView: React.FC<EntryViewProps> = ({ title, value, forceExpand, truncateMax }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const toggleExpandCollapse = () => setIsExpanded(!isExpanded)
  truncateMax = truncateMax ?? 5

  return (
    <div className="flex flex-col gap-y-2 relative">
      <div className="text-base font-semibold leading-6 text-black">
        {title}
      </div>
      {!Array.isArray(value) && (
        <div className="w-full border border-gray-300 rounded-md p-2 text-sm text-gray-700">
          {value}
        </div>
      )}
      {Array.isArray(value) && (
        <div className="relative w-full border border-gray-300 rounded-md p-2 text-sm text-gray-700">
          {value && value.length > 0 && (
            <ul className="list-disc pl-5">
              {((forceExpand || isExpanded) ? value : value.slice(0, truncateMax))?.map((exam, index) => (
                <li key={index}>{exam}</li>
              ))}
            </ul>
          )}
          {value.length === 0 && "Not provided"}
          {!forceExpand && value.length > truncateMax && <button
            onClick={toggleExpandCollapse}
            className="mt-2 flex justify-between items-center p-2 w-full bg-blue-100 hover:bg-blue-200 text-blue-900 rounded-md focus:outline-none"
          >
            {isExpanded ? <p>Collapse to see less.</p> : <p>Expand to see more.</p>}
            {isExpanded ? (
              <ChevronUpIcon className="w-5 h-5 text-blue-900" />
            ) : (
              <ChevronDownIcon className="w-5 h-5 text-blue-900" />
            )}
          </button>}
        </div>
      )}
    </div>
  );
};

interface SummarizedHistoryViewProps {
  activePreHistory: PreHistoryV2Definition;
  preHistoryStreaming: boolean;
}

export const SummarizedHistoryView: React.FC<SummarizedHistoryViewProps> = ({
  activePreHistory,
  preHistoryStreaming,
}) => {
  return (
    <div className={`flex flex-col gap-y-4 ${preHistoryStreaming ? "pt-6" : ""} relative w-full`}>
      {preHistoryStreaming && (
        <div className="absolute top-0 sm:top-0 right-0 sm:right-0 flex flex-col gap-y-2">
          <div className="flex flex-row relative items-center gap-x-2 rounded-md bg-white px-2.5 py-2.5 text-sm font-semibold border border-gray-300 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 h-10 overflow-hidden justify-center z-[2] shadow">
            <Spinner size="h-5 w-5" timer={false} />
            Writing summary...
          </div>
        </div>
      )}
      <EntryView title="Summary" value={activePreHistory.summary} forceExpand={!!preHistoryStreaming}/>
      <EntryView title="Referrers" value={activePreHistory.referrers} truncateMax={3} forceExpand={!!preHistoryStreaming}/>
      <EntryView
        title="Physical Exams"
        value={activePreHistory.physical_exams}
        forceExpand={!!preHistoryStreaming}
      />
      <EntryView
        title="Prior Issues"
        value={activePreHistory.prior_issues}
        forceExpand={!!preHistoryStreaming}
      />
      <EntryView
        title="Prior Vaccines"
        value={activePreHistory.prior_vaccines}
        forceExpand={!!preHistoryStreaming}
      />
      <EntryView
        title="Prior Medications"
        value={activePreHistory.prior_medications}
        forceExpand={!!preHistoryStreaming}
      />
      <EntryView
        title="Prior Test Reports"
        value={activePreHistory.prior_test_reports}
        forceExpand={!!preHistoryStreaming}
      />
      <EntryView
        title="Prior treatments"
        value={activePreHistory.prior_treatments}
        forceExpand={!!preHistoryStreaming}
      />
      <EntryView
        title="Additional information"
        value={activePreHistory.additional_information}
        forceExpand={!!preHistoryStreaming}
      />
    </div>
  );
};
