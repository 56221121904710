import { ArrowDownCircleIcon, CheckIcon, ClipboardDocumentIcon, CloudIcon, DocumentTextIcon, HandThumbDownIcon, HandThumbUpIcon, LinkIcon, MicrophoneIcon, SparklesIcon, Squares2X2Icon } from "@heroicons/react/24/solid";
import ReactQuill from "react-quill";
import { Spinner } from "../../utils/Spinner";
import MoreMenu from "../moreMenu";
import { useAuth, useOrganization, useUser } from "@clerk/clerk-react";
import { useEffect, useMemo, useState } from "react";
import { preprocessText, filterNotMentioned, filterNotMentionedHTML, revertPreprocessedText, revertPreprocessedTextNoStyling, fixIndentation } from "../../utils/TextUtils";
import va from '@vercel/analytics';
import { GetNotesSignedURL } from "../../supabase/supabaseProxy";
import { useSupabase } from "../../supabase/SupabaseContext";
import { downloadSoapPDF } from "../../ServerActions";
import TemplatePicker from "../templates/TemplatePicker";
import { Loading } from "../../utils/Loading";
import 'react-quill/dist/quill.snow.css'; // Import Quill styles"
import { useHistory } from "../../providers/HistoryProvider";
import { TemplateType } from "../templates/TemplateUtils";
import { WarningBanner } from "../common/WarningBanner";
import VetSpireModal from "../integrations/VetspireModal";

interface NotesProps {
}

export const Notes: React.FC<NotesProps> = ({}) => {

    const { user } = useUser()
    const { getToken } = useAuth()
    const {organization} = useOrganization()
    const [savingNotes, setSavingNotes] = useState<boolean>(false)
    const [copySuccess, setCopySuccess] = useState<boolean>(false)
    const [copyKey, setCopyKey] = useState<string>("")
    const [soapNotesChanged, setSoapNotesChanged] = useState<boolean>(false)
    const [didLastSaveFail, setDidLastSaveFail] = useState<boolean>(false)
    const [vetSpireExport, setVetSpireExport] = useState<boolean>(false)
    const {activeSession, notesProcessing, activeNotes, transcriptProcessing, activeTranscript, notesStreaming, displayActiveNotes, notesLoading, loadingSessionInfo, feedbackNotes, sessionTemplateName, template, notesStreamCompleted, notesSections, toggleSections, setNotification, setNotificationTitle, handleMarkComplete, handleAddToSession, handleThumbsInput, handleNotesTemplateChange, setError, setWarning, setDisplayActiveNotes, setTemplate, processTranscript, getLoggingProperties, handleToggleSections} = useHistory()
    const { uploadToSupabaseSignedURL } = useSupabase()

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (copySuccess || copyKey) {
            timer = setTimeout(() => {
                setCopySuccess(false);
                setCopyKey("")
            }, 1000); // 5000ms = 5 seconds
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
          clearInterval(interval);
        };
    }, [copySuccess, copyKey]);

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        let interval: string | number | NodeJS.Timeout | undefined;
    
        if (savingNotes) {
            timer = setTimeout(() => {
                if(savingNotes) setSavingNotes(false)
            }, 3000); // 5000ms = 5 seconds
        }
    
        // Cleanup
        return () => {
          clearTimeout(timer);
        };
    }, [savingNotes]);

    const copyToClipboardSection = async (key:string) => {
        try {
            if(notesSections){
                setCopyKey(key)
                // TODO: Need to handle pims types
                // TODO: Add rich text and plain text options
                await navigator.clipboard.writeText(filterNotMentioned(notesSections[key]));
                setNotification(true)
                setNotificationTitle("Notes copied to clipboard")
            }
        } catch (err) {
        }
    };

    const copyToClipboard = async () => {
        try {
            setCopySuccess(true)
            let text_temp_html = filterNotMentionedHTML(fixIndentation(displayActiveNotes)) // rich text
            let text_temp_plain

            const pims = user?.publicMetadata?.pims || organization?.publicMetadata?.pims;

            if (pims === "ezyvet") {
                text_temp_plain = filterNotMentioned(revertPreprocessedText(displayActiveNotes)); // plain with markdown
            } else {
                text_temp_plain = filterNotMentioned(revertPreprocessedTextNoStyling(displayActiveNotes)); // plain with no markdown
            }

            let text = new ClipboardItem({
                'text/html': new Blob([text_temp_html], { type: 'text/html' }),
                'text/plain': new Blob([text_temp_plain], { type: 'text/plain' })
            });

            await navigator.clipboard.write([text]);
            setNotification(true)
            setNotificationTitle("Notes copied to clipboard")
            va.track("History_CopyNotes", getLoggingProperties())
        } catch (err) {
            console.error('Failed to copy: ', err);
            setNotification(false)
            setNotificationTitle(undefined)
            setError("There was an issue copying the notes. Please try again.")
            va.track("History_CopyNotes_Failed", getLoggingProperties())
        }
    };

    const saveNotes = async () => {
        if(activeSession){
            try {
                setDidLastSaveFail(false)
                setSavingNotes(true)
                setNotification(true)
                setNotificationTitle("Saving your notes...")
                let fileName = activeSession?.owner + "/" + activeSession.id + "/" + activeSession.id +"_soap.txt"
                const blob = new Blob([revertPreprocessedText(displayActiveNotes)], {type: 'text/plain'})
                const file = new File([blob], `${activeSession.id}.txt`, {type: 'text/plain'})
                let signedURL = await GetNotesSignedURL(activeSession.id, await getToken({template:"supabase"}) ?? '')
                await uploadToSupabaseSignedURL(file, fileName, "soap", signedURL.signed_url.token)
                setNotificationTitle("Saved notes!")
                va.track("History_SaveNotes", getLoggingProperties())
            } catch (err) {
                setNotification(false)
                setNotificationTitle(undefined)
                setError("There was an issue saving the notes. Please try again.")
                setDidLastSaveFail(true)
                va.track("History_SaveNotes_Failed", getLoggingProperties())
            }
        }
    }

    async function handleDownloadPDF(): Promise<void> {
        try {
            setNotification(true)
            setNotificationTitle("Downloading PDF...")
            
            let signed_url = await downloadSoapPDF(activeSession?.id ?? "", await getToken({template:"supabase"}) ?? "")
            if(signed_url){
                // Trigger the PDF download
                const link = document.createElement('a');
                link.href = signed_url['signed_url'];
                link.target = '_blank';
                link.setAttribute('download', `${activeSession?.name}_notes.pdf`); // You can specify the file name here
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                va.track("HistoryPage_DownloadSoapPDF", getLoggingProperties())
            }
            else {
                setNotification(false)
                setNotificationTitle(undefined)
                setError("There was an issue downloading the PDF. Please try again.")
                va.track("HistoryPage_DownloadSoapPDF_FailedNoSignedUrl", getLoggingProperties())
            }
        } catch (err) {
            setNotification(false)
            setNotificationTitle(undefined)
            setError("There was an issue downloading the PDF. Please try again.")
            va.track("HistoryPage_DownloadSoapPDF_Failed", getLoggingProperties())
        }
    }

    function handleVetSpireExport(open:boolean): void {
        setVetSpireExport(open)
    }

    const notesOptions = useMemo(() => {
        let baseOptions = [{
            name:"Generate Notes",
            action: () => handleNotesTemplateChange(true),
            icon: <DocumentTextIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
        },{
            name:"Download PDF",
            action: () => handleDownloadPDF(),
            icon: <ArrowDownCircleIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
        }]
        //Pims needs to be on the organization as keys
        if(((organization?.publicMetadata.hasOwnProperty("pims")) && organization.publicMetadata['pims'] == "vetspire")){
            baseOptions.push({
                name:"Export to Vetspire",
                action: () => handleVetSpireExport(true),
                icon: <LinkIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"/>
            })
        }
        return baseOptions
    }, [user, activeSession])

    return(<>
        {/* <div>
            transcriptProcessing: {transcriptProcessing.toString()}<br/>
            notesProcessing: {notesProcessing.toString()}<br/>
            activeTranscript: {activeTranscript ? activeTranscript[0] : "undefined"}<br/>
            activeNotes: {activeNotes ? activeNotes[0] : "undefined"}<br/>
            notesLoading: {notesLoading ?? "undefined"}<br/>
            loading: {loadingSessionInfo ?? "undefined"}<br/>
            notesstreaming: {notesStreaming.toString()}<br/>
        </div> */}
        {(notesLoading || loadingSessionInfo) && !activeNotes && !transcriptProcessing && !notesProcessing && <Loading text={notesLoading ?? loadingSessionInfo ?? ""}/>}
        {((transcriptProcessing || notesProcessing) && !notesStreaming && !activeNotes && !notesLoading) && <div className='pt-5 w-full m-auto flex flex-col justify-center items-center align-center gap-y-6 mb-8 font-semibold'>
            {transcriptProcessing && !notesProcessing && !notesStreaming && <div>Transcribing recording 🤖...</div>}
            {notesProcessing && !notesStreaming && <div>Generating notes 🤖....</div>}
            <Spinner size="w-12 h-12" timer={true}/>
            <div className="text-sm text-center text-gray-700 font-normal">Notes will be ready within 60s.<br/>Feel free to navigate away if you need to.</div>
        </div>}
        {activeNotes && <div  className="flex xl:flex-row flex-col gap-y-4 justify-between gap-x-4 xl:items-center items-end">
            {!didLastSaveFail && notesStreaming && <div className="rounded-lg bg-blue-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
                <div className="flex grow items-center justify-center">
                    <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                        <div className="flex flex-row gap-x-4 items-center">
                            <div>
                                <DocumentTextIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                            </div>
                            <div className="text-sm text-blue-900 font-semibold">We are finishing your notes. Feel free to navigate away, your notes will be ready within 60s or less.</div>
                        </div>
                    </div>
                </div>
            </div>}
            {!didLastSaveFail && !notesStreaming && <div className="rounded-lg bg-blue-50 px-4 shadow h-12 grow flex items-center justify-center w-full">
                    <div className="flex grow items-center justify-center">
                        <div className="flex-1 flex flex-col sm:flex-row sm:justify-between justify-center gap-y-4 items-center">
                            <div className="flex flex-row gap-x-4 items-center">
                                <div>
                                    <LinkIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
                                </div>
                                <div className="text-sm text-blue-900 font-semibold">Export your notes to your PIMS! <a className="cursor-pointer underline" href="/integrations">Learn more</a></div>
                            </div>
                        </div>
                    </div>
                </div>}
            {didLastSaveFail && <WarningBanner warningText="There was an issue saving the notes. Please try again." />}
            <div className="flex flex-row gap-x-2 w-full sm:w-auto">
                <button
                    type="button"
                    title={activeSession && activeSession.status.general && activeSession.status.general == "Completed" ? "This session is completed" : "Mark this session as completed"}
                    className={`whitespace-nowrap inline-flex items-center gap-x-3 rounded-md px-3.5 py-2.5 text-xs sm:text-sm font-semibold text-gray-900 border border-gray-300 shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52 ${activeSession && activeSession.status.general && activeSession.status.general == "Completed" ? 'bg-green-600 hover:bg-green-500 font-semibold text-white' : 'bg-white'}`}
                    onClick={() => handleMarkComplete()}
                >
                    <CheckIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                    <span className="hidden sm:block">{activeSession && activeSession.status.general && activeSession.status.general == "Completed" ?  "Completed" : "Mark completed"}</span>
                </button>
                <button
                    type="button"
                    title="Record additional information for this session which will be added to the notes."
                    className="whitespace-nowrap inline-flex items-center gap-x-3 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 sm:h-12 overflow-hidden justify-center grow w-full sm:max-w-52"
                    onClick={() => handleAddToSession()}
                >
                    <MicrophoneIcon className="-mr-0.5 h-5 w-5 block sm:hidden" aria-hidden="true" />
                    <span className="hidden sm:block">Add to visit</span>
                </button>
                {!notesStreaming && <div className="sm:hidden flex flex-row gap-x-2">
                    <button
                        type="button"
                        title="Copy the notes"
                        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                        onClick={() => copyToClipboard()}
                    >
                        {!copySuccess && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />}
                        {copySuccess && <Spinner size="h-5 w-5" timer={false}/>}
                    </button>
                    {!feedbackNotes && <button
                        type="button"
                        title="Provide positive feedback on notes"
                        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                        onClick={() => handleThumbsInput(true, "Notes")}
                    >
                        <HandThumbUpIcon className="h-5 w-5 text-white hover:text-blue-400"/>
                    </button>}
                    {!feedbackNotes && <button
                        type="button"
                        title="Provide negative feedback on notes"
                        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                        onClick={() => handleThumbsInput(false, "Notes")}
                    >
                        <HandThumbDownIcon className="h-5 w-5 text-white hover:text-blue-400"/>
                    </button>}
                    {<button
                        type="button"
                        title="Toggle Notes Display"
                        className="relative items-center gap-x-2 rounded-md w-10 bg-main-button flex text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                        onClick={() => handleToggleSections()}
                    >
                        {toggleSections && <div className="h-4 w-4 border-4 border-white rounded-sm"/>}
                        {!toggleSections && <Squares2X2Icon className="h-5 w-5 text-white"/>}
                    </button>}
                </div>}
                <div className="whitespace-nowrap">
                    <MoreMenu 
                        moreText="Actions" 
                        options={notesOptions} 
                        height="h-10 sm:h-12"/>
                </div>
            </div>
        </div>}
        {activeNotes && <div className="flex flex-col justify-end w-full">
            <div className="flex flex-col-reverse sm:flex-row relative h-full w-full">
                <div className="flex flex-col items-end justify-end w-full">
                    {notesStreaming && <div className="absolute top-2 sm:top-2 right-4 sm:right-4 flex flex-col gap-y-2">
                        <div
                            className="flex flex-row relative items-center gap-x-2 rounded-md bg-white px-2.5 py-2.5 text-sm font-semibold border border-gray-300 text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 h-10 overflow-hidden justify-center z-[2] shadow"
                        >
                            <Spinner size="h-5 w-5" timer={false}/>
                            Writing notes... 
                        </div>
                    </div>}
                    {!toggleSections && <ReactQuill
                        theme='snow'
                        placeholder='Mr.Wiggles is looking mighty well today...'
                        className='block w-full lg:h-[68vh] h-[65vh] rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
                        value={displayActiveNotes}
                        modules={{toolbar: false}}
                        onChange={(value, d, s, editor) => {
                            setDisplayActiveNotes(value)
                            setSoapNotesChanged(true)
                        }}
                        readOnly={notesStreaming}
                    />}
                    {toggleSections && !notesSections && <div className="flex flex-col gap-y-4 w-full justify-center items-center mt-8 mb-8">
                        Splitting Notes into sections
                        <Spinner size="h-10 w-10" timer={true}/>
                    </div>}
                    {toggleSections && notesSections && <div className="flex flex-col gap-y-4 w-full">
                        {Object.keys(notesSections).filter(key => notesSections[key].length > 0).map(key => (
                            <div className="text-sm text-gray-500 rounded-lg flex flex-col gap-y-2 w-full">
                                <div className="flex flex-row gap-x-4 items-center">
                                    <span className="font-semibold text-gray-900">
                                        {key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ').replace(/\b\w/g, char => char.toUpperCase())}
                                    </span>
                                    <div>
                                        <button
                                        type="button"
                                        className="relative -ml-px inline-flex justify-center items-center gap-x-1.5 rounded-md px-3 py-1 text-xs font-semibold text-main-text ring-1 ring-inset ring-gray-400 bg-main hover:bg-main-lighter w-full"
                                        onClick={() => copyToClipboardSection(key)}
                                        >
                                            {copyKey !== key && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4" aria-hidden="true" />}
                                            {copyKey === key && <Spinner size="h-4 w-4" timer={false}/>}
                                            Copy
                                        </button>
                                    </div>
                                </div>
                                <div onDoubleClick={() => setWarning("Toggle notes view to edit. The current view is read-only.")}>
                                    <ReactQuill
                                        theme='snow'
                                        placeholder='Mr.Wiggles is looking mighty well today...'
                                        className='block w-full h-full rounded-md border-0 text-main-text-darker shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 resize-none thin-scrollbar'
                                        value={preprocessText(notesSections[key])}
                                        modules={{toolbar: false}}
                                        readOnly
                                    />
                                </div>
                            </div>
                        ))}
                        </div>}
                    <div className={`flex ${sessionTemplateName ? "justify-between" : "justify-end"} w-full items-center`}>
                        {sessionTemplateName && <div className="text-sm text-gray-600">Template: {sessionTemplateName}</div>}
                        {!notesStreaming && soapNotesChanged && <button
                            type="button"
                            className="relative mt-2 gap-x-2 rounded-md w-20 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2] flex flex-row items-center"
                            onClick={() => saveNotes()}
                        >
                            Save
                            {savingNotes ? <Spinner size="h-5 w-5" timer={false} /> : <CloudIcon className="h-5 w-5 text-white hover:text-blue-400"/>}
                        </button>}
                    </div>
                </div>
                <div className="hidden sm:flex sm:flex-col gap-y-2 justify-between items-end pl-2 h-full">
                    {!notesStreaming && <div className="flex flex-row sm:flex-col gap-y-2 items-center gap-x-3">
                        <button
                            type="button"
                            title="Copy the notes"
                            className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                            onClick={() => copyToClipboard()}
                        >
                            {!copySuccess && <ClipboardDocumentIcon className="-mr-0.5 h-4 w-4 sm:h-5 sm:w-5" aria-hidden="true" />}
                            {copySuccess && <Spinner size="h-5 w-5" timer={false}/>}
                        </button>
                        {!feedbackNotes && <button
                            type="button"
                            title="Provide positive feedback on notes"
                            className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                            onClick={() => handleThumbsInput(true, "Notes")}
                        >
                            <HandThumbUpIcon className="h-5 w-5 text-white"/>
                        </button>}
                        {!feedbackNotes && <button
                            type="button"
                            title="Provide negative feedback on notes"
                            className="relative items-center gap-x-2 rounded-md w-10 bg-main-button px-2.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center z-[2]"
                            onClick={() => handleThumbsInput(false, "Notes")}
                        >
                            <HandThumbDownIcon className="h-5 w-5 text-white"/>
                        </button>}
                        {<button
                            type="button"
                            title="Toggle Notes View"
                            className="relative flex gap-x-2 rounded-md w-10 bg-main-button text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden justify-center items-center z-[2]"
                            onClick={() => handleToggleSections()}
                        >
                            {toggleSections && <div className="h-4 w-4 border-4 border-white rounded-sm"/>}
                            {!toggleSections && <Squares2X2Icon className="h-5 w-5 text-white"/>}
                        </button>}
                    </div>}
                </div>
            </div>
        </div>}
        {activeTranscript && !activeNotes && !notesLoading && (!notesProcessing || notesStreaming || notesStreamCompleted) && <>
            <div className="bg-blue-50 sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6 xl:flex xl:flex-row xl:justify-between xl:item-center">
                    <div>
                        <h3 className="text-base font-semibold leading-6 text-gray-900">{"Generate Notes"}</h3>
                        <div className="mt-2 max-w-xl text-sm text-gray-500">
                            <p>{"Generate your notes for this visit."}</p>
                        </div>
                    </div>
                    <div className="xl:mt-0 mt-5 flex flex-col sm:flex-row md:items-center gap-x-4 gap-y-4">
                        <TemplatePicker template={template} templateId_force={activeSession?.template_id} setTemplate={setTemplate} className="h-10" templateBackground='bg-transparent' type={TemplateType.MEDICAL}/>
                        <button
                            type="button"
                            className="inline-flex items-center gap-x-2 rounded-md bg-main-button px-3.5 py-2.5 text-sm font-semibold text-main-button-text shadow-sm hover:bg-main-button-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-10 overflow-hidden xl:w-36 lg:w-36 md:w-36 sm:w-36 w-full justify-center"
                            onClick={() => processTranscript(activeNotes ? true : false)}
                        >
                            Generate
                            <SparklesIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </>}
        <VetSpireModal vetspireExport={vetSpireExport} handleVetSpireExport={handleVetSpireExport}/>
    </>)
}

